.printer-element{
    display: none;
}


@media print {

    body, html {
        margin-top: 2px;
        padding-top: 2px;
        padding-bottom: 2px;
        margin-left: 2px;
    }

    #printable, #printable * {
        visibility: visible;
    }
    #printable {
        position: fixed;
        left: 0;
        top: 0;
    }
    table {
        page-break-inside: avoid;
    }

    .kloai{
        page-break-inside: avoid;
    }

    #printPageButton {
        display: none;
    }

    .printer-element{
        display: block;
    }

    .titulo-principal{
        display: none;
    }

    .s-alert-wrapper{
        display: none;
    }

    .pagebreak { page-break-before: always; }
}

.printer-ticket {
    display: table !important;
    width: 100%;
    max-width: 400px;
    line-height: 1.3em;
}
.printer-ticket,
.printer-ticket * {
    font-family: monospace;
    font-size: 16px;
    font-weight: bolder;
    background: #FFF;
    table-layout: fixed;
    width: 100%;
}
.tabela-recibo {
    border-bottom: 3px dashed;
}
.printer-ticket th:nth-child(2),
.printer-ticket td:nth-child(2) {
    width: 50px;
}
.printer-ticket th:nth-child(3),
.printer-ticket td:nth-child(3) {
    width: 90px;
    text-align: right;
}
.printer-ticket th {
    font-weight: inherit;
    padding: 10px 0;
    text-align: center;
    border-bottom: 1px dashed #BCBCBC;
}
.printer-ticket tbody tr:last-child td {
    padding-bottom: 10px;
}
.printer-ticket tfoot .sup td {
    padding: 10px 0;
    border-top: 1px dashed #BCBCBC;
}
.printer-ticket tfoot .sup.p--0 td {
    padding-bottom: 0;
}
.printer-ticket .title {
    font-size: 1.5em;
    padding: 15px 0;
}
.printer-ticket .top td {
    padding-top: 10px;
}
.printer-ticket .last td {
    padding-bottom: 10px;
}
