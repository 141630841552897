
body.loading-non-critical-css .inc-ui-module:not(#BANNERS_MKT):not(#PAYMENTS):not(#CAROUSEL):not(#SECONDARY_OFFERS):not(#COMMERCIAL):not(#BIG_BANNER) {
    display: none
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, button, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    font-style: inherit;
    line-height: inherit;
    vertical-align: baseline;
    font-variant: inherit
}

.vxa-3-btn .btn-text, body {
    font-family: Rubik, Arial, sans-serif
}

body {
    color: var(--color-gray-1);
    font-size: 14px;
    line-height: 1
}

ol, ul {
    list-style: none
}

blockquote, q {
    quotes: none
}

blockquote:after, blockquote:before, q:after, q:before {
    content: "";
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

.vxa-3-btn {
    display: inline-block;
    box-sizing: border-box;
    outline: 0;
    border: none;
    border-radius: 24px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    transition: background-color .3s ease-out;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    overflow: hidden
}

.vxa-3-input .input-tag, .vxa-3-select .select-tag {
    border-width: 1px;
    border-style: solid;
    box-shadow: none;
    box-sizing: border-box
}

.vxa-3-btn:hover {
    text-decoration: none;
    transition-duration: 0s
}

.vxa-3-btn:hover.-primary {
    background-color: var(--brand-primary-4)
}

.vxa-3-btn:hover.-secondary {
    background-color: var(--brand-secondary-4)
}

.vxa-3-btn:hover.-link, .vxa-3-btn:hover.-white {
    background-color: var(--brand-primary-1)
}

.vxa-3-btn:after {
    content: "";
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    transform-origin: 0 0
}

.vxa-3-btn .btn-text {
    display: inline-block;
    vertical-align: top
}

.vxa-3-btn .btn-status {
    display: block
}

.vxa-3-btn .btn-status, .vxa-3-btn .btn-text {
    color: var(--color-white);
    font-style: normal;
    transition: transform .2s
}

.vxa-3-btn .btn-icon, .vxa-3-btn.-icon .btn-text {
    color: var(--color-white)
}

.vxa-3-btn.-primary, .vxa-3-btn.-primary:after {
    background-color: var(--brand-primary-3)
}

.vxa-3-btn.-secondary, .vxa-3-btn.-secondary:after {
    background-color: var(--brand-secondary-3)
}

.vxa-3-btn.-default {
    background-color: var(--color-gray-4)
}

.vxa-3-btn.-default:after {
    background-color: var(--color-gray-3)
}

.vxa-3-btn.-default .btn-icon, .vxa-3-btn.-default .btn-text {
    color: var(--color-gray-3)
}

.vxa-3-btn.-white .btn-icon, .vxa-3-btn.-white .btn-text {
    color: var(--brand-primary-3)
}

.vxa-3-btn.-white, .vxa-3-btn.-white:after {
    background-color: var(--color-white)
}

.vxa-3-btn.-disable, .vxa-3-btn[disabled] {
    background-color: var(--color-gray-4) !important;
    pointer-events: none
}

.vxa-3-btn.-disable .btn-icon, .vxa-3-btn.-disable .btn-text, .vxa-3-btn[disabled] .btn-icon, .vxa-3-btn[disabled] .btn-text {
    color: var(--color-gray-2) !important
}

.vxa-3-btn.-lg {
    height: 48px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 48px
}

.vxa-3-btn.-lg .btn-icon {
    font-size: 20px
}

.vxa-3-btn.-lg .btn-icon + .btn-text, .vxa-3-btn.-lg .btn-icon:last-child {
    padding-left: 8px
}

.vxa-3-btn.-lg.-circle {
    width: 48px;
    padding: 0;
    border-radius: 50%
}

.vxa-3-btn.-lg.-circle .btn-icon {
    padding: 0
}

.vxa-3-btn.-md {
    height: 36px;
    padding: 0 16px;
    font-size: 14px;
    line-height: 36px
}

.vxa-3-btn.-md .btn-icon {
    font-size: 16px
}

.vxa-3-btn.-md .btn-icon + .btn-text, .vxa-3-btn.-md .btn-icon:last-child {
    padding-left: 8px
}

.vxa-3-btn.-md.-circle {
    width: 36px;
    padding: 0;
    border-radius: 50%
}

.vxa-3-btn.-md.-circle .btn-icon {
    padding: 0
}

.vxa-3-btn.-sm {
    height: 24px;
    padding: 0 10px;
    font-size: 11px;
    line-height: 24px
}

.vxa-3-btn.-sm .btn-icon {
    font-size: 12px
}

.vxa-3-btn.-sm .btn-icon + .btn-text, .vxa-3-btn.-sm .btn-icon:last-child {
    padding-left: 8px
}

.vxa-3-btn:active {
    box-shadow: none;
    transition-duration: 0s
}

.vxa-3-btn:active.-primary {
    background-color: var(--brand-primary-4)
}

.vxa-3-btn:active.-secondary {
    background-color: var(--brand-secondary-4)
}

.vxa-3-btn.-loading {
    position: relative;
    background-color: var(--color-gray-4);
    transform: translate3d(0, 0, 0);
    pointer-events: none
}

.vxa-3-btn.-loading:after {
    display: block;
    animation: vxa-button-loading 1.4s infinite
}

.vxa-3-btn.-loading .btn-icon {
    display: none
}

.vxa-3-btn.-loading.-lg {
    line-height: 46px
}

.vxa-3-btn.-loading.-lg .btn-status, .vxa-3-btn.-loading.-lg .btn-text {
    transform: translateY(-44px) translateZ(0)
}

.vxa-3-btn.-loading.-md {
    line-height: 34px
}

.vxa-3-btn.-loading.-md .btn-status, .vxa-3-btn.-loading.-md .btn-text {
    transform: translateY(-34px) translateZ(0)
}

.vxa-3-btn.-loading.-sm {
    line-height: 22px
}

.vxa-3-btn.-loading.-sm .btn-status, .vxa-3-btn.-loading.-sm .btn-text {
    transform: translateY(-24px) translateZ(0)
}

.vxa-3-btn.-link {
    background-color: transparent;
    cursor: default
}

.vxa-3-btn.-link .btn-icon, .vxa-3-btn.-link .btn-status, .vxa-3-btn.-link .btn-text, .vxa-3-btn.-link.-icon .btn-text {
    color: var(--brand-primary-3);
    cursor: pointer
}

.vxa-3-btn.-dark {
    background-color: var(--color-white)
}

.vxa-3-btn.-dark:hover {
    background-color: var(--vxa-loyalty-primary-100)
}

.vxa-3-btn.-dark .btn-icon, .vxa-3-btn.-dark .btn-status, .vxa-3-btn.-dark .btn-text, .vxa-3-btn.-dark.-icon .btn-text {
    color: var(--color-gray-1)
}

@keyframes vxa-button-loading {
    0% {
        width: 0%;
        border-radius: 0 0 0 4px;
        opacity: .1
    }
    32% {
        border-radius: 0 0 0 4px
    }
    40% {
        width: 100%;
        border-radius: 0 0 4px 4px;
        opacity: .7
    }
    100% {
        width: 100%;
        border-radius: 0 0 4px 4px;
        opacity: 0
    }
}

:root {
    --brand-primary-1: #EBE5F5;
    --brand-primary-2: #A780FF;
    --brand-primary-3: #4300D2;
    --brand-primary-4: #270570;
    --brand-secondary-1: #FFE3E0;
    --brand-secondary-2: #FB8478;
    --brand-secondary-3: #FA503F;
    --brand-secondary-4: #E12B19;
    --brand-tertiary-1: #ECFDF9;
    --brand-tertiary-2: #CBF6EC;
    --brand-tertiary-3: #ADEDDD;
    --brand-tertiary-4: #62C1A3;
    --color-black: #000000;
    --color-gray-1: #444444;
    --color-gray-2: #666666;
    --color-gray-3: #888888;
    --color-gray-4: #CCCCCC;
    --color-gray-5: #EEEEEE;
    --color-white: #FFFFFF;
    --color-green-1: #D6F4F0;
    --color-green-2: #4FC0B2;
    --color-green-3: #fa8d42;
    --color-green-4: #028474;
    --color-purple-1: #EBE5F5;
    --color-purple-2: #A780FF;
    --color-purple-3: #4300D2;
    --color-purple-4: #270570;
    --color-yellow-1: #FFF59D;
    --color-yellow-2: #FDD735;
    --color-yellow-3: #FFC000;
    --color-yellow-4: #F9A824;
    --color-orange-1: #FFE0C3;
    --color-orange-2: #FFBC67;
    --color-orange-3: #FA8E04;
    --color-orange-4: #D56202;
    --color-red-1: #FFE3E0;
    --color-red-2: #FB8478;
    --color-red-3: #FA503F;
    --color-red-4: #E12B19;
    --vxa-loyalty-primary-700: #F04A51;
    --vxa-loyalty-primary-500: #FF5C63;
    --vxa-loyalty-primary-300: #FF7A80;
    --vxa-loyalty-primary-100: #FFF1F2;
    --vxa-loyalty-secondary-500: #FAE74B;
    --vxa-loyalty-secondary-300: #FFF288;
    --vxa-loyalty-secondary-200: #FFF5AB;
    --vxa-loyalty-secondary-100: #FFFBDB;
    --vxa-loyalty-tertiary-900: #12101C;
    --vxa-loyalty-tertiary-800: #1B182B;
    --vxa-loyalty-tertiary-600: #353645;
    --vxa-loyalty-tertiary-400: #535466
}

.vxa-3-h1, .vxa-3-h2 {
    letter-spacing: -.2px;
    font-weight: 500
}

.vxa-3-h1, .vxa-3-h2, .vxa-3-h3, .vxa-3-h4, .vxa-3-h5, .vxa-3-h6 {
    font-weight: 500;
    color: var(--color-gray-1)
}

.vxa-3-h1 {
    font-size: 44px;
    line-height: 48px
}

.vxa-3-h2 {
    font-size: 32px;
    line-height: 36px
}

.vxa-3-h3 {
    font-size: 24px;
    line-height: 32px
}

.vxa-3-h4 {
    font-size: 20px;
    line-height: 28px
}

.vxa-3-h5 {
    font-size: 16px;
    line-height: 24px
}

.vxa-3-h6 {
    font-size: 14px;
    letter-spacing: .1px;
    line-height: 20px
}

.vxa-3-p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400
}

.vxa-3-p.-lg {
    font-size: 16px
}

.vxa-3-small {
    font-size: 11px
}

.vxa-3-body-1 {
    font-size: 16px;
    line-height: 24px
}

.vxa-3-body-2, .vxa-3-button {
    font-size: 14px;
    line-height: 20px
}

.vxa-3-body-2 {
    letter-spacing: .05px
}

.vxa-3-button {
    font-weight: 500
}

.vxa-3-caption {
    font-size: 12px;
    letter-spacing: .1px;
    line-height: 16px
}

.vxa-3-overline-1 {
    font-size: 14px;
    letter-spacing: 1.2px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase
}

.vxa-3-overline-2 {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase
}

.vxa-3-link, .vxa-3-link-underline {
    color: var(--brand-primary-3);
    font-weight: 500;
    transition: color .2s ease-out, opacity .2s;
    text-decoration: none;
    cursor: pointer
}

.vxa-3-link-underline:hover, .vxa-3-link:hover {
    color: var(--brand-primary-4);
    transition: none
}

.vxa-3-link-underline:active, .vxa-3-link:active {
    color: var(--brand-primary-4)
}

.vxa-3-link-underline.-disable, .vxa-3-link-underline[disable], .vxa-3-link.-disable, .vxa-3-link[disable] {
    color: var(--color-gray-5)
}

.vxa-3-link-underline.-lighten, .vxa-3-link.-lighten {
    color: var(--color-gray-4)
}

.vxa-3-link-underline.-lighten:hover, .vxa-3-link.-lighten:hover {
    color: var(--color-white);
    transition: none
}

.vxa-3-link-underline.-lighten:active, .vxa-3-link.-lighten:active {
    color: var(--color-white)
}

.vxa-3-link-underline.-lighten.-disable, .vxa-3-link-underline.-lighten[disable], .vxa-3-link.-lighten.-disable, .vxa-3-link.-lighten[disable] {
    opacity: .6
}

.vxa-3-link-underline {
    text-decoration: underline !important
}

.vxa-3-input .input-container {
    position: relative
}

.vxa-3-input .input-tag {
    border-color: var(--color-gray-4);
    color: var(--color-gray-1);
    transition: border-color .3s ease-out;
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none
}

.vxa-3-input .input-icon, .vxa-3-input .input-tag:disabled ~ .input-icon {
    color: var(--color-gray-3)
}

.vxa-3-input .input-tag::-webkit-input-placeholder {
    color: var(--color-gray-3);
    font-family: Rubik, Arial, sans-serif;
    font-style: italic
}

.vxa-3-input .input-tag:-moz-placeholder {
    color: var(--color-gray-3);
    font-family: Rubik, Arial, sans-serif;
    font-style: italic
}

.vxa-3-input .input-tag::-moz-placeholder {
    color: var(--color-gray-3);
    font-family: Rubik, Arial, sans-serif;
    font-style: italic
}

.vxa-3-input .input-tag:-ms-input-placeholder {
    color: var(--color-gray-3);
    font-family: Rubik, Arial, sans-serif;
    font-style: italic
}

.vxa-3-input .input-tag:focus {
    outline-width: 0;
    border-color: var(--brand-primary-3)
}

.vxa-3-input .input-tag:focus ~ .input-gradient {
    opacity: 0
}

.vxa-3-input .input-tag:disabled {
    border-color: var(--color-gray-4);
    background-color: var(--color-gray-4);
    color: var(--color-gray-3);
    cursor: default;
    opacity: 1
}

.vxa-3-input .input-tag:disabled ~ .input-gradient {
    background-image: linear-gradient(to right, rgba(238, 238, 238, 0), #EEE 80%)
}

.vxa-3-input .input-gradient {
    top: 0;
    width: 30px;
    border-radius: 0 6px 6px 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 80%);
    background-size: 100%;
    transition: opacity .2s linear;
    opacity: 1
}

.vxa-3-input .input-icon, .vxa-3-input .input-loader {
    top: 50%;
    position: absolute
}

.vxa-3-input.-no-border .input-tag {
    border-color: var(--color-white)
}

.vxa-3-input.-no-border .input-tag:focus {
    border-color: var(--brand-primary-3)
}

.vxa-3-input.-no-border .input-tag:disabled {
    border-color: var(--color-gray-4)
}

.vxa-3-input.-invalid .input-tag {
    border-color: var(--color-red-3)
}

.vxa-3-input.-icon-left.-lg .input-icon, .vxa-3-input.-icon-left.-md .input-icon {
    left: 10px;
    margin-top: -12px;
    font-size: 24px
}

.vxa-3-input.-icon-left.-lg .input-tag, .vxa-3-input.-icon-left.-md .input-tag {
    padding-left: 44px
}

.vxa-3-input.-icon-left.-sm .input-icon {
    left: 5px;
    margin-top: -8px;
    font-size: 16px
}

.vxa-3-input.-icon-left.-sm .input-tag {
    padding-left: 30px
}

.vxa-3-input.-icon-right.-lg .input-icon, .vxa-3-input.-icon-right.-md .input-icon {
    right: 10px;
    margin-top: -12px;
    font-size: 24px
}

.vxa-3-input.-icon-right.-lg .input-loader, .vxa-3-input.-icon-right.-md .input-loader {
    right: 10px;
    margin-top: -10px;
    font-size: 20px
}

.vxa-3-input.-icon-right.-lg .input-tag, .vxa-3-input.-icon-right.-md .input-tag {
    padding-right: 40px
}

.vxa-3-input.-icon-right.-lg .input-gradient, .vxa-3-input.-icon-right.-md .input-gradient {
    right: 35px
}

.vxa-3-input.-icon-right.-sm .input-icon {
    right: 5px;
    margin-top: -8px;
    font-size: 16px
}

.vxa-3-input.-icon-right.-sm .input-loader {
    right: 10px;
    width: 16px;
    height: 16px;
    margin-top: -10px;
    font-size: 20px
}

.vxa-3-input.-icon-right.-sm .input-tag {
    padding-right: 30px
}

.vxa-3-input.-icon-right.-sm .input-gradient {
    right: 25px
}

.vxa-3-input.-md .input-label {
    margin-bottom: 16px;
    border-radius: 6px
}

.vxa-3-input.-md .input-tag {
    width: 100%;
    height: 36px;
    padding: 0 16px;
    border-radius: 6px;
    font-size: 14px
}

.vxa-3-input.-md .input-gradient {
    position: absolute;
    right: 1px;
    width: 50px;
    height: 34px;
    margin-top: 1px;
    pointer-events: none
}

@media (max-width: 1023px) {
    .vxa-3-input.-md .input-label {
        margin-bottom: 16px;
        border-radius: 6px
    }

    .vxa-3-input.-md .input-tag {
        width: 100%;
        height: 48px;
        padding: 0 16px;
        border-radius: 6px;
        font-size: 14px
    }

    .vxa-3-input.-md .input-gradient {
        position: absolute;
        right: 1px;
        width: 50px;
        height: 46px;
        margin-top: 1px;
        pointer-events: none
    }
}

.vxa-3-input.-sm .input-label {
    margin-bottom: 8px;
    border-radius: 6px
}

.vxa-3-input.-sm .input-tag {
    width: 100%;
    height: 25px;
    padding: 0 8px;
    border-radius: 6px;
    font-size: 12px
}

.vxa-3-input.-sm .input-gradient {
    position: absolute;
    right: 1px;
    width: 50px;
    height: 23px;
    margin-top: 1px;
    pointer-events: none
}

@media (max-width: 1023px) {
    .vxa-3-input.-sm .input-label {
        margin-bottom: 16px;
        border-radius: 6px
    }

    .vxa-3-input.-sm .input-tag {
        width: 100%;
        height: 48px;
        padding: 0 16px;
        border-radius: 6px;
        font-size: 14px
    }

    .vxa-3-input.-sm .input-gradient {
        position: absolute;
        right: 1px;
        width: 50px;
        height: 46px;
        margin-top: 1px;
        pointer-events: none
    }

    .vxa-3-input.-sm.-icon-left .input-icon {
        left: 10px;
        margin-top: -12px;
        font-size: 24px
    }

    .vxa-3-input.-sm.-icon-left .input-tag {
        padding-left: 40px
    }

    .vxa-3-input.-sm.-icon-right .input-icon {
        right: 10px;
        margin-top: -12px;
        font-size: 24px
    }

    .vxa-3-input.-sm.-icon-right .input-tag {
        padding-right: 40px
    }
}

.vxa-3-input.-lg .input-label {
    margin-bottom: 16px;
    border-radius: 6px
}

.vxa-3-input.-lg .input-tag {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border-radius: 6px;
    font-size: 14px
}

.vxa-3-input.-lg .input-gradient {
    position: absolute;
    right: 1px;
    width: 50px;
    height: 46px;
    margin-top: 1px;
    pointer-events: none
}

.vxa-3-select .select-container {
    position: relative
}

.vxa-3-select .select-container:after {
    content: "\f008";
    display: inline-block;
    position: absolute;
    right: 0;
    color: var(--color-gray-1);
    font-family: vxa-font;
    font-weight: 700;
    text-align: center;
    pointer-events: none
}

.vxa-3-select .select-tag {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    border-color: var(--color-gray-4);
    background-color: var(--color-white);
    color: var(--color-gray-1);
    transition: border-color .3s ease-out;
    cursor: pointer
}

.vxa-3-select .select-tag:disabled {
    border-color: var(--color-gray-4);
    background-color: var(--color-gray-4);
    color: var(--color-gray-2);
    cursor: default;
    opacity: 1
}

.vxa-3-select .select-tag:disabled ~ .select-gradient {
    background-image: linear-gradient(to right, rgba(238, 238, 238, 0), #EEE 80%)
}

.vxa-3-select .select-tag:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--color-black)
}

.vxa-3-select .select-tag::-ms-expand {
    display: none
}

.vxa-3-select .select-tag:focus {
    outline-width: 0;
    border-color: var(--brand-primary-3)
}

.vxa-3-select .select-tag:focus ~ .select-gradient {
    opacity: 0
}

.vxa-3-select .select-gradient {
    top: 0;
    width: 50px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 80%);
    background-size: 100%;
    transition: opacity .2s linear;
    opacity: 1;
    pointer-events: none
}

.vxa-3-select.-no-border .select-tag {
    border-color: var(--color-white)
}

.vxa-3-select.-no-border .select-tag:focus {
    border-color: var(--brand-primary-3)
}

.vxa-3-select.-no-border .select-tag:disabled {
    border-color: var(--color-gray-4)
}

.vxa-3-select.-invalid .select-tag {
    border-color: var(--color-red-3)
}

.vxa-3-select.-lg .select-container:after {
    bottom: 10px;
    box-sizing: border-box;
    width: 38px;
    padding-right: 12px;
    font-size: 24px
}

.vxa-3-select.-lg .select-tag {
    height: 48px;
    padding: 0 38px 0 12px;
    border-radius: 6px;
    font-size: 14px
}

.vxa-3-select.-lg .select-gradient {
    position: absolute;
    right: 38px;
    height: 46px;
    margin-top: 1px
}

.vxa-3-select.-md .select-container:after {
    bottom: 9px;
    box-sizing: border-box;
    width: 28px;
    padding-right: 12px;
    font-size: 16px
}

.vxa-3-select.-md .select-tag {
    height: 36px;
    padding: 0 28px 0 12px;
    border-radius: 6px;
    font-size: 14px
}

.vxa-3-select.-md .select-gradient {
    position: absolute;
    right: 28px;
    height: 34px;
    margin-top: 1px
}

@media (max-width: 1023px) {
    .vxa-3-select.-md .select-container:after {
        bottom: 10px;
        box-sizing: border-box;
        width: 38px;
        padding-right: 12px;
        font-size: 24px
    }

    .vxa-3-select.-md .select-tag {
        height: 48px;
        padding: 0 38px 0 12px;
        border-radius: 6px;
        font-size: 14px
    }

    .vxa-3-select.-md .select-gradient {
        position: absolute;
        right: 38px;
        height: 46px;
        margin-top: 1px
    }
}

.vxa-3-select.-sm .select-container:after {
    bottom: 4px;
    box-sizing: border-box;
    width: 25px;
    padding-right: 12px;
    font-size: 16px
}

.vxa-3-select.-sm .select-tag {
    height: 25px;
    padding: 0 30px 0 8px;
    border-radius: 6px;
    font-size: 12px
}

.vxa-3-select.-sm .select-gradient {
    position: absolute;
    right: 25px;
    height: 23px;
    margin-top: 1px
}

@media (max-width: 1023px) {
    .vxa-3-select.-sm .select-container:after {
        bottom: 10px;
        box-sizing: border-box;
        width: 45px;
        padding-right: 12px;
        font-size: 24px
    }

    .vxa-3-select.-sm .select-tag {
        height: 48px;
        padding: 0 55px 0 12px;
        border-radius: 6px;
        font-size: 14px
    }

    .vxa-3-select.-sm .select-gradient {
        position: absolute;
        right: 45px;
        height: 46px;
        margin-top: 1px
    }
}

.vxa-3-container {
    box-sizing: content-box;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 24px
}

.vxa-3-container.-fluid {
    max-width: none;
    padding: 0;
    overflow-x: hidden
}

.vxa-3-container.-sm {
    max-width: 844px
}

.vxa-3-container.-md {
    max-width: 1062px
}

@media (min-width: 1px) and (max-width: 767px) {
    .vxa-3-container {
        padding: 0 16px
    }
}

.vxa-3-row {
    margin: 0 -12px
}

.vxa-3-row:after, .vxa-3-row:before {
    content: "";
    display: table;
    clear: both
}

@media (min-width: 1px) {
    .vxa-3-row .-sm-1 {
        width: 8.33333%
    }

    .vxa-3-row .-sm-2 {
        width: 16.66667%
    }

    .vxa-3-row .-sm-3 {
        width: 25%
    }

    .vxa-3-row .-sm-4 {
        width: 33.33333%
    }

    .vxa-3-row .-sm-5 {
        width: 41.66667%
    }

    .vxa-3-row .-sm-6 {
        width: 50%
    }

    .vxa-3-row .-sm-7 {
        width: 58.33333%
    }

    .vxa-3-row .-sm-8 {
        width: 66.66667%
    }

    .vxa-3-row .-sm-9 {
        width: 75%
    }

    .vxa-3-row .-sm-10 {
        width: 83.33333%
    }

    .vxa-3-row .-sm-11 {
        width: 91.66667%
    }

    .vxa-3-row .-sm-12 {
        width: 100%
    }
}

@media (min-width: 768px) {
    .vxa-3-row .-md-1 {
        width: 8.33333%
    }

    .vxa-3-row .-md-2 {
        width: 16.66667%
    }

    .vxa-3-row .-md-3 {
        width: 25%
    }

    .vxa-3-row .-md-4 {
        width: 33.33333%
    }

    .vxa-3-row .-md-5 {
        width: 41.66667%
    }

    .vxa-3-row .-md-6 {
        width: 50%
    }

    .vxa-3-row .-md-7 {
        width: 58.33333%
    }

    .vxa-3-row .-md-8 {
        width: 66.66667%
    }

    .vxa-3-row .-md-9 {
        width: 75%
    }

    .vxa-3-row .-md-10 {
        width: 83.33333%
    }

    .vxa-3-row .-md-11 {
        width: 91.66667%
    }

    .vxa-3-row .-md-12 {
        width: 100%
    }
}

.vxa-3-row .col {
    display: block;
    float: left;
    box-sizing: border-box;
    min-height: 1px;
    padding: 0 12px
}

.vxa-3-row.-no-gutter {
    margin-right: 0;
    margin-left: 0
}

.vxa-3-row.-no-gutter > .col {
    padding: 0
}

.-vxa-3-hide {
    display: none !important
}

@media (min-width: 1px) and (max-width: 767px) {
    .vxa-3-row .col {
        padding: 0 8px
    }

    .vxa-3-row {
        margin: 0 -8px
    }

    .vxa-3-row.-no-gutter-sm {
        margin-right: 0;
        margin-left: 0
    }

    .vxa-3-row.-no-gutter-sm > .col {
        padding: 0
    }

    .-vxa-3-hide-medium-down, .-vxa-3-hide-small {
        display: none !important
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .vxa-3-row.-no-gutter-md {
        margin-right: 0;
        margin-left: 0
    }

    .vxa-3-row.-no-gutter-md > .col {
        padding: 0
    }

    .-vxa-3-hide-medium, .-vxa-3-hide-medium-down, .-vxa-3-hide-medium-up {
        display: none !important
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .vxa-3-row.-no-gutter-lg {
        margin-right: 0;
        margin-left: 0
    }

    .vxa-3-row.-no-gutter-lg > .col {
        padding: 0
    }

    .-vxa-3-hide-large, .-vxa-3-hide-medium-up {
        display: none !important
    }
}

@media (min-width: 1024px) {
    .vxa-3-row .-lg-1 {
        width: 8.33333%
    }

    .vxa-3-row .-lg-2 {
        width: 16.66667%
    }

    .vxa-3-row .-lg-3 {
        width: 25%
    }

    .vxa-3-row .-lg-4 {
        width: 33.33333%
    }

    .vxa-3-row .-lg-5 {
        width: 41.66667%
    }

    .vxa-3-row .-lg-6 {
        width: 50%
    }

    .vxa-3-row .-lg-7 {
        width: 58.33333%
    }

    .vxa-3-row .-lg-8 {
        width: 66.66667%
    }

    .vxa-3-row .-lg-9 {
        width: 75%
    }

    .vxa-3-row .-lg-10 {
        width: 83.33333%
    }

    .vxa-3-row .-lg-11 {
        width: 91.66667%
    }

    .vxa-3-row .-lg-12 {
        width: 100%
    }

    .vxa-3-row.-no-gutter-lg {
        margin-right: 0;
        margin-left: 0
    }

    .vxa-3-row.-no-gutter-lg > .col {
        padding: 0
    }

    .-vxa-3-hide-large, .-vxa-3-hide-medium-up {
        display: none !important
    }
}

.vxa-3-card, .vxa-3-driver.-j3, .vxa-3-driver.-j4, .vxa-3-driver.-j5 {
    background-color: var(--color-white)
}

.vxa-3-card {
    box-sizing: border-box;
    border-radius: 8px
}

.vxa-3-driver {
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    max-width: 210px;
    height: 20px;
    padding: 0 8px;
    border-radius: 4px;
    overflow: hidden
}

.vxa-3-driver .driver-text {
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden
}

.vxa-3-driver .driver-icon {
    font-size: 16px
}

.vxa-3-driver .driver-icon + .driver-text {
    margin-left: 4px
}

.vxa-3-driver .driver-icon + .driver-icon {
    margin-left: 2px
}

.vxa-3-driver + .vxa-3-driver {
    margin-left: 8px
}

.vxa-3-driver.-border, .vxa-3-driver.-j2, .vxa-3-driver.-j3, .vxa-3-driver.-j4, .vxa-3-driver.-j5 {
    border: 1px solid var(--color-gray-4)
}

.vxa-3-driver.-j4, .vxa-3-driver.-j5 {
    color: var(--color-gray-1)
}

.vxa-3-driver.-benefits.-j1, .vxa-3-driver.-benefits.-j2, .vxa-3-driver.-info.-j1, .vxa-3-driver.-info.-j2 {
    font-weight: 500
}

.vxa-3-driver.-benefits.-j1, .vxa-3-driver.-info.-j1 {
    color: var(--color-white)
}

.vxa-3-driver.-benefits.-j1 {
    background-color: var(--color-green-3)
}

.vxa-3-driver.-benefits.-j2, .vxa-3-driver.-benefits.-j3, .vxa-3-driver.-benefits.-j4 .driver-icon, .vxa-3-driver.-benefits.-j5 .driver-icon {
    background-color: var(--color-white);
    color: var(--color-green-3)
}

.vxa-3-driver.-info.-j1 {
    background-color: var(--brand-primary-4)
}

.vxa-3-driver.-info.-j2, .vxa-3-driver.-info.-j3, .vxa-3-driver.-info.-j4 .driver-icon, .vxa-3-driver.-info.-j5 .driver-icon {
    background-color: var(--color-white);
    color: var(--brand-primary-4)
}

.vxa-3-driver.-urgent.-j3, .vxa-3-driver.-urgent.-j4 .driver-icon, .vxa-3-driver.-urgent.-j5 .driver-icon {
    color: var(--brand-secondary-3)
}

.vxa-3-driver.-urgent.-j4, .vxa-3-driver.-urgent.-j5 {
    color: var(--color-gray-1)
}

.vxa-3-driver.-loyalty.-j1 {
    background-color: var(--vxa-loyalty-primary-500);
    color: var(--color-white);
    font-weight: 500
}

.vxa-3-label, .vxa-3-label .label-container {
    display: inline-flex;
    position: relative
}

.vxa-3-label, .vxa-3-label .label-container .label-text.-linethrough {
    position: relative
}

.vxa-3-label.-top-left, .vxa-3-label.-top-right {
    padding-top: 12px
}

.vxa-3-label.-bottom-left, .vxa-3-label.-bottom-right {
    padding-bottom: 12px
}

.vxa-3-label .label-container .label-icon + .label-text, .vxa-3-label.-compound.-lg .label-icon, .vxa-3-label.-compound.-md .label-icon {
    margin-left: 4px
}

.vxa-3-label {
    overflow: hidden
}

.vxa-3-label .label-container {
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 40px;
    border-radius: 24px;
    color: var(--color-white);
    z-index: 1
}

.vxa-3-label .label-container .label-text.-linethrough:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 3px;
    background-color: var(--color-green-1);
    transform: translateY(-2px)
}

.vxa-3-label.-top-left .label-container {
    border-top-left-radius: 0;
    border-bottom-left-radius: 8px
}

.vxa-3-label.-top-left .label-container + .label-container, .vxa-3-label.-top-right .label-container + .label-container {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.vxa-3-label.-top-left .label-container:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 0 0 16px;
    border-color: transparent;
    top: 0;
    left: 0;
    transform: translateY(-100%)
}

.vxa-3-label.-top-left .label-container + .label-container:before {
    content: none
}

.vxa-3-label.-top-left .label-container.-vxa-3-bc-green-3:before {
    border-left-color: var(--color-green-3)
}

.vxa-3-label.-top-left .label-container.-vxa-3-bc-purple-4:before {
    border-left-color: var(--brand-primary-4)
}

.vxa-3-label.-top-left .label-container.-vxa-3-bc-bubblegum-1:before {
    border-left-color: var(--vxa-loyalty-primary-300)
}

.vxa-3-label.-top-right .label-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 8px
}

.vxa-3-label.-top-right .label-container:last-child:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 12px 16px;
    border-color: transparent;
    top: 0;
    right: 0;
    transform: translateY(-100%)
}

.vxa-3-label.-top-right .label-container.-vxa-3-bc-green-3:before {
    border-bottom-color: var(--color-green-3)
}

.vxa-3-label.-top-right .label-container.-vxa-3-bc-purple-4:before {
    border-bottom-color: var(--brand-primary-4)
}

.vxa-3-label.-top-right .label-container.-vxa-3-bc-bubblegum-1:before {
    border-bottom-color: var(--vxa-loyalty-primary-300)
}

.vxa-3-label.-bottom-left .label-container {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0
}

.vxa-3-label.-bottom-left .label-container + .label-container, .vxa-3-label.-bottom-right .label-container + .label-container {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.vxa-3-label.-bottom-left .label-container:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 16px 0 0;
    border-color: transparent;
    bottom: 0;
    left: 0;
    transform: translateY(100%)
}

.vxa-3-label.-bottom-left .label-container + .label-container:before {
    content: none
}

.vxa-3-label.-bottom-left .label-container.-vxa-3-bc-green-3:before {
    border-top-color: var(--color-green-3)
}

.vxa-3-label.-bottom-left .label-container.-vxa-3-bc-purple-4:before {
    border-top-color: var(--brand-primary-4)
}

.vxa-3-label.-bottom-left .label-container.-vxa-3-bc-bubblegum-1:before {
    border-top-color: var(--vxa-loyalty-primary-300)
}

.vxa-3-label.-bottom-right .label-container {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0
}

.vxa-3-label.-bottom-right .label-container:last-child:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 16px 12px 0;
    border-color: transparent;
    right: 0;
    bottom: 0;
    transform: translateY(100%)
}

.vxa-3-label.-bottom-right .label-container.-vxa-3-bc-green-3:before {
    border-right-color: var(--color-green-3)
}

.vxa-3-label.-bottom-right .label-container.-vxa-3-bc-purple-4:before {
    border-right-color: var(--brand-primary-4)
}

.vxa-3-label.-bottom-right .label-container.-vxa-3-bc-bubblegum-1:before {
    border-right-color: var(--vxa-loyalty-primary-300)
}

.vxa-3-label.-sm .label-container {
    height: 28px;
    padding: 0 8px
}

.vxa-3-label.-sm .label-text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400
}

.vxa-3-label.-sm .label-icon {
    width: 12px;
    height: 12px;
    font-size: 12px;
    line-height: 12px
}

.vxa-3-label.-sm.-top-left, .vxa-3-label.-sm.-top-right {
    padding-top: 8px
}

.vxa-3-label.-sm.-bottom-left, .vxa-3-label.-sm.-bottom-right {
    padding-bottom: 8px
}

.vxa-3-label.-sm.-top-left .label-container:before {
    border-width: 8px 0 0 12px
}

.vxa-3-label.-sm.-top-right .label-container:before {
    border-width: 0 0 8px 12px
}

.vxa-3-label.-sm.-bottom-left .label-container:before {
    border-width: 8px 12px 0 0
}

.vxa-3-label.-sm.-bottom-right .label-container:before {
    border-width: 0 12px 8px 0
}

.vxa-3-label.-md .label-container {
    height: 32px;
    padding: 0 12px
}

.vxa-3-label.-md .label-text {
    font-size: 14px;
    letter-spacing: .05px;
    line-height: 20px
}

.vxa-3-label.-md .label-icon {
    width: 16px;
    height: 16px;
    font-size: 16px;
    line-height: 16px
}

.vxa-3-label.-md .label-extra {
    padding-right: 12px
}

.vxa-3-label.-lg .label-container {
    height: 36px;
    padding: 0 16px
}

.vxa-3-label.-lg .label-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500
}

.vxa-3-label.-lg .label-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px
}

.vxa-3-label.-lg .label-extra {
    padding-right: 16px
}

.vxa-3-label.-green .label-container {
    background-color: var(--color-green-3)
}

.vxa-3-label.-green.-top-left .label-container:before {
    border-left-color: var(--color-green-3)
}

.vxa-3-label.-green.-top-right .label-container:before {
    border-bottom-color: var(--color-green-3)
}

.vxa-3-label.-green.-bottom-left .label-container:before {
    border-top-color: var(--color-green-3)
}

.vxa-3-label.-green.-bottom-right .label-container:before {
    border-right-color: var(--color-green-3)
}

.vxa-3-label.-purple .label-container {
    background-color: var(--brand-primary-4)
}

.vxa-3-label.-purple.-top-left .label-container:before {
    border-left-color: var(--brand-primary-4)
}

.vxa-3-label.-purple.-top-right .label-container:before {
    border-bottom-color: var(--brand-primary-4)
}

.vxa-3-label.-purple.-bottom-left .label-container:before {
    border-top-color: var(--brand-primary-4)
}

.vxa-3-label.-purple.-bottom-right .label-container:before {
    border-right-color: var(--brand-primary-4)
}

.vxa-3-label.-loyalty .label-container {
    background-color: var(--vxa-loyalty-primary-300)
}

.vxa-3-label.-loyalty.-top-left .label-container:before {
    border-left-color: var(--vxa-loyalty-primary-300)
}

.vxa-3-label.-loyalty.-top-right .label-container:before {
    border-bottom-color: var(--vxa-loyalty-primary-300)
}

.vxa-3-label.-loyalty.-bottom-left .label-container:before {
    border-top-color: var(--vxa-loyalty-primary-300)
}

.vxa-3-label.-loyalty.-bottom-right .label-container:before {
    border-right-color: var(--vxa-loyalty-primary-300)
}

.vxa-3-label.-compound .label-container {
    background-color: var(--color-green-3)
}

.vxa-3-label.-compound .label-container:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.vxa-3-label.-compound .label-container.-vxa-3-bc-white {
    border: 1px solid var(--color-gray-4)
}

.vxa-3-label.-compound.-bottom-left .label-container.-vxa-3-bc-white, .vxa-3-label.-compound.-top-left .label-container.-vxa-3-bc-white {
    border-left: 0;
    border-top-left-radius: 0
}

.vxa-3-label.-compound.-bottom-right .label-container.-vxa-3-bc-white, .vxa-3-label.-compound.-top-right .label-container.-vxa-3-bc-white {
    border-right: 0
}

.vxa-3-label.-compound.-top-left .label-container:before {
    border-left-color: var(--color-green-3)
}

.vxa-3-label.-compound.-top-right .label-container:before {
    border-bottom-color: var(--color-green-3)
}

.vxa-3-label.-compound.-bottom-left .label-container:before {
    border-top-color: var(--color-green-3)
}

.vxa-3-label.-compound.-bottom-right .label-container:before {
    border-right-color: var(--color-green-3)
}

.vxa-3-label.-compound.-lg .label-icon + .label-text, .vxa-3-label.-compound.-md .label-icon + .label-text {
    margin-right: 4px
}

.vxa-3-label.-secret-deals .label-container {
    border: 1px solid var(--color-gray-4);
    background-color: var(--color-white);
    color: var(--color-gray-2)
}

.vxa-3-label.-secret-deals.-bottom-left .label-container:after, .vxa-3-label.-secret-deals.-bottom-right .label-container:after, .vxa-3-label.-secret-deals.-top-left .label-container:after, .vxa-3-label.-secret-deals.-top-right .label-container:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid
}

.vxa-3-label.-secret-deals .label-container:before {
    z-index: 1
}

.vxa-3-label.-secret-deals.-top-left .label-container:before {
    top: 2px;
    border-left-color: var(--color-white)
}

.vxa-3-label.-secret-deals.-top-left .label-container:after {
    border-width: 12px 0 0 16px;
    border-color: transparent;
    top: 0;
    left: -1px;
    border-left-color: var(--color-gray-4);
    transform: translateY(-100%)
}

.vxa-3-label.-secret-deals.-top-right .label-container:before {
    top: 2px;
    border-bottom-color: var(--color-white)
}

.vxa-3-label.-secret-deals.-top-right .label-container:after {
    border-width: 0 0 12px 16px;
    border-color: transparent;
    top: 0;
    right: -1px;
    border-bottom-color: var(--color-gray-4);
    transform: translateY(-100%)
}

.vxa-3-label.-secret-deals.-bottom-left .label-container:before {
    bottom: 2px;
    border-top-color: var(--color-white)
}

.vxa-3-label.-secret-deals.-bottom-left .label-container:after {
    border-width: 12px 16px 0 0;
    border-color: transparent;
    bottom: 0;
    left: -1px;
    border-top-color: var(--color-gray-4);
    transform: translateY(100%)
}

.vxa-3-label.-secret-deals.-bottom-right .label-container:before {
    bottom: 2px;
    border-right-color: var(--color-white)
}

.vxa-3-label.-secret-deals.-bottom-right .label-container:after {
    border-width: 0 16px 12px 0;
    border-color: transparent;
    right: -1px;
    bottom: 0;
    border-right-color: var(--color-gray-4);
    transform: translateY(100%)
}

.vxa-3-label.-secret-deals.-sm.-top-left .label-container:after {
    border-width: 8px 0 0 12px
}

.vxa-3-label.-secret-deals.-sm.-top-right .label-container:after {
    border-width: 0 0 8px 12px
}

.vxa-3-label.-secret-deals.-sm.-bottom-left .label-container:after {
    border-width: 8px 12px 0 0
}

.vxa-3-label.-secret-deals.-sm.-bottom-right .label-container:after {
    border-width: 0 12px 8px 0
}

.vxa-3-nav-dots {
    display: flex;
    position: relative;
    justify-content: center
}

.vxa-3-nav-dots .nav-dot {
    position: relative;
    width: 12px;
    height: 4px;
    margin: 0 4px;
    border-radius: 10px;
    background-color: var(--color-gray-4);
    transform-origin: center center;
    transition: none;
    transition: .2s width, .2s background-color, .2s left
}

.vxa-3-nav-dots .nav-dot.-active {
    width: 16px;
    background-color: var(--brand-primary-3)
}

.vxa-3-nav-dots.-lighten .nav-dot {
    border: 0;
    background-color: rgba(255, 255, 255, 0.5)
}

.vxa-3-nav-dots.-lighten .nav-dot.-active, .vxa-3-nav-dots.-lighten .nav-dot:after {
    background-color: var(--color-white)
}

.vxa-3-nav-dots.-lg .nav-dot {
    width: 16px
}

.vxa-3-nav-dots.-lg .nav-dot.-active {
    width: 20px
}

.vxa-3-nav-dots.-sm .nav-dot {
    width: 8px
}

.vxa-3-nav-dots.-sm .nav-dot.-active {
    max-width: 10px
}

.vxa-3-nav-dots.-center {
    text-align: center
}

.vxa-3-nav-dots.-left {
    text-align: left
}

.vxa-3-nav-dots.-right {
    text-align: right
}

.vxa-3-nav-dots.-carousel {
    justify-content: normal;
    width: 80px;
    overflow: hidden
}

.vxa-3-nav-dots.-carousel.-sm .nav-dot.-active-next-next, .vxa-3-nav-dots.-carousel.-sm .nav-dot.-active-next-next-next, .vxa-3-nav-dots.-carousel.-sm .nav-dot.-active-prev-prev, .vxa-3-nav-dots.-carousel.-sm .nav-dot.-active-prev-prev-prev, .vxa-3-nav-dots.-carousel.-sm .nav-dot.-far, .vxa-3-nav-dots.-carousel.-sm .nav-dot.-near {
    width: calc(8px * .5)
}

.vxa-3-nav-dots.-carousel.-sm .nav-dot.-active-next, .vxa-3-nav-dots.-carousel.-sm .nav-dot.-active-prev, .vxa-3-nav-dots.-carousel.-sm .nav-dot.-nearest {
    width: calc(8px * .66667)
}

.vxa-3-nav-dots.-carousel.-lg {
    width: 93px
}

.vxa-3-nav-dots.-carousel.-lg .nav-dot.-active-next-next-next, .vxa-3-nav-dots.-carousel.-lg .nav-dot.-active-prev-prev-prev, .vxa-3-nav-dots.-carousel.-lg .nav-dot.-far {
    width: calc(16px * .33333)
}

.vxa-3-nav-dots.-carousel.-lg .nav-dot.-active-next-next, .vxa-3-nav-dots.-carousel.-lg .nav-dot.-active-prev-prev, .vxa-3-nav-dots.-carousel.-lg .nav-dot.-near {
    width: calc(16px * .5)
}

.vxa-3-nav-dots.-carousel.-lg .nav-dot.-active-next, .vxa-3-nav-dots.-carousel.-lg .nav-dot.-active-prev, .vxa-3-nav-dots.-carousel.-lg .nav-dot.-nearest {
    width: calc(16px * .66667)
}

.vxa-3-nav-dots.-carousel .nav-dot {
    flex-shrink: 0
}

.vxa-3-nav-dots.-carousel .nav-dot.-out {
    width: 0;
    transition-duration: 0;
}

.vxa-3-nav-dots.-carousel .nav-dot.-active-next-next-next, .vxa-3-nav-dots.-carousel .nav-dot.-active-prev-prev-prev, .vxa-3-nav-dots.-carousel .nav-dot.-far {
    width: calc(12px * .33333)
}

.vxa-3-nav-dots.-carousel .nav-dot.-active-next-next, .vxa-3-nav-dots.-carousel .nav-dot.-active-prev-prev, .vxa-3-nav-dots.-carousel .nav-dot.-near {
    width: calc(12px * .5)
}

.vxa-3-nav-dots.-carousel .nav-dot.-active-next, .vxa-3-nav-dots.-carousel .nav-dot.-active-prev, .vxa-3-nav-dots.-carousel .nav-dot.-nearest {
    width: calc(12px * .66667)
}

.vxa-3-nav-slider.-left, .vxa-3-nav-slider.-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.vxa-3-nav-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--color-gray-2);
    text-align: center;
    transition: opacity .5s ease-out;
    cursor: pointer
}

.vxa-3-nav-slider:hover {
    color: var(--color-gray-1);
    opacity: 1
}

.vxa-3-nav-slider.-sm {
    width: 32px;
    height: 32px
}

.vxa-3-nav-slider.-sm .nav-slider-icon {
    font-size: 16px
}

.vxa-3-nav-slider.-sm .nav-slider-icon.vxa-3-icon-arrow-right {
    margin-right: -2px
}

.vxa-3-nav-slider.-md {
    width: 48px;
    height: 48px
}

.vxa-3-nav-slider.-md .nav-slider-icon {
    font-size: 24px
}

.vxa-3-nav-slider.-md .nav-slider-icon.vxa-3-icon-arrow-right {
    margin-right: -4px
}

.vxa-3-nav-slider.-lg .nav-slider-icon.vxa-3-icon-arrow-right, .vxa-3-nav-slider.-xlg .nav-slider-icon.vxa-3-icon-arrow-right {
    margin-top: 1px;
    margin-right: -5px
}

.vxa-3-nav-slider.-md .nav-slider-icon.vxa-3-icon-arrow-left {
    margin-left: -1px
}

.vxa-3-nav-slider.-lg .nav-slider-icon.vxa-3-icon-arrow-left, .vxa-3-nav-slider.-xlg .nav-slider-icon.vxa-3-icon-arrow-left {
    margin-top: 1px;
    margin-left: -2px
}

.vxa-3-nav-slider.-lg {
    width: 64px;
    height: 64px
}

.vxa-3-nav-slider.-lg .nav-slider-icon {
    font-size: 32px
}

.vxa-3-nav-slider.-xlg {
    width: 72px;
    height: 72px
}

.vxa-3-nav-slider.-xlg .nav-slider-icon {
    font-size: 42px
}

.vxa-3-nav-slider.-negative {
    background-color: var(--color-gray-2);
    color: var(--color-white)
}

.vxa-3-nav-slider.-negative:hover {
    background-color: var(--color-gray-1)
}

.vxa-3-nav-slider.-white {
    background-color: var(--color-white)
}

.vxa-3-nav-slider.-white:hover {
    color: var(--color-gray-1);
    opacity: 1
}

.vxa-3-nav-slider.-disabled {
    color: var(--color-gray-2);
    cursor: unset;
    opacity: 1
}

.vxa-3-nav-slider.-disabled.-negative, .vxa-3-nav-slider.-disabled.-white {
    background-color: var(--color-gray-4)
}

.vxa-3-nav-slider.-disabled.-negative:hover, .vxa-3-nav-slider.-disabled.-white:hover {
    color: var(--color-gray-2)
}

.vxa-3-rating .rating-text, .vxa-3-tooltip .tooltip-container .vxa-3-h5, .vxa-3-tooltip .tooltip-container .tooltip-text {
    color: var(--color-white)
}

.vxa-3-nav-slider.-left {
    left: 0
}

.vxa-3-nav-slider.-right {
    right: 0
}

.vxa-3-rating {
    display: flex;
    align-items: center;
    justify-content: center
}

.vxa-3-rating.-lg {
    width: 60px;
    height: 48px;
    border-radius: 8px
}

.vxa-3-rating.-lg .rating-text {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500
}

.vxa-3-rating.-md {
    width: 36px;
    height: 28px;
    border-radius: 6px
}

.vxa-3-rating.-md .rating-text {
    font-size: 16px;
    line-height: 24px
}

.vxa-3-rating.-sm {
    width: 24px;
    height: 20px;
    border-radius: 4px
}

.vxa-3-rating.-sm .rating-text {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400
}

.vxa-3-rating.-good {
    background-color: var(--color-green-3)
}

.vxa-3-rating.-regular {
    background-color: var(--color-orange-3)
}

.vxa-3-rating.-bad {
    background-color: var(--color-red-3)
}

.-vxa-3-shadow-1-hover, .-vxa-3-shadow-2-hover, .-vxa-3-shadow-line-hover, .-vxa-3-shadow-static-hover, .vxa-3-shadow {
    transition: box-shadow .2s ease-out
}

.-vxa-3-shadow-line, .-vxa-3-shadow-line-hover {
    border: 1px solid var(--color-gray-4)
}

.vxa-3-shadow {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2)
}

.vxa-3-shadow.-plus:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4)
}

.-vxa-3-shadow-1-hover:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
}

.-vxa-3-shadow-2-hover:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3)
}

.-vxa-3-shadow-1 {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2)
}

.-vxa-3-shadow-2 {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3)
}

.-vxa-3-shadow-3 {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.25)
}

.-vxa-3-shadow-line-hover:hover {
    box-shadow: 0 12px 20px rgba(68, 68, 68, 0.2)
}

.-vxa-3-shadow-static-hover {
    box-shadow: 0 4px 8px rgba(68, 68, 68, 0.2)
}

.-vxa-3-shadow-static-hover:hover {
    box-shadow: 0 12px 20px rgba(68, 68, 68, 0.2)
}

.-vxa-3-shadow-static {
    box-shadow: 0 4px 8px rgba(68, 68, 68, 0.2)
}

.-vxa-3-shadow-top {
    box-shadow: 0 -2px 8px rgba(68, 68, 68, 0.16)
}

.-vxa-3-shadow-bottom {
    box-shadow: 0 2px 8px rgba(68, 68, 68, 0.16)
}

.vxa-3-nav-view-more {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 230px;
    text-decoration: none;
    cursor: pointer
}

.vxa-3-nav-view-more .view-more-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: #fff
}

.vxa-3-nav-view-more .view-more-label {
    margin-top: 15px;
    font-size: 16px;
    cursor: inherit
}

.vxa-3-tooltip {
    position: relative;
    white-space: normal;
    cursor: pointer
}

.vxa-3-tooltip:hover.-bottom .tooltip-container, .vxa-3-tooltip:hover.-bottom .tooltip-content, .vxa-3-tooltip:hover.-top .tooltip-container, .vxa-3-tooltip:hover.-top .tooltip-content {
    display: block;
    transform: translateY(0);
    visibility: visible;
    opacity: 1
}

.vxa-3-tooltip:hover.-left-inline .tooltip-container, .vxa-3-tooltip:hover.-right-inline .tooltip-container {
    transform: translate(0, -50%);
    visibility: visible;
    opacity: 1;
    display: flex
}

.vxa-3-tooltip:hover.-left-inline .tooltip-content, .vxa-3-tooltip:hover.-right-inline .tooltip-content {
    display: block;
    transform: translate(0);
    visibility: visible;
    opacity: 1
}

.vxa-3-tooltip .tooltip-container {
    position: relative;
    width: 290px;
    margin-left: -145px;
    padding: 16px;
    background-color: var(--brand-primary-4);
    text-align: center;
    cursor: auto;
    z-index: 1
}

.vxa-3-tooltip .tooltip-container .vxa-3-h5 {
    display: block;
    padding-bottom: 8px
}

.vxa-3-tooltip .tooltip-marker {
    position: absolute;
    width: 40px;
    height: 20px;
    overflow: hidden
}

.vxa-3-tooltip .tooltip-marker:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    width: 15px;
    height: 15px;
    margin-left: -8px;
    background-color: var(--brand-primary-4);
    transform: rotateZ(45deg)
}

.vxa-3-tooltip .tooltip-close {
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--color-white);
    transition: opacity .2s ease-out;
    cursor: pointer;
    opacity: 1;
    z-index: 1
}

.vxa-3-tooltip .tooltip-close:hover {
    transition-duration: 0s;
    opacity: .5
}

.vxa-3-tooltip.-block {
    display: inherit
}

.vxa-3-tooltip.-sm .tooltip-container {
    width: 153px;
    margin-left: -77px;
    padding: 12px
}

.vxa-3-tooltip.-bottom.-show-tooltip .tooltip-container, .vxa-3-tooltip.-bottom.-show-tooltip .tooltip-content, .vxa-3-tooltip.-top.-show-tooltip .tooltip-container, .vxa-3-tooltip.-top.-show-tooltip .tooltip-content {
    display: block;
    transform: translateY(0);
    visibility: visible;
    opacity: 1
}

.vxa-3-tooltip.-left-inline.-show-tooltip .tooltip-container, .vxa-3-tooltip.-right-inline.-show-tooltip .tooltip-container {
    transform: translate(0, -50%);
    visibility: visible;
    opacity: 1;
    display: flex
}

.vxa-3-tooltip.-left-inline.-show-tooltip .tooltip-content, .vxa-3-tooltip.-right-inline.-show-tooltip .tooltip-content {
    display: block;
    transform: translateY(0);
    visibility: visible;
    opacity: 1
}

.vxa-3-tooltip.-bottom .tooltip-container, .vxa-3-tooltip.-bottom .tooltip-content, .vxa-3-tooltip.-left-inline .tooltip-container, .vxa-3-tooltip.-left-inline .tooltip-content, .vxa-3-tooltip.-right-inline .tooltip-container, .vxa-3-tooltip.-right-inline .tooltip-content, .vxa-3-tooltip.-top .tooltip-container, .vxa-3-tooltip.-top .tooltip-content {
    transition: opacity .15s ease-out, transform .15s ease-out, visibility .15s ease-out;
    visibility: hidden;
    opacity: 0
}

.vxa-3-tooltip.-left-inline .tooltip-container, .vxa-3-tooltip.-right-inline .tooltip-container {
    top: 50%;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%)
}

.vxa-3-tooltip.-left-inline .tooltip-marker, .vxa-3-tooltip.-right-inline .tooltip-marker {
    transform: rotate(90deg)
}

.vxa-3-tooltip.-top .tooltip-container {
    position: absolute;
    left: 50%;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 400;
    display: block;
    transform: translateY(5px);
    transform-origin: 50% 110%;
    bottom: calc(100% + 10px)
}

.vxa-3-tooltip.-top .tooltip-marker {
    left: 50%;
    margin-left: -20px;
    bottom: -20px
}

.vxa-3-tooltip.-top .tooltip-marker:after {
    top: -9px;
    border-radius: 2px
}

.vxa-3-tooltip.-top .tooltip-content {
    display: block;
    transform: translateY(5px);
    transform-origin: 50% 110%
}

.vxa-3-tooltip.-bottom .tooltip-container {
    position: absolute;
    left: 50%;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 400;
    display: block;
    transform: translateY(-5px);
    transform-origin: 50% 110%;
    top: calc(100% + 10px)
}

.vxa-3-tooltip.-bottom .tooltip-marker {
    left: 50%;
    margin-left: -20px;
    top: -20px
}

.vxa-3-tooltip.-bottom .tooltip-marker:after {
    bottom: -9px
}

.vxa-3-tooltip.-bottom .tooltip-content {
    display: block;
    transform: translateY(-5px);
    transform-origin: 50% 110%
}

.vxa-3-tooltip.-right .tooltip-container {
    right: auto;
    left: 50%;
    margin-left: -25px
}

.vxa-3-tooltip.-right .tooltip-marker {
    right: auto;
    left: 5px;
    margin: 0
}

.vxa-3-tooltip.-right-inline .tooltip-marker {
    margin-left: -20px;
    left: -10px
}

.vxa-3-tooltip.-right-inline .tooltip-container {
    position: absolute;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 400;
    transform: translate(-5px, -50%);
    transform-origin: 50% 110%;
    display: flex;
    left: 20px;
    margin-left: 100%
}

.vxa-3-tooltip.-right-inline .tooltip-marker:after {
    top: -9px
}

.vxa-3-tooltip.-right-inline .tooltip-content {
    display: block;
    transform: translateX(-5px);
    transform-origin: 50% 110%
}

.vxa-3-tooltip.-left-inline .tooltip-container {
    position: absolute;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 400;
    transform: translate(5px, -50%);
    transform-origin: 50% 110%;
    display: flex
}

.vxa-3-tooltip.-left-inline .tooltip-marker {
    left: -10px;
    margin-left: 100%
}

.vxa-3-tooltip.-left-inline .tooltip-marker:after {
    top: 13px
}

.vxa-3-tooltip.-left-inline .tooltip-content {
    display: block;
    transform: translateX(5px);
    transform-origin: 50% 110%
}

.vxa-3-tooltip.-left-inline .tooltip-container {
    right: 0;
    left: auto;
    margin-right: calc(100% + 20px)
}

.vxa-3-tooltip.-left .tooltip-container {
    right: 50%;
    left: auto;
    margin-right: -25px
}

.vxa-3-tooltip.-left .tooltip-marker {
    right: 5px;
    left: auto;
    margin: 0
}

.vxa-3-tooltip.-white.-sm .tooltip-container, .vxa-3-tooltip.-white.-sm .tooltip-marker {
    border-radius: 4px
}

.vxa-3-tooltip.-white .tooltip-container, .vxa-3-tooltip.-white .tooltip-marker {
    border-radius: 8px
}

.vxa-3-tooltip.-white .tooltip-container, .vxa-3-tooltip.-white .tooltip-marker:after {
    border: 1px solid var(--color-gray-4);
    background-color: var(--color-white)
}

.vxa-3-tooltip.-white .tooltip-container {
    text-align: left
}

.vxa-3-tooltip.-white .vxa-3-h5, .vxa-3-tooltip.-white .tooltip-close, .vxa-3-tooltip.-white .tooltip-text {
    color: var(--color-gray-1)
}

.vxa-3-tooltip.-white.-close .tooltip-content {
    padding-right: 30px
}

.vxa-3-tooltip.-not-hover, .vxa-3-tooltip.-not-hover:hover {
    cursor: auto
}

.vxa-3-tooltip.-not-hover .tooltip-container, .vxa-3-tooltip.-not-hover .tooltip-content, .vxa-3-tooltip.-not-hover:hover .tooltip-container, .vxa-3-tooltip.-not-hover:hover .tooltip-content {
    display: none
}

.vxa-3-tooltip.-not-hover.-show-tooltip .tooltip-container, .vxa-3-tooltip.-not-hover.-show-tooltip .tooltip-content, .vxa-3-tooltip.-not-hover:hover.-show-tooltip .tooltip-container, .vxa-3-tooltip.-not-hover:hover.-show-tooltip .tooltip-content {
    display: block
}

.vxa-3-tooltip.-not-hover.-show-tooltip.-left-inline .tooltip-container, .vxa-3-tooltip.-not-hover.-show-tooltip.-right-inline .tooltip-container, .vxa-3-tooltip.-not-hover:hover.-show-tooltip.-left-inline .tooltip-container, .vxa-3-tooltip.-not-hover:hover.-show-tooltip.-right-inline .tooltip-container {
    display: flex
}

@media (min-width: 768px) and (max-width: 1023px) {
    .vxa-3-tooltip.-medium-center.-left-inline .tooltip-container, .vxa-3-tooltip.-medium-center.-right-inline .tooltip-container, .vxa-3-tooltip.-medium-left.-left-inline .tooltip-container, .vxa-3-tooltip.-medium-left.-right-inline .tooltip-container, .vxa-3-tooltip.-medium-right.-left-inline .tooltip-container, .vxa-3-tooltip.-medium-right.-right-inline .tooltip-container, .vxa-3-tooltip.-not-hover-medium .tooltip-container, .vxa-3-tooltip.-not-hover-medium .tooltip-content, .vxa-3-tooltip.-not-hover-medium:hover .tooltip-container, .vxa-3-tooltip.-not-hover-medium:hover .tooltip-content {
        display: none
    }

    .vxa-3-tooltip.-medium-right .tooltip-container {
        right: auto;
        left: 50%;
        margin-right: auto;
        margin-left: -25px
    }

    .vxa-3-tooltip.-medium-right .tooltip-marker {
        right: auto;
        left: 5px;
        margin: 0
    }

    .vxa-3-tooltip.-medium-left .tooltip-container {
        right: 50%;
        left: auto;
        margin-right: -25px;
        margin-left: auto
    }

    .vxa-3-tooltip.-medium-left .tooltip-marker {
        right: 5px;
        left: auto;
        margin: 0
    }

    .vxa-3-tooltip.-medium-center .tooltip-container {
        right: auto;
        left: 50%;
        margin-right: auto;
        margin-left: -145px
    }

    .vxa-3-tooltip.-medium-center .tooltip-marker {
        right: auto;
        left: 50%;
        margin-right: auto;
        margin-left: -20px
    }

    .vxa-3-tooltip.-medium-center.-sm .tooltip-container {
        margin-left: -83px
    }

    .vxa-3-tooltip.-not-hover-medium, .vxa-3-tooltip.-not-hover-medium:hover {
        cursor: auto
    }

    .vxa-3-tooltip.-not-hover-medium.-show-tooltip .tooltip-container, .vxa-3-tooltip.-not-hover-medium.-show-tooltip .tooltip-content, .vxa-3-tooltip.-not-hover-medium:hover.-show-tooltip .tooltip-container, .vxa-3-tooltip.-not-hover-medium:hover.-show-tooltip .tooltip-content {
        display: block
    }
}

@media (min-width: 1px) and (max-width: 767px) {
    .vxa-3-tooltip.-not-hover-small .tooltip-container, .vxa-3-tooltip.-not-hover-small .tooltip-content, .vxa-3-tooltip.-not-hover-small:hover .tooltip-container, .vxa-3-tooltip.-not-hover-small:hover .tooltip-content, .vxa-3-tooltip.-sm-full .tooltip-container .tooltip-marker, .vxa-3-tooltip.-small-center.-left-inline .tooltip-container, .vxa-3-tooltip.-small-center.-right-inline .tooltip-container, .vxa-3-tooltip.-small-left.-left-inline .tooltip-container, .vxa-3-tooltip.-small-left.-right-inline .tooltip-container, .vxa-3-tooltip.-small-right.-left-inline .tooltip-container, .vxa-3-tooltip.-small-right.-right-inline .tooltip-container {
        display: none
    }

    .vxa-3-tooltip.-sm-full .tooltip-container {
        position: fixed;
        top: 0 !important;
        bottom: auto;
        left: 0 !important;
        width: calc(100% - 20px) !important;
        margin: 10px 10px 0 !important
    }

    .vxa-3-tooltip.-sm-full.-left-inline .tooltip-container, .vxa-3-tooltip.-sm-full.-right-inline .tooltip-container {
        transform: translateY(0)
    }

    .vxa-3-tooltip.-small-right .tooltip-container {
        right: auto;
        left: 50%;
        margin-right: auto;
        margin-left: -25px
    }

    .vxa-3-tooltip.-small-right .tooltip-container .tooltip-marker {
        right: auto;
        left: 5px;
        margin: 0
    }

    .vxa-3-tooltip.-small-left .tooltip-container {
        right: 50%;
        left: auto;
        margin-right: -25px;
        margin-left: auto
    }

    .vxa-3-tooltip.-small-left .tooltip-container .tooltip-marker {
        right: 5px;
        left: auto;
        margin: 0
    }

    .vxa-3-tooltip.-small-center .tooltip-container {
        right: auto;
        left: 50%;
        margin-right: auto;
        margin-left: -145px
    }

    .vxa-3-tooltip.-small-center .tooltip-container .tooltip-marker {
        right: auto;
        left: 50%;
        margin-right: auto;
        margin-left: -20px
    }

    .vxa-3-tooltip.-small-center.-sm .tooltip-container {
        margin-left: -83px
    }

    .vxa-3-tooltip.-not-hover-small, .vxa-3-tooltip.-not-hover-small:hover {
        cursor: auto
    }

    .vxa-3-tooltip.-not-hover-small.-show-tooltip .tooltip-container, .vxa-3-tooltip.-not-hover-small.-show-tooltip .tooltip-content, .vxa-3-tooltip.-not-hover-small:hover.-show-tooltip .tooltip-container, .vxa-3-tooltip.-not-hover-small:hover.-show-tooltip .tooltip-content {
        display: block
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .vxa-3-tooltip.-not-hover-large, .vxa-3-tooltip.-not-hover-large:hover {
        cursor: auto
    }

    .vxa-3-tooltip.-not-hover-large .tooltip-container, .vxa-3-tooltip.-not-hover-large .tooltip-content, .vxa-3-tooltip.-not-hover-large:hover .tooltip-container, .vxa-3-tooltip.-not-hover-large:hover .tooltip-content {
        display: none
    }

    .vxa-3-tooltip.-not-hover-large.-show-tooltip .tooltip-container, .vxa-3-tooltip.-not-hover-large.-show-tooltip .tooltip-content, .vxa-3-tooltip.-not-hover-large:hover.-show-tooltip .tooltip-container, .vxa-3-tooltip.-not-hover-large:hover.-show-tooltip .tooltip-content {
        display: block
    }
}

@media (min-width: 1024px) {
    .vxa-3-tooltip.-not-hover-large, .vxa-3-tooltip.-not-hover-large:hover {
        cursor: auto
    }

    .vxa-3-tooltip.-not-hover-large .tooltip-container, .vxa-3-tooltip.-not-hover-large .tooltip-content, .vxa-3-tooltip.-not-hover-large:hover .tooltip-container, .vxa-3-tooltip.-not-hover-large:hover .tooltip-content {
        display: none
    }

    .vxa-3-tooltip.-not-hover-large.-show-tooltip .tooltip-container, .vxa-3-tooltip.-not-hover-large.-show-tooltip .tooltip-content, .vxa-3-tooltip.-not-hover-large:hover.-show-tooltip .tooltip-container, .vxa-3-tooltip.-not-hover-large:hover.-show-tooltip .tooltip-content {
        display: block
    }
}

.vxa-3-carousel {
    padding-left: 10px
}

.vxa-3-carousel .carousel-title-container {
    display: block;
    margin-bottom: 19px;
    padding: 0 8px
}

.vxa-3-carousel .carousel-title-container.-title-align-center {
    text-align: center
}

.vxa-3-carousel .carousel-title-container.-title-align-right {
    text-align: right
}

.vxa-3-carousel .carousel-cards-container {
    display: flex;
    position: relative;
    align-items: stretch
}

.vxa-3-carousel .cards-container-list {
    position: relative;
    flex: 1;
    overflow: hidden
}

.vxa-3-carousel .list-container {
    display: flex
}

.vxa-3-carousel .card-item {
    box-sizing: border-box;
    padding: 8px 8px 24px
}

.vxa-3-carousel .card-item.-item-nav-slider {
    align-self: center;
    width: 100%
}

.vxa-3-carousel .carousel-navigation-container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 12px 0 10px
}

.vxa-3-carousel .vxa-3-nav-dots {
    align-self: center;
    margin: 0 10px
}

.vxa-3-carousel .vxa-3-nav-slider.-left {
    left: 10px
}

.vxa-3-carousel .vxa-3-nav-slider.-right {
    right: 10px
}

.vxa-3-carousel .card-item-image {
    width: 100%;
    min-width: 134px;
    min-height: 132px
}

.-vxa-3-bc-black {
    background-color: var(--color-black) !important
}

.-vxa-3-tc-black {
    color: var(--color-black) !important
}

.-vxa-3-bc-white {
    background-color: var(--color-white) !important
}

.-vxa-3-tc-white {
    color: var(--color-white) !important
}

.-vxa-3-bc-gray-0 {
    background-color: var(--color-gray-1) !important
}

.-vxa-3-tc-gray-0 {
    color: var(--color-gray-1) !important
}

.-vxa-3-bc-gray-1 {
    background-color: var(--color-gray-2) !important
}

.-vxa-3-tc-gray-1 {
    color: var(--color-gray-2) !important
}

.-vxa-3-bc-gray-2 {
    background-color: var(--color-gray-3) !important
}

.-vxa-3-tc-gray-2 {
    color: var(--color-gray-3) !important
}

.-vxa-3-bc-gray-3 {
    background-color: var(--color-gray-4) !important
}

.-vxa-3-tc-gray-3 {
    color: var(--color-gray-4) !important
}

.-vxa-3-bc-gray-4 {
    background-color: var(--color-gray-5) !important
}

.-vxa-3-tc-gray-4 {
    color: var(--color-gray-5) !important
}

.-vxa-3-bc-green-1 {
    background-color: var(--color-green-1) !important
}

.-vxa-3-tc-green-1 {
    color: var(--color-green-1) !important
}

.-vxa-3-bc-green-2 {
    background-color: var(--color-green-2) !important
}

.-vxa-3-tc-green-2 {
    color: var(--color-green-2) !important
}

.-vxa-3-bc-green-3 {
    background-color: var(--color-green-3) !important
}

.-vxa-3-tc-green-3 {
    color: var(--color-green-3) !important
}

.-vxa-3-bc-green-4 {
    background-color: var(--color-green-4) !important
}

.-vxa-3-tc-green-4 {
    color: var(--color-green-4) !important
}

.-vxa-3-bc-yellow-1 {
    background-color: var(--color-yellow-1) !important
}

.-vxa-3-tc-yellow-1 {
    color: var(--color-yellow-1) !important
}

.-vxa-3-bc-yellow-2 {
    background-color: var(--color-yellow-2) !important
}

.-vxa-3-tc-yellow-2 {
    color: var(--color-yellow-2) !important
}

.-vxa-3-bc-yellow-3 {
    background-color: var(--color-yellow-3) !important
}

.-vxa-3-tc-yellow-3 {
    color: var(--color-yellow-3) !important
}

.-vxa-3-bc-yellow-4 {
    background-color: var(--color-yellow-4) !important
}

.-vxa-3-tc-yellow-4 {
    color: var(--color-yellow-4) !important
}

.-vxa-3-bc-orange-1 {
    background-color: var(--color-orange-1) !important
}

.-vxa-3-tc-orange-1 {
    color: var(--color-orange-1) !important
}

.-vxa-3-bc-orange-2 {
    background-color: var(--color-orange-2) !important
}

.-vxa-3-tc-orange-2 {
    color: var(--color-orange-2) !important
}

.-vxa-3-bc-orange-3 {
    background-color: var(--color-orange-3) !important
}

.-vxa-3-tc-orange-3 {
    color: var(--color-orange-3) !important
}

.-vxa-3-bc-orange-4 {
    background-color: var(--color-orange-4) !important
}

.-vxa-3-tc-orange-4 {
    color: var(--color-orange-4) !important
}

.-vxa-3-bc-red-1 {
    background-color: var(--color-red-1) !important
}

.-vxa-3-tc-red-1 {
    color: var(--color-red-1) !important
}

.-vxa-3-bc-red-2 {
    background-color: var(--color-red-2) !important
}

.-vxa-3-tc-red-2 {
    color: var(--color-red-2) !important
}

.-vxa-3-bc-red-3 {
    background-color: var(--color-red-3) !important
}

.-vxa-3-tc-red-3 {
    color: var(--color-red-3) !important
}

.-vxa-3-bc-red-4 {
    background-color: var(--color-red-4) !important
}

.-vxa-3-tc-red-4 {
    color: var(--color-red-4) !important
}

.-vxa-3-bc-blue-1 {
    background-color: var(--brand-primary-1) !important
}

.-vxa-3-tc-blue-1 {
    color: var(--brand-primary-1) !important
}

.-vxa-3-bc-blue-2 {
    background-color: var(--brand-primary-2) !important
}

.-vxa-3-tc-blue-2 {
    color: var(--brand-primary-2) !important
}

.-vxa-3-bc-blue-3 {
    background-color: var(--brand-primary-3) !important
}

.-vxa-3-tc-blue-3 {
    color: var(--brand-primary-3) !important
}

.-vxa-3-bc-blue-4 {
    background-color: var(--brand-primary-4) !important
}

.-vxa-3-tc-blue-4 {
    color: var(--brand-primary-4) !important
}

.-vxa-3-bc-light-blue-1 {
    background-color: var(--brand-primary-1) !important
}

.-vxa-3-tc-light-blue-1 {
    color: var(--brand-primary-1) !important
}

.-vxa-3-bc-light-blue-2 {
    background-color: var(--brand-primary-2) !important
}

.-vxa-3-tc-light-blue-2 {
    color: var(--brand-primary-2) !important
}

.-vxa-3-bc-light-blue-3 {
    background-color: var(--brand-primary-3) !important
}

.-vxa-3-tc-light-blue-3 {
    color: var(--brand-primary-3) !important
}

.-vxa-3-bc-light-blue-4 {
    background-color: var(--brand-primary-4) !important
}

.-vxa-3-tc-light-blue-4 {
    color: var(--brand-primary-4) !important
}

.-vxa-3-bc-purple-1 {
    background-color: var(--brand-primary-1) !important
}

.-vxa-3-tc-purple-1 {
    color: var(--brand-primary-1) !important
}

.-vxa-3-bc-purple-2 {
    background-color: var(--brand-primary-2) !important
}

.-vxa-3-tc-purple-2 {
    color: var(--brand-primary-2) !important
}

.-vxa-3-bc-purple-3 {
    background-color: var(--brand-primary-3) !important
}

.-vxa-3-tc-purple-3 {
    color: var(--brand-primary-3) !important
}

.-vxa-3-bc-purple-4 {
    background-color: var(--brand-primary-4) !important
}

.-vxa-3-tc-purple-4 {
    color: var(--brand-primary-4) !important
}

.-vxa-3-bc-brand-primary-1 {
    background-color: var(--brand-primary-1) !important
}

.-vxa-3-tc-brand-primary-1 {
    color: var(--brand-primary-1) !important
}

.-vxa-3-bc-brand-primary-2 {
    background-color: var(--brand-primary-2) !important
}

.-vxa-3-tc-brand-primary-2 {
    color: var(--brand-primary-2) !important
}

.-vxa-3-bc-brand-primary-3 {
    background-color: var(--brand-primary-3) !important
}

.-vxa-3-tc-brand-primary-3 {
    color: var(--brand-primary-3) !important
}

.-vxa-3-bc-brand-primary-4 {
    background-color: var(--brand-primary-4) !important
}

.-vxa-3-tc-brand-primary-4 {
    color: var(--brand-primary-4) !important
}

.-vxa-3-bc-brand-secondary-1 {
    background-color: var(--brand-secondary-1) !important
}

.-vxa-3-tc-brand-secondary-1 {
    color: var(--brand-secondary-1) !important
}

.-vxa-3-bc-brand-secondary-2 {
    background-color: var(--brand-secondary-2) !important
}

.-vxa-3-tc-brand-secondary-2 {
    color: var(--brand-secondary-2) !important
}

.-vxa-3-bc-brand-secondary-3 {
    background-color: var(--brand-secondary-3) !important
}

.-vxa-3-tc-brand-secondary-3 {
    color: var(--brand-secondary-3) !important
}

.-vxa-3-bc-brand-secondary-4 {
    background-color: var(--brand-secondary-4) !important
}

.-vxa-3-tc-brand-secondary-4 {
    color: var(--brand-secondary-4) !important
}

.-vxa-3-bc-aqua-1 {
    background-color: var(--brand-tertiary-1) !important
}

.-vxa-3-tc-aqua-1 {
    color: var(--brand-tertiary-1) !important
}

.-vxa-3-bc-aqua-2 {
    background-color: var(--brand-tertiary-2) !important
}

.-vxa-3-tc-aqua-2 {
    color: var(--brand-tertiary-2) !important
}

.-vxa-3-bc-aqua-3 {
    background-color: var(--brand-tertiary-3) !important
}

.-vxa-3-tc-aqua-3 {
    color: var(--brand-tertiary-3) !important
}

.-vxa-3-bc-aqua-4 {
    background-color: var(--brand-tertiary-4) !important
}

.-vxa-3-tc-aqua-4 {
    color: var(--brand-tertiary-4) !important
}

.-vxa-3-bc-brand-tertiary-1 {
    background-color: var(--brand-tertiary-1) !important
}

.-vxa-3-tc-brand-tertiary-1 {
    color: var(--brand-tertiary-1) !important
}

.-vxa-3-bc-brand-tertiary-2 {
    background-color: var(--brand-tertiary-2) !important
}

.-vxa-3-tc-brand-tertiary-2 {
    color: var(--brand-tertiary-2) !important
}

.-vxa-3-bc-brand-tertiary-3 {
    background-color: var(--brand-tertiary-3) !important
}

.-vxa-3-tc-brand-tertiary-3 {
    color: var(--brand-tertiary-3) !important
}

.-vxa-3-bc-brand-tertiary-4 {
    background-color: var(--brand-tertiary-4) !important
}

.-vxa-3-tc-brand-tertiary-4 {
    color: var(--brand-tertiary-4) !important
}

.-vxa-3-bc-dark {
    background-color: rgba(0, 0, 0, 0.9) !important
}

.-vxa-3-bc-light {
    background-color: rgba(255, 255, 255, 0.9) !important
}

.-vxa-3-bc-bubblegum-1 {
    background-color: var(--vxa-loyalty-primary-300) !important
}

.-vxa-3-bc-bubblegum-2 {
    background-color: var(--vxa-loyalty-primary-500) !important
}

.-vxa-3-bc-bubblegum-3 {
    background-color: var(--vxa-loyalty-primary-100) !important
}

.-vxa-3-bc-lemon-1 {
    background-color: var(--vxa-loyalty-secondary-300) !important
}

.-vxa-3-bc-lemon-2 {
    background-color: var(--vxa-loyalty-secondary-500) !important
}

.-vxa-3-bc-lemon-3 {
    background-color: var(--vxa-loyalty-secondary-200) !important
}

.-vxa-3-bc-lemon-4 {
    background-color: var(--vxa-loyalty-secondary-100) !important
}

.-vxa-3-bc-blueberry-1 {
    background-color: var(--vxa-loyalty-tertiary-900) !important
}

.-vxa-3-bc-blueberry-2 {
    background-color: var(--vxa-loyalty-tertiary-800) !important
}

.-vxa-3-bc-blueberry-3 {
    background-color: var(--vxa-loyalty-tertiary-600) !important
}

.-vxa-3-bc-blueberry-4 {
    background-color: var(--vxa-loyalty-tertiary-400) !important
}

.-vxa-3-tc-bubblegum-1 {
    color: var(--vxa-loyalty-primary-500) !important
}

.-vxa-3-tc-bubblegum-2 {
    color: var(--vxa-loyalty-primary-700) !important
}

.-vxa-3-tc-lemon-1 {
    color: var(--vxa-loyalty-secondary-300) !important
}

.-vxa-3-index-0 {
    z-index: 0 !important
}

.-vxa-3-index-1 {
    z-index: 1 !important
}

.-vxa-3-index-2 {
    z-index: 2 !important
}

.-vxa-3-index-3 {
    z-index: 3 !important
}

.-vxa-3-index-4 {
    z-index: 4 !important
}

.-vxa-3-index-5 {
    z-index: 5 !important
}

.-vxa-3-index-6 {
    z-index: 6 !important
}

.-vxa-3-index-7 {
    z-index: 7 !important
}

.-vxa-3-index-8 {
    z-index: 8 !important
}

.-vxa-3-index-9 {
    z-index: 9 !important
}

.-vxa-3-index-10 {
    z-index: 10 !important
}

.-vxa-3-mt-xsm {
    margin-top: 4px !important
}

.-vxa-3-mb-xsm {
    margin-bottom: 4px !important
}

.-vxa-3-ml-xsm {
    margin-left: 4px !important
}

.-vxa-3-mr-xsm {
    margin-right: 4px !important
}

.-vxa-3-m-xsm {
    margin: 4px !important
}

.-vxa-3-mt-sm {
    margin-top: 8px !important
}

.-vxa-3-mb-sm {
    margin-bottom: 8px !important
}

.-vxa-3-ml-sm {
    margin-left: 8px !important
}

.-vxa-3-mr-sm {
    margin-right: 8px !important
}

.-vxa-3-m-sm {
    margin: 8px !important
}

.-vxa-3-mt-md {
    margin-top: 12px !important
}

.-vxa-3-mb-md {
    margin-bottom: 12px !important
}

.-vxa-3-ml-md {
    margin-left: 12px !important
}

.-vxa-3-mr-md {
    margin-right: 12px !important
}

.-vxa-3-m-md {
    margin: 12px !important
}

.-vxa-3-mt-lg {
    margin-top: 16px !important
}

.-vxa-3-mb-lg {
    margin-bottom: 16px !important
}

.-vxa-3-ml-lg {
    margin-left: 16px !important
}

.-vxa-3-mr-lg {
    margin-right: 16px !important
}

.-vxa-3-m-lg {
    margin: 16px !important
}

.-vxa-3-mt-xlg {
    margin-top: 20px !important
}

.-vxa-3-mb-xlg {
    margin-bottom: 20px !important
}

.-vxa-3-ml-xlg {
    margin-left: 20px !important
}

.-vxa-3-mr-xlg {
    margin-right: 20px !important
}

.-vxa-3-m-xlg {
    margin: 20px !important
}

.-vxa-3-mt-xxlg {
    margin-top: 24px !important
}

.-vxa-3-mb-xxlg {
    margin-bottom: 24px !important
}

.-vxa-3-ml-xxlg {
    margin-left: 24px !important
}

.-vxa-3-mr-xxlg {
    margin-right: 24px !important
}

.-vxa-3-m-xxlg {
    margin: 24px !important
}

.-vxa-3-pt-xsm {
    padding-top: 4px !important
}

.-vxa-3-pb-xsm {
    padding-bottom: 4px !important
}

.-vxa-3-pl-xsm {
    padding-left: 4px !important
}

.-vxa-3-pr-xsm {
    padding-right: 4px !important
}

.-vxa-3-p-xsm {
    padding: 4px !important
}

.-vxa-3-pt-sm {
    padding-top: 8px !important
}

.-vxa-3-pb-sm {
    padding-bottom: 8px !important
}

.-vxa-3-pl-sm {
    padding-left: 8px !important
}

.-vxa-3-pr-sm {
    padding-right: 8px !important
}

.-vxa-3-p-sm {
    padding: 8px !important
}

.-vxa-3-pt-md {
    padding-top: 12px !important
}

.-vxa-3-pb-md {
    padding-bottom: 12px !important
}

.-vxa-3-pl-md {
    padding-left: 12px !important
}

.-vxa-3-pr-md {
    padding-right: 12px !important
}

.-vxa-3-p-md {
    padding: 12px !important
}

.-vxa-3-pt-lg {
    padding-top: 16px !important
}

.-vxa-3-pb-lg {
    padding-bottom: 16px !important
}

.-vxa-3-pl-lg {
    padding-left: 16px !important
}

.-vxa-3-pr-lg {
    padding-right: 16px !important
}

.-vxa-3-p-lg {
    padding: 16px !important
}

.-vxa-3-pt-xlg {
    padding-top: 20px !important
}

.-vxa-3-pb-xlg {
    padding-bottom: 20px !important
}

.-vxa-3-pl-xlg {
    padding-left: 20px !important
}

.-vxa-3-pr-xlg {
    padding-right: 20px !important
}

.-vxa-3-p-xlg {
    padding: 20px !important
}

.-vxa-3-pt-xxlg {
    padding-top: 24px !important
}

.-vxa-3-pb-xxlg {
    padding-bottom: 24px !important
}

.-vxa-3-pl-xxlg {
    padding-left: 24px !important
}

.-vxa-3-pr-xxlg {
    padding-right: 24px !important
}

.-vxa-3-p-xxlg {
    padding: 24px !important
}

.-vxa-3-fwidth {
    width: 100% !important;
    max-width: none !important
}

.-vxa-3-fl {
    float: left !important
}

.-vxa-3-fr {
    float: right !important
}

.-vxa-3-valign {
    display: flex;
    align-items: center
}

.-vxa-3-halign {
    display: flex;
    justify-content: center
}

.-vxa-3-center {
    display: flex;
    align-items: center;
    justify-content: center
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .-vxa-3-fwidth-large {
        width: 100% !important;
        max-width: none !important
    }

    .-vxa-3-fl-large {
        float: left !important
    }

    .-vxa-3-fr-large {
        float: right !important
    }
}

@media (min-width: 1024px) {
    .-vxa-3-fwidth-large {
        width: 100% !important;
        max-width: none !important
    }

    .-vxa-3-fl-large {
        float: left !important
    }

    .-vxa-3-fr-large {
        float: right !important
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .-vxa-3-fwidth-medium {
        width: 100% !important;
        max-width: none !important
    }

    .-vxa-3-fl-medium {
        float: left !important
    }

    .-vxa-3-fr-medium {
        float: right !important
    }
}

@media (min-width: 1px) and (max-width: 767px) {
    .-vxa-3-fwidth-small {
        width: 100% !important;
        max-width: none !important
    }

    .-vxa-3-fl-small {
        float: left !important
    }

    .-vxa-3-fr-small {
        float: right !important
    }
}

.-vxa-3-tc {
    text-align: center !important
}

.-vxa-3-tl {
    text-align: left !important
}

.-vxa-3-tr {
    text-align: right !important
}

.-vxa-3-uppercase {
    text-transform: uppercase !important
}

.-vxa-3-capitalize {
    text-transform: capitalize !important
}

.-vxa-3-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.-vxa-3-italic {
    font-style: italic !important
}

.-vxa-3-bold {
    font-weight: 500 !important
}

.-vxa-3-underline {
    text-decoration: underline !important
}

.-vxa-3-icon-xsm {
    font-size: 16px !important
}

.-vxa-3-icon-sm {
    font-size: 20px !important
}

.-vxa-3-icon-md {
    font-size: 24px !important
}

.-vxa-3-icon-lg {
    font-size: 32px !important
}

.-vxa-3-icon-xlg {
    font-size: 48px !important
}

.-vxa-3-icon-xxlg {
    font-size: 64px !important
}

@font-face {
    font-family: FontAwesome;
    src: url(https://static.buson.com.br/public-2785/_v2/static/fonts/font-awesome/fontawesome-webfont.eot?v=4.6.2);
    src: url(https://static.buson.com.br/public-2785/_v2/static/fonts/font-awesome/fontawesome-webfont.eot#iefix&v=4.6.2) format("embedded-opentype"), url(https://static.buson.com.br/public-2785/_v2/static/fonts/font-awesome/fontawesome-webfont.woff2?v=4.6.2) format("woff2"), url(https://static.buson.com.br/public-2785/_v2/static/fonts/font-awesome/fontawesome-webfont.woff?v=4.6.2) format("woff"), url(https://static.buson.com.br/public-2785/_v2/static/fonts/font-awesome/fontawesome-webfont.ttf?v=4.6.2) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.vxa-3-icon-arrow-bold-left, .vxa-3-icon-arrow-bold-right, .vxa-3-icon-arrow-down, .vxa-3-icon-arrow-left, .vxa-3-icon-arrow-right, .vxa-3-icon-close, .vxa-3-icon-info-circle-outline, .vxa-3-icon-picture, .vxa-3-icon-star {
    font-family: vxa-font;
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    speak: none;
    text-decoration: inherit;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.vxa-3-icon-arrow-bold-left:before {
    content: "\f005"
}

.vxa-3-icon-arrow-bold-right:before {
    content: "\f006"
}

.vxa-3-icon-arrow-down:before {
    content: "\f008"
}

.vxa-3-icon-arrow-left:before {
    content: "\f009"
}

.vxa-3-icon-arrow-right:before {
    content: "\f010"
}

.vxa-3-icon-close:before {
    content: "\f039"
}

.vxa-3-icon-info-circle-outline:before {
    content: "\f074"
}

.vxa-3-icon-picture:before {
    content: "\f101"
}

.vxa-3-icon-star:before {
    content: "\f127"
}

body {
    background-color: #eeeeee
}

body.lock-vertical-scroll {
    overflow-y: hidden
}

.inc-ui-title-normal-weight {
    font-weight: normal
}

.in-process {
    overflow: hidden;
    max-width: 1280px;
    width: 100%;
    margin: 30px auto;
    padding: 0 40px;
    height: 150px;
    font-size: 56px;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: white;
    color: black;
    font-weight: bold;
    background-image: url(http://annizha.com/construccion.gif);
    background-size: contain;
    background-repeat: no-repeat
}

a {
    text-decoration: none;
    color: inherit
}

.inc-ui-max-width {
    max-width: 958px;
    margin: 0 auto
}

.inc-ui-strong-text {
    font-weight: 500
}

.inc-ui-module-title {
    display: block
}

.inc-ui-module-title {
    margin-bottom: 20px
}

.inc-ui-max-width-10-col {
    margin-left: auto;
    margin-right: auto;
    max-width: 1062.5px
}

#LOGIN_INCENTIVE {
    display: none
}

.inc-box-mb {
    margin-bottom: 80px
}

.inc-box-mb.inc-ui-carousel-module, .inc-box-mb.inc-ui-secondary-offers-module {
    margin-bottom: 0
}

.inc-box-mb.inc-ui-reason-why-container {
    margin-bottom: 40px
}

#BANNERS_MKT .inc-box-mb, #PAYMENTS .inc-box-mb, #LOYALTY .inc-box-mb, .inc-box-mb.inc-ui-searchbox-container {
    margin-bottom: 56px
}

#LOYALTY {
    display: none
}

#LOYALTY.inc-ui-loyalty-ready {
    display: block
}

.inc-ui-searchbox-container #searchbox-sbox-all-boxes {
    margin: 0 auto
}

.inc-ui-ve-message {
    margin: 65px auto 80px
}

.searchbox-sbox-all-boxes {
    position: relative
}

.inc-ui-searchbox-container {
    padding: 12px 10px;
    background-color: #ddd5f5
}

.inc-ui-searchbox-container.inc-ui-searchbox-container-only-image {
    background-size: 100%;
    background-position: bottom
}

.inc-ui-searchbox-container.hot-sale-mx-2020-mobile .inc-ui-hot-sale-landing-access-mobile-container {
    margin: 15px -10px 25px;
    display: flex;
    align-items: center
}

.inc-ui-searchbox-container.hot-sale-mx-2020-mobile .inc-ui-hot-sale-logo {
    display: flex;
    flex: 0 0 118px;
    height: 68px;
    background-size: cover;
}

.inc-ui-searchbox-container.hot-sale-mx-2020-mobile .inc-ui-hot-sale-text-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center
}

.inc-ui-searchbox-container.hot-sale-mx-2020-mobile .inc-ui-hot-sale-text-title {
    font-weight: 500;
    color: white;
    font-size: 16px;
    margin-bottom: 4px
}

.inc-ui-searchbox-container.hot-sale-mx-2020-mobile .inc-ui-hot-sale-text-subtitle {
    color: white;
    font-size: 16px
}

.inc-ui-searchbox-container.industry-event-plain-black-bg {
    background: #000
}

.inc-ui-searchbox-container.industry-event-plain-blue-bg {
    background: #0094d5
}

.inc-ui-searchbox-container.industry-event-plain-orange-bg {
    background: #ee452f
}

.inc-ui-searchbox-container.industry-event-gradient-bg {
    background: linear-gradient(to right, #009ad5 0%, #4b5aa8 50%, #653795)
}

.inc-ui-searchbox-container .inc-ui-searchbox-industry-event-data {
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.07px
}

.inc-ui-searchbox-container .inc-ui-searchbox-industry-event-main-text {
    font-weight: 500
}

.inc-ui-searchbox-container .inc-ui-searchbox-industry-event-secondary-text {
    font-weight: 500
}

.inc-ui-searchbox-container .searchbox-sbox-all-boxes {
    padding: 0;
    margin: 0
}

body > div.inc-ui-searchbox-container--industry .inc-ui-searchbox-industry-event-data {
    margin-top: 24px;
    margin-bottom: 12px;
    display: flex;
    justify-content: center
}

body > div.inc-ui-searchbox-container--industry .inc-ui-searchbox-industry-event-data--mobile {
    justify-content: center;
    padding: 24px 6px 32px;
    margin-top: 0;
    margin-bottom: 0
}

body > div.inc-ui-searchbox-container--industry .inc-ui-searchbox-industry-event-text {
    display: grid
}

body > div.inc-ui-searchbox-container--industry .inc-ui-searchbox-industry-event-text--mobile {
    width: 100%
}

body > div.inc-ui-searchbox-container--industry .logo__container {
    grid-area: logo
}

body > div.inc-ui-searchbox-container--industry .logo__container--with-pipe {
    background: white;
    padding: 8px 32px 8px 32px;
    position: relative;
    left: -16px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px
}

body > div.inc-ui-searchbox-container--industry .logo__img {
    height: 100%;
    max-height: 40px
}

body > div.inc-ui-searchbox-container--industry .inc-ui-searchbox-industry-event-main-text {
    align-self: end;
    grid-area: main-text
}

body > div.inc-ui-searchbox-container--industry .inc-ui-searchbox-industry-event-secondary-text {
    grid-area: sec-text
}

body > div.inc-ui-searchbox-container.hot_travel_mx_2020 {
    background: linear-gradient(150deg, #9062F1 0%, #7047C6 100%)
}

body > div.inc-ui-searchbox-container.hot_travel_mx_2020 .inc-ui-searchbox-industry-event-text {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 5px;
    grid-template-areas: "logo main-text" "logo sec-text"
}

body > div.inc-ui-searchbox-container.hot_travel_mx_2020 .inc-ui-searchbox-industry-event-main-text {
    color: #FFF59D;
    align-self: end
}

body > div.inc-ui-searchbox-container.SEMANA_CONSUMIDOR {
    background: linear-gradient(-30deg, #9062F1 0%, #7047C6 100%)
}

body > div.inc-ui-searchbox-container.SEMANA_CONSUMIDOR .inc-ui-searchbox-industry-event-data--mobile {
    padding: 24px 6px 32px
}

body > div.inc-ui-searchbox-container.SEMANA_CONSUMIDOR .logo__container {
    display: flex;
    align-items: flex-end
}

body > div.inc-ui-searchbox-container.SEMANA_CONSUMIDOR .inc-ui-searchbox-industry-event-main-text {
    letter-spacing: 2px
}

body > div.inc-ui-searchbox-container.SEMANA_CONSUMIDOR .inc-ui-searchbox-industry-event-secondary-text {
    font-size: 14px
}

body > div.inc-ui-searchbox-container.SEMANA_CONSUMIDOR .inc-ui-searchbox-industry-event-text {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 5px;
    grid-template-areas: "logo main-text" "logo sec-text"
}

body > div.inc-ui-searchbox-container.SEMANA_CONSUMIDOR .inc-ui-searchbox-industry-event-text--mobile {
    width: 100%;
    grid-template-columns: 48px 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 5px
}

body > div.inc-ui-searchbox-container.SEMANA_CONSUMIDOR .inc-ui-searchbox-industry-event-text--mobile .logo__container {
    align-self: center
}

.inc-ui-searchbox-container {
    padding: 16px 0;
    border-top: solid 1px #cccccc
}

.inc-ui-big-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    box-sizing: border-box
}

.inc-ui-big-banner-container .inc-ui-big-banner-background-img {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1
}

.inc-ui-big-banner-container .inc-ui-big-banner-text-container {
    color: #FFF;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    line-height: 1.33
}

.inc-ui-big-banner-container .inc-ui-big-banner-button:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.inc-ui-big-banner-container {
    height: 280px;
    padding: 30px 52px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
}

.inc-ui-big-banner-container .inc-ui-big-banner-text-container {
    max-width: 84%;
    margin-bottom: 20px
}

.inc-ui-big-banner-container .inc-ui-big-banner-title {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.13px;
    line-height: 36px;
    margin-bottom: 12px
}

.inc-ui-big-banner-container .inc-ui-big-banner-subtitle {
    font-size: 20px;
    letter-spacing: -0.09px;
    line-height: 28px
}

@media (min-width: 1366px) {
    .inc-ui-big-banner-container .inc-ui-big-banner-title {
        font-size: 28px
    }
}

.inc-ui-carousel-offers-skeleton-container .--cards-container {
    display: flex
}

.inc-ui-carousel-offers-skeleton-container .--card {
    border-radius: 8px;
    background-color: white;
    border: 1px solid #CCC;
    overflow: hidden
}

.inc-ui-carousel-offers-skeleton-container .--card-title-placeholder, .inc-ui-carousel-offers-skeleton-container .--card-driver-placeholder, .inc-ui-carousel-offers-skeleton-container .--card-price-placeholder {
    position: relative
}

.inc-ui-carousel-offers-skeleton-container .--card-title-placeholder:before, .inc-ui-carousel-offers-skeleton-container .--card-title-placeholder:after, .inc-ui-carousel-offers-skeleton-container .--card-driver-placeholder:before, .inc-ui-carousel-offers-skeleton-container .--card-driver-placeholder:after, .inc-ui-carousel-offers-skeleton-container .--card-price-placeholder:before, .inc-ui-carousel-offers-skeleton-container .--card-price-placeholder:after {
    content: '';
    position: absolute;
    background-color: white;
    height: 100%
}

.inc-ui-carousel-offers-skeleton-container .--card-title-placeholder:before, .inc-ui-carousel-offers-skeleton-container .--card-driver-placeholder:before, .inc-ui-carousel-offers-skeleton-container .--card-price-placeholder:before {
    left: 0
}

.inc-ui-carousel-offers-skeleton-container .--card-title-placeholder:after, .inc-ui-carousel-offers-skeleton-container .--card-driver-placeholder:after, .inc-ui-carousel-offers-skeleton-container .--card-price-placeholder:after {
    right: 0
}

.inc-ui-carousel-offers-skeleton-container .--card-price-container {
    border-top: 1px solid #CCC
}

.inc-ui-carousel-offers-skeleton-container .--animate {
    background-image: linear-gradient(to left, #f5f5f5, #eee), linear-gradient(to right, #f5f5f5, #eee), linear-gradient(to right, #eee, #eee);
    background-position: 0 0, 15% 0, 0 0;
    background-size: 15% 100%, 15% 100%, 100% 100%;
    background-repeat: no-repeat
}

@keyframes skeleton-animation {
    0% {
        background-position: -15% 0, 0% 0, 0 0
    }
    100% {
        background-position: 150% 0, 165% 0, 0 0
    }
}

.inc-ui-carousel-offers-skeleton-container {
    max-width: 1186px;
    margin: 0 auto;
    padding-top: 60px
}

.inc-ui-carousel-offers-skeleton-container:nth-child(1) {
    padding-top: 0
}

.inc-ui-carousel-offers-skeleton-container .--cards-container {
    margin: 0 25px;
    overflow: hidden
}

.inc-ui-carousel-offers-skeleton-container .--card {
    flex: 0 0 267px;
    height: 452px;
    margin-left: 20px
}

.inc-ui-carousel-offers-skeleton-container .--card:nth-child(1) {
    margin-left: 0
}

.inc-ui-carousel-offers-skeleton-container .--card-img-placeholder {
    height: 196px;
    width: 100%
}

.inc-ui-carousel-offers-skeleton-container .--card-title-placeholder {
    height: 40px;
    margin: 20px 0
}

.inc-ui-carousel-offers-skeleton-container .--card-title-placeholder:before, .inc-ui-carousel-offers-skeleton-container .--card-title-placeholder:after {
    width: 20px
}

.inc-ui-carousel-offers-skeleton-container .--card-driver-placeholder {
    height: 20px;
    margin: 20px 0
}

.inc-ui-carousel-offers-skeleton-container .--card-driver-placeholder:before, .inc-ui-carousel-offers-skeleton-container .--card-driver-placeholder:after {
    width: 20px
}

.inc-ui-carousel-offers-skeleton-container .--card-price-container {
    margin-top: 81px
}

.inc-ui-carousel-offers-skeleton-container .--card-price-placeholder {
    height: 42px;
    margin: 16px 0
}

.inc-ui-carousel-offers-skeleton-container .--card-price-placeholder:before {
    width: 20px
}

.inc-ui-carousel-offers-skeleton-container .--card-price-placeholder:after {
    width: 133px
}

.inc-ui-carousel-offers-skeleton-container .--animate {
    animation: skeleton-animation 1.2s infinite
}

@media (min-width: 1366px) {
    .inc-ui-carousel-offers-skeleton-container {
        max-width: 1307px
    }

    .inc-ui-carousel-offers-skeleton-container .--card {
        flex: 0 0 295px;
        margin-left: 23px
    }
}

.inc-ui-banners-mkt-container .inc-ui-banners-mkt-item-img {
    border-radius: 8px
}

.inc-ui-banners-mkt-container .inc-ui-banners-mkt {
    height: 270px;
    overflow: hidden;
    display: flex;
    justify-content: space-between
}

.inc-ui-banners-mkt-container .inc-ui-banners-mkt .inc-ui-banners-mkt-item {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    width: 297px;
    height: 270px
}

.inc-ui-banners-mkt-container .inc-ui-banners-mkt .inc-ui-banners-mkt-item:first-child {
    width: 617px
}

.inc-ui-banners-mkt-container .inc-ui-banners-mkt .inc-ui-banners-mkt-item-img {
    width: 100%
}

@media (max-width: 1329px) {
    .inc-ui-banners-mkt-container .inc-ui-banners-mkt {
        height: 20.67669vw
    }

    .inc-ui-banners-mkt-container .inc-ui-banners-mkt .inc-ui-banners-mkt-item {
        min-width: 22.70677vw;
        min-height: 20.67669vw
    }

    .inc-ui-banners-mkt-container .inc-ui-banners-mkt .inc-ui-banners-mkt-item .inc-ui-banners-mkt-item-img {
        width: 22.70677vw;
        height: 20.67669vw
    }

    .inc-ui-banners-mkt-container .inc-ui-banners-mkt .inc-ui-banners-mkt-item:first-child {
        min-width: 47.21805vw;
        min-height: 20.67669vw
    }

    .inc-ui-banners-mkt-container .inc-ui-banners-mkt .inc-ui-banners-mkt-item:first-child .inc-ui-banners-mkt-item-img {
        width: 47.21805vw;
        height: 20.67669vw
    }
}

.inc-ui-banks {
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #cccccc
}

.inc-ui-banks .inc-ui-banks-container {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
    height: 67px;
    padding-top: 6px
}

.inc-ui-banks .inc-ui-banks-title {
    font-weight: 500;
    color: #444
}

.inc-ui-banks .inc-ui-banks-item {
    text-align: center
}

.inc-ui-banks .inc-ui-banks-item:first-of-type {
    margin-left: 0
}

.inc-ui-banks .inc-ui-banks-facilities {
    font-size: 12px;
    color: #666;
    padding: 4px 0 2px;
    display: block;
    max-width: 60px;
    margin: 0 auto 20px
}

.inc-ui-banks .inc-ui-card-container {
    height: 38px;
    background-color: white;
    border: none;
    overflow: hidden
}

.inc-ui-banks .inc-ui-banks-conditions {
    font-size: 13px;
    line-height: 1.2;
    margin-bottom: 10px
}

#COMMERCIAL {
    background-color: #ddd5f5;
    margin-bottom: 32px
}

.inc-ui-module-wrapper > #COMMERCIAL:first-child {
    margin-top: -57px;
    padding-top: 20px;
    background-color: unset;
    background-repeat: no-repeat;
    background-size: 100%;
}

body.inc-ui-desktop.hot-sale-mx-2020 .inc-ui-module-wrapper > #COMMERCIAL:first-child {
    padding-top: 20px;
    background-color: unset;
    background-repeat: no-repeat;
    background-size: 100%;
}

body.inc-ui-desktop.hot-sale-mx-2020 .inc-ui-module-wrapper > #COMMERCIAL:not(:first-child) {
    background-size: 100% auto;
    background-repeat-x: no-repeat;
    background-repeat-y: repeat
}

body.inc-ui-desktop.hot-sale-mx-2020 .inc-ui-module-wrapper > #COMMERCIAL:not(:first-child) .commercial-module__title, body.inc-ui-desktop.hot-sale-mx-2020 .inc-ui-module-wrapper > #COMMERCIAL:not(:first-child) .commercial-module__subtitle {
    color: white
}

body.inc-ui-desktop.hot-sale-mx-2020 .inc-ui-module-wrapper > #COMMERCIAL:not(:first-child) .commercial-module__go-to-landing {
    background-color: white;
    border-color: white
}

body.inc-ui-desktop.hot-sale-mx-2020 .inc-ui-module-wrapper > #COMMERCIAL:not(:first-child) .commercial-module__go-to-landing:hover {
    color: var(--brand-primary-3);
    background-color: var(--brand-primary-1)
}

.inc-ui-module-wrapper.inc-ui-module-wrapper-mobile > #COMMERCIAL:first-child {
    background-position-y: -80px
}

body.inc-ui-mobile.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-mobile > #COMMERCIAL:first-child {
    background-position: 0 -144px;
    background-size: 100%
}

body.inc-ui-mobile.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-mobile > #COMMERCIAL:first-child .commercial-module__title--mobile, body.inc-ui-mobile.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-mobile > #COMMERCIAL:first-child .commercial-module__go-to-landing {
    color: white
}

body.inc-ui-mobile.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-mobile > #COMMERCIAL:not(:first-child) {
    padding-top: 4px;
    padding-bottom: 4px;
    background-position-y: unset
}

body.inc-ui-mobile.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-mobile > #COMMERCIAL:not(:first-child) .commercial-module__title--mobile, body.inc-ui-mobile.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-mobile > #COMMERCIAL:not(:first-child) .commercial-module__go-to-landing {
    color: white
}

.inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:first-child {
    background-position-y: -18px
}

@media (max-width: 1023px) {
    .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:first-child {
        background-position-y: -18px
    }
}

body.inc-ui-tablet.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:first-child {
    background-position-y: unset
}

body.inc-ui-tablet.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:first-child .commercial-module__title--mobile, body.inc-ui-tablet.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:first-child .commercial-module__subtitle, body.inc-ui-tablet.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:first-child .commercial-module__go-to-landing {
    color: white
}

@media (max-width: 1023px) {
    body.inc-ui-tablet.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:first-child {
        background-position-y: unset
    }
}

body.inc-ui-tablet.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:not(:first-child) {
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: unset;
    background-position-y: unset
}

body.inc-ui-tablet.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:not(:first-child) .commercial-module__title--mobile, body.inc-ui-tablet.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:not(:first-child) .commercial-module__subtitle {
    color: white
}

body.inc-ui-tablet.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:not(:first-child) .commercial-module__go-to-landing {
    color: white;
    border-color: white
}

body.inc-ui-tablet.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:not(:first-child) .commercial-module__text-section--mobile {
    padding-top: 60px
}

@media (max-width: 1023px) {
    body.inc-ui-tablet.hot-sale-mx-2020 .inc-ui-module-wrapper.inc-ui-module-wrapper-tablet > #COMMERCIAL:not(:first-child) {
        background-position-y: unset
    }
}

.commercial-module {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-areas: "heading offers";
    width: calc(100% + 12px)
}

@media (max-width: 1278px) {
    .commercial-module {
        grid-template-columns: 30% 70%
    }
}

.commercial-module .--no-padding {
    padding: 0
}

.commercial-module--mobile {
    grid-template-rows: repeat(2, min-content);
    grid-template-columns: 100%;
    grid-template-areas: "heading" "offers";
    margin-top: 24px;
    grid-row-gap: 28px;
    margin-bottom: 40px;
    width: auto
}

.commercial-module--tablet {
    grid-template-columns: 30% 70%;
    grid-template-areas: "heading offers";
    padding-left: 15px
}

@media (max-width: 1023px) {
    .commercial-module--tablet {
        grid-template-rows: repeat(2, min-content);
        grid-template-columns: 100%;
        grid-template-areas: "heading" "offers";
        margin-top: 24px;
        grid-row-gap: 28px;
        margin-bottom: 40px;
        width: auto
    }
}

.commercial-module__text-section {
    grid-area: heading;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 0
}

.commercial-module__text-section--mobile {
    padding: 0 10px;
    align-items: flex-start
}

.commercial-module__lead {
    background: #fa8e04;
    color: white;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 20px 0
}

.commercial-module__lead--mobile {
    margin-top: 0;
    margin-bottom: 16px
}

.commercial-module__title {
    color: var(--color-gray-1);
    margin: 20px 0
}

.commercial-module__title--mobile {
    margin: 0;
    margin-bottom: 16px
}

.commercial-module__subtitle {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    color: var(--color-gray-2);
    line-height: 24px
}

.commercial-module__carousel-content {
    overflow: hidden;
    margin: 0 25px
}

.commercial-module__carousel-content--mobile {
    margin: 0;
    padding: 0 4px
}

.commercial-module__carousel-content .list-container {
    transition: all .5s;
    right: 0;
    position: relative
}

.commercial-module__nav-dots {
    margin: 0 80px
}

.commercial-module__offers {
    padding-top: 32px;
    grid-area: offers
}

.commercial-module__offers--tablet, .commercial-module__offers--mobile {
    padding-top: 0
}

.commercial-module__to-landing-card {
    width: 100%;
    height: 100%;
    background: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px
}

.commercial-module__arrow, .commercial-module__text {
    color: var(--brand-primary-4)
}

@media (min-width: 1023px) {
    .commercial-module__go-to-landing--tablet {
        padding: 0 20px;
        height: 48px;
        border: 1px solid var(--brand-primary-3);
        line-height: 48px;
        border-radius: 100px;
        font-size: 16px
    }

    .commercial-module__go-to-landing--tablet btn-icon {
        display: none
    }
}

.carousel-cards__item-mobile {
    flex: 0 0 90%;
    display: flex
}

.carousel-cards__more-offers-card {
    flex: 0 0 100%
}

.carousel-cards__item {
    flex: 0 0 33.33%;
    display: flex
}

@media (max-width: 1279px) {
    .carousel-cards__item {
        flex: 0 0 50%
    }
}

@media (max-width: 1024px) {
    .carousel-cards__item {
        flex: 0 0 47%
    }
}

.commercial-card-mobile {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    width: 100%
}

.commercial-card-mobile .inc-ui-card-content {
    padding: 24px 16px
}

.commercial-card {
    position: relative
}

.commercial-card.inc-ui-card {
    max-width: initial;
    width: 100%
}

.commercial-card .inc-ui-card-content {
    flex-grow: 1
}

.commercial-card__logo-pipe {
    position: absolute;
    top: 12px;
    right: 0
}

.commercial-card__logo-pipe .vxa-3-label.-top-right .label-container {
    background-color: var(--color-white)
}

.commercial-card__logo-pipe .vxa-3-label.-top-right .label-container:before {
    top: 1px;
    border-bottom-color: var(--color-white)
}

.commercial-card__logo-pipe .vxa-3-label.-lg .label-text {
    line-height: unset
}

.commercial-card__img {
    max-height: 32px
}

.commercial-card__price-description {
    border-top: 1px solid #ccc;
    padding: 16px;
    color: var(--color-gray-2)
}

.position-absolute {
    position: absolute
}

@keyframes bounce-left {
    0% {
        transform: translateX(0)
    }
    50% {
        transform: translateX(-15px)
    }
    100% {
        transform: translate(0)
    }
}

@keyframes bounce-right {
    0% {
        transform: translateX(0)
    }
    50% {
        transform: translateX(15px)
    }
    100% {
        transform: translate(0)
    }
}

.animated {
    animation-duration: .6s;
    animation-timing-function: ease-out
}

.animated--bounce-left {
    animation-name: bounce-left
}

.animated--bounce-right {
    animation-name: bounce-right
}

.inc-ui-card {
    box-sizing: border-box;
    border-radius: 8px
}

.inc-ui-card .inc-ui-card-img {
    height: 200px;
    overflow: hidden
}

.inc-ui-card .inc-ui-card-img .inc-ui-card-img-tag {
    object-fit: cover
}

.inc-ui-card .inc-ui-card-img .inc-ui-card-img-tag.-cars-category {
    object-fit: contain
}

.inc-ui-card .inc-ui-card-bold {
    font-weight: 500
}

.inc-ui-card .inc-ui-card-img {
    height: 300px;
    border-bottom: 1px solid #EEE;
    background-repeat: no-repeat;
    background-position: center
}

.inc-ui-card .inc-ui-card-img.-hb-offer-fallbackImg {
}

.inc-ui-card .inc-ui-card-img.-hb-offer-fallbackImg .inc-ui-card-img-tag {
    display: none
}

.inc-ui-card .inc-ui-card-img.-hb-offer-fallbackImg-mobile {
}

.inc-ui-card .inc-ui-card-img.-hb-offer-fallbackImg-mobile .inc-ui-card-img-tag {
    display: none
}

.inc-ui-card.-hb-large .inc-ui-card-img {
    height: 295px
}

.inc-ui-card .inc-ui-card-content {
    box-sizing: border-box;
    position: relative
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-product {
    color: #666666
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-title {
    color: #444444;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-content-row {
    color: #666666;
    display: flex;
    align-items: center;
    overflow: hidden
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-content-row.inc-ui-card-drivers-level1-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-content-row:last-child {
    margin-bottom: 0
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-content-row .inc-ui-card-rating {
    margin-right: 8px
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-content-row .inc-ui-card-stars-container {
    margin-right: 8px
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-content-row .inc-ui-card-stars-container {
    display: flex
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-content-row .inc-ui-card-stars-container i {
    color: #E16C02
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-content-row .inc-ui-card-driver-text {
    color: #444444
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-driver-img {
    text-transform: uppercase
}

.inc-ui-card-product {
    margin-bottom: 8px
}

.inc-ui-card-product, .inc-ui-card-head {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    box-sizing: border-box
}

.inc-ui-card-head {
    padding-bottom: 8px
}

.inc-ui-card {
    max-width: 300px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.inc-ui-card .inc-ui-card-head {
    color: #666666
}

.inc-ui-card .inc-ui-card-content {
    padding: 24px 16px
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-driver-img {
    margin-top: -34px;
    margin-bottom: 12px
}

.inc-ui-card .inc-ui-card-content .inc-ui-card-title {
    font-size: 24px;
    line-height: 28px;
    max-height: 58px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.inc-ui-card .inc-ui-card-content-row {
    margin: 8px 0 0
}

.inc-ui-card .inc-ui-card-content-row.inc-ui-card-drivers-level1-container {
    flex-wrap: nowrap
}

.inc-ui-card .inc-ui-card-content-row.inc-ui-card-drivers-level2-container {
    flex-wrap: wrap
}

.inc-ui-card .inc-ui-card-content-row.inc-ui-card-drivers-level2-container .inc-ui-driver {
    margin: 0 4px 4px 0
}

.inc-ui-card .inc-ui-card-img {
    border-radius: 8px 8px 0 0;
    height: 200px;
    background-size: cover;
    background-position: center
}

.inc-ui-card .inc-ui-card-img .inc-ui-card-img-tag {
    border-radius: 8px 8px 0 0
}

.inc-ui-card .inc-ui-driver-level2 {
    margin-left: 0
}

.inc-ui-card .tooltip-container.inc-ui-tooltip-container {
    width: unset;
    min-width: 265px;
    max-width: 290px
}

@media (min-width: 1366px) {
    .inc-ui-card .inc-ui-card-content {
        padding: 24px
    }
}

.inc-ui-pricebox {
    border-top: 1px solid #CCC;
    box-sizing: border-box
}

.inc-ui-pricebox__legal-text {
    font-size: 12px
}

.inc-ui-pricebox__description-text {
    font-size: 12px;
    margin-top: 2px
}

.inc-ui-pricebox__old-price {
    letter-spacing: -0.2px;
    color: #AAA;
    text-decoration: line-through
}

.inc-ui-pricebox {
    height: 100px;
    padding: 16px 0 16px 16px;
    display: grid;
    grid-template-columns: 73% 27%;
    grid-template-rows: 14px 14px 38px
}

.inc-ui-pricebox__legal-text {
    grid-column: 1 / 3;
    grid-row: 1;
    margin-bottom: 4px
}

.inc-ui-pricebox__description-text {
    grid-column: 1 / 3;
    grid-row: 2
}

.inc-ui-pricebox__price-symbol {
    font-size: 14px;
    margin-right: 4px
}

.inc-ui-pricebox__price {
    font-size: 24px;
    line-height: 1;
    letter-spacing: -0.18px;
    grid-column: 1;
    grid-row: 3;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end
}

.inc-ui-pricebox__old-price {
    font-size: 12px;
    line-height: 1.08;
    margin-left: 4px
}

.inc-ui-pricebox__discount {
    grid-column: 2;
    grid-row: 3;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end
}
