@font-face {
    font-family: "nunitoSans";
    src: url("../../comum/fonts/NunitoSans-Bold.ttf");
    font-display: swap;
}

a.dropdown-item.drop-item-texto {
    background-color: initial;
}

.navbar-nav .nav-item a.nav-link,
.navbar-nav .nav-item.active a.nav-link {
    font-size: 14px;
    border-bottom: 0px;
}

.redes svg.MuiSvgIcon-root {
    font-size: 35px;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 30px;
}

.navbar-nav .nav-item.redes-sociais {
    display: inline-flex;
}

.collapsing {
    height: 0 !important;
    display: flex;
}

.interno .navbar-nav .nav-item a.nav-link,
.interno .navbar-nav .nav-item.active a.nav-link {
    color: #000;
}

/* ALTERACAO */

.setaRetornaAoTopo {
    justify-content: center;
    margin-bottom: 30px;
    margin: 0;
}

.setaRetornaAoTopo .iconUp {
    transform: rotate(270deg);
}

.iconUp {
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: 55px !important;
    padding: 8px;
    cursor: pointer;
}

.wp-block {
    display: flex;
    justify-content: center;
    top: 0px;
}

.cookies {
    position: fixed;
    display: flex;
    bottom: 0%;
    width: 100%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    z-index: 999;
    min-height: 110px;
    justify-content: center;
}

p.navbar-brand {
    font-size: max(16px, 1.2vw) !important;
    white-space: initial;
    line-height: max(20px, 2.5vw);
}

.cookies .btn {
    background-color: white;
    margin-left: 10px;
    color: black;
}

.cookies .btn-outline-primary {
    border-color: white;
    font-size: 14px;
    text-transform: none;
}

.cookies u {
    color: white;
}

.cookies u:hover {
    color: rgb(206, 203, 203);
    opacity: 1 !important;
}

.cookies .btn:hover {
    background-color: rgb(206, 203, 203);
    opacity: 1 !important;
}

/* LOGO AGENCIA */
.agencia {
    width: 70px;
}

/* BANNER INICIO DESKTOP */

.banner-inicio {
    height: auto;
    width: 100%;
}

.cards-dicas {
    display: block;
    width: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-color: #ffffff;
}

.cards-dicas .about-us {
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 0px;
    margin-right: 10%;
    margin-bottom: 0px;
    margin-left: 10%;
}

.cards-dicas .about-us .text-about-us {
    padding: 1rem;
    margin: auto;
    height: auto;
    overflow: hidden;
    text-align: center;
}

.cards-dicas .about-us .text-about-us h1 {
    font-size: 30px;
    font-weight: bold;
    padding: 10px;
    margin: 3px;
    text-align: center;
}

.cards-dicas .about-us .text-about-us h2 {
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    margin: 3px;
    text-align: center;
}

.cards-dicas .about-us .text-about-us p {
    align-self: flex-start;
    text-align: justify;
    font-size: 20px;
    padding: 10px;
    margin: 3px;
    padding-bottom: 0.5rem;
    overflow: hidden;
    line-height: 1.75rem;
}

#verMaisButton {
    font-size: 18px;
    background-color: #9a9a9a74;
    padding: 8px;
    border-radius: 15px;
    margin-top: 20px;
}

#recolherButton {
    font-size: 18px;
    background-color: #9a9a9a74;
    padding: 8px;
    border-radius: 15px;
    margin-top: 20px;
}

/* CARDS DESTINOS MAIS PROCURADOS */

.cards-destino {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.cards-destino .card-body-destinos {
    padding: 15px 15px 5px 15px;
}

.cards-destino .card-body-destinos .titulo-info {
    border-bottom: 1px solid #ccc;
    margin: 0 2px 15px;
}

.cards-destino .card-body .titulo-info .coluna-dir,
.cards-destino .card-body .titulo-info .coluna-esq {
    padding: 0;
}

.cards-destino .card-body .coluna-dir #titleH5,
.cards-destino .card-body .coluna-esq #titleH5 {
    font-weight: bold;
    font-style: italic;
    font-size: 15px;
    padding: 5px 0;
    margin-bottom: 0;
}

.cards-destino .card-body .texto-info .coluna-dir #titleH5 {
    font-weight: lighter;
    font-family: 'antItalic';
}

.cards-destino .card-body .coluna-dir {
    text-align: right;
}

.alice-carousel__stage {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    margin: 0px;
    padding: 0;
    white-space: nowrap;
    transform-style: flat;
    -webkit-transform-style: flat;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.alice-carousel {
    display: inherit;
    height: 420px;
}

.card-container {
    position: relative;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    width: 250px;
    height: 390px !important;
    margin: 30px;
    align-items: center;
    justify-content: flex-start;
}

.card-container img {
    position: absolute;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    border-radius: 12px;
}

#precoDestino {
    display: flex;
    height: 66px;
    flex-direction: column;
    position: relative;
    top: -162px;
    margin-left: 47px;
    text-shadow: 1px 2px black;
    text-align: center;
    padding: 15px;
    z-index: 1;
    font-size: 25px;
    text-align: center;
    background: #ffffff;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}

.card-body-destinos {
    top: 250px;
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 1px;
}

.card-container .destino-subtitulo {
    top: 210px;
    position: absolute;
    font-size: 20px;
    color: #000000;
    text-align: center;
    padding: 5px;
    background: #f8ae1b;
    border-radius: 3px;
}

.link-viagens {
    height: 70px;
}

.texto-info {
    display: flex;
    height: 110px;
    width: 219px;
    border-radius: 12px;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    font-size: 23px;
    align-items: flex-start;
    background: rgba(245, 145, 30, 0.429);
}

.cards-destino .card-body-destinos .link-viagens .texto-info:hover {
    background-color: rgb(245 146 30);
}

.card-title-preco {
    font-size: 16px;
    color: #000000;
    text-shadow: none;
}

.card-title-valor {
    display: flex;
    top: 17%;
    color: rgb(245 146 30);
    text-shadow: 1px 2px black;
    align-items: center;
    padding: 5px;
}

.card-title-destino {
    display: flex;
    top: 17%;
    position: relative;
    color: rgb(255, 255, 255);
    text-shadow: 1px 2px black;
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
}

#destinoIcons {
    font-size: 16px;
    padding: 0px 6px 0px 0px;
}

.titulo-info {
    display: flex;
    color: #ffffff;
    text-align: end;
    margin: 15px;
    padding: 5px;
}

.alice-carousel__prev-btn {
    position: absolute;
    top: 45%;
    text-align: left;
    left: -5rem;
    background-color: #e6e8f000;
    width: 4rem !important;
    border-radius: 50%;
    height: 4rem;
    line-height: 1px;
}

.alice-carousel__next-btn {
    position: absolute;
    top: 45%;
    text-align: left;
    right: -5rem;
    background-color: #e6e8f000;
    width: 4rem !important;
    border-radius: 50%;
    height: 4rem;
    line-height: 1px;
}

.alice-carousel__next-btn-item span,
.alice-carousel__prev-btn-item span {
    color: #000000;
}

.alice-carousel__prev-btn-wrapper {
    height: 0px;
    width: 0px;
    padding: 0px;
}

.alice-carousel__prev-btn {
    height: 60px;
    width: 60px;
    padding: 10px;
}

.alice-carousel__next-btn-wrapper {
    height: 0px;
    width: 0px;
    padding: 0px;
}

.alice-carousel__next-btn {
    height: 60px;
    width: 60px;
    padding: 10px;
}

.alice-carousel__next-btn-item {
    height: 0px;
    width: 0px;
    padding: 0px;
}

/* AREA DE BUSCAS */

div #react-autowhatever-1 {
    background-color: #fff;
    font-weight: bold;
    position: absolute;
    width: 90%;
    z-index: 9999;
    max-height: 350px;
    overflow-y: scroll;
}

.destino div #react-autowhatever-1 {
    width: 100%;
}

.carousel-indicators li {
    width: 86% !important;
    height: 15px !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    text-indent: 0 !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    padding: 15px 15px !important;
}

.carousel-indicators {
    width: 100%;
    height: 15px;
    margin-right: 0;
    margin-left: 0;
    text-indent: 0;
    border-top: 0;
    border-bottom: 0;
    padding: 15px 15px;
}

.campo-busca {
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid #e4e7ea;
    border-left: 0px;
    border-radius: 0px;
    padding-left: 15%;
    will-change: transform;
    transform: translateZ(0);
}

.item-passagem {
    border-color: #ffffff;
}

.wrapper {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: #0064e8;
    display: flex;
    padding-top: 6px;
    padding-bottom: 6px;
    align-items: center;
    grid-gap: 5px;
    cursor: pointer;
}

.wrapper .tooltip {
    position: absolute;
    width: 400px;
    top: 0;
    z-index: 1;
    background: #fff;
    color: #0064e8;
    padding: 10px 18px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 25px;
    opacity: 0;
    pointer-events: none;
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.332);
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper:hover .tooltip {
    top: -180px;
    opacity: 1;
    pointer-events: auto;
}

.wrapper .tooltip:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background: #fff;
    left: 50%;
    bottom: -6px;
    transform: translateX(-50%) rotate(45deg);
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper:hover {
    color: #464646;
}

.wrapper:hover,
.wrapper:hover .tooltip {
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.wrapper:hover,
.wrapper:hover .tooltip,
.wrapper:hover .tooltip:before {
    color: #464646;
    background: #ffffff;
    border-radius: 3px;
}

#bilhete {
    font-size: 16px !important;
    color: rgb(2, 198, 89);
}

#imagemEmpresa {
    width: 150px;
    height: 150px;
    padding: 3px;
}

#infoVeiculo {
    align-items: center;
}

#infoVeiculo button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 30px;
    padding: 0 24px;
    line-height: 44px;
    white-space: nowrap;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 8px;
    color: #fff;
    background-color: #001755;
}

#infoVeiculo button:hover {
    background-color: #0064e8;
    color: #ffffff;
}

#infoVeiculo img {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 25px;
}

#trechoHora {
    display: flex;
    justify-items: center;
    flex-direction: column;
    justify-content: center;
}

#contrateAgora {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 30px;
}

#precoPassagem {
    display: flex;
    justify-items: center;
    flex-direction: column;
    justify-content: center;
}

h1#titulo-page {
    font-size: 18px !important;
    margin-bottom: 5px;
    font-weight: bold;
}

h4#titulo-page {
    font-style: italic;
    font-weight: lighter;
    font-size: 16px !important;
}

.card {
    margin-bottom: 0px;
}

#maisInfoContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.avisoInformativo {
    display: flex;
    height: 30px;
    width: 90%;
    padding: 20px;
    border: 2px solid #ff9e16fc;
    border-radius: 14px;
    font-size: 16px;
    background: #ffc91677;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}

#iconWarning {
    font-size: 16px;
    margin-right: 15px;
    color: #ff9e16fc;
}

#divPassagem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 14px;
}

.btn-detalhamento {
    border: 1px solid #02c659;
    padding: 5px 10px;
    display: inline-flex;
    border-radius: 30px;
    width: 50%;
    justify-content: space-evenly;
}

.btn-alterarFormaPagamento {
    border: 1px solid #000000;
    padding: 8px 5px;
    display: inline-flex;
    border-radius: 30px;
    width: 70%;
    justify-content: space-evenly;
}

#btnAlterarPagamento {
    margin: 8px 0px 0px 369px;
}

#wallet {
    font-size: 16px;
}

.detalhe {
    margin-top: 7px;
}

.img-reserva {
    position: relative;
    border-radius: 12px;
    width: 15em;
}

.btn-bilhete {
    border: 1px solid #02c659;
    padding: 5px 10px;
    display: inline-flex;
    border-radius: 30px;
    width: 50%;
    justify-content: space-evenly;
}

.imprimir {
    justify-content: flex-end;
    display: inline-flex;
}

.img-reserva {
    position: relative;
    border-radius: 12px;
    width: 15em;
}

.card.col-sm-12.item-passagem {
    border-radius: 8px;
    box-shadow: 1px 1px 10px 2px #d5d2d2;
}

#divPassagemButton {
    display: flex;
    height: 35px;
    width: 200px;
    font-size: 16px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.arrow {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 10px;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 4px solid transparent;
}

.arrow.expanded {
    transform: rotate(180deg);
}

#containerActive {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-content: center;
}

#informativoIcons {
    font-size: 16px;
}

#cardImagem {
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.avaliacao {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
}

.fas.fa-star {
    color: #f1e106;
    padding: 0px 3px 0px 3px;
}


.sectionMaps {
    padding: 0px;
    margin: 0px;
}

.row.informativos {
    align-items: center;
    text-align: justify;
    font-size: 16px;
    flex-wrap: wrap;
    padding: 10px;
    margin: 3px;
}

.row.informativos span {
    padding: 0px 15px 0px 15px;
}

.informativoText {
    padding: 10px;
}

.sectionMapa1 {
    height: 310px;
    width: 450px;
    font-size: 16px;
}

.sectionMapa2 {
    height: 310px;
    width: 450px;
    font-size: 16px;
}

.renderMapa {
    height: 240px;
    width: 450px;
    padding: 10px 0px 10px 0px;
}

.rota .rota-hora-start {
    text-align: right;
    font-weight: 700;
    color: #000000;
    padding: 0 16px 0 0;
}

.rota .rota-hora-end {
    text-align: right;
    font-weight: 700;
    color: #000000;
    padding: 0 0 0 16px;
}

.rota .rota-tempo .rota-tempo-texto.tempo {
    margin-top: -14px;
    margin-bottom: 4px;
    color: #0064e8;
    font-weight: bold;
    font-size: 16px;
    padding: 6px;
}

.valor-passagem {
    display: block;
    padding: 10%;
    text-align: center;
    margin-bottom: 0;
    color: rgb(2, 198, 89) !important;
}

.card-title {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
}

.tit-assento {
    display: block;
    font-size: 16px !important;
    margin: 0 0 10px 0;
    width: 100%;
    font-weight: bold;
}

.qtd-poltrona strong {
    font-size: 25px;
    align-items: center;
}

.qtd-poltrona {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
}

#tipoVeiculoDesc {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.modal {
    position: fixed;
    z-index: 9999;
}

.valor-passagem-itinerario {
    font-size: 35px;
    font-weight: bold;
    text-align: right;
    color: rgb(2, 198, 89);
    font-style: italic;
}

.campo-busca-data {
    border-top: 0px;
    border-right: 0px;
    border-bottom: 1px solid #e4e7ea;
    border-left: 0px;
    border-radius: 0px;
    padding-left: 25%;
    will-change: transform;
    transform: translateZ(0);
}

.taxa-servico {
    cursor: default;
}

div#tooltip-taxa {
    background-color: rgb(63, 62, 62);
    line-height: 20px;
    border-radius: 5px 5px;
    width: 350px;
    font-weight: bold;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

/* INFORMATIVO 'NÃO TEMOS PASSAGEM PARA DATA' */
#textoInfoPassagem {
    display: flex;
    flex-direction: row;
    border-radius: 30px;
    text-align: center;
    justify-content: center;
    padding: 15px;
    background-color: #ffffff !important;
}

#textoInfo {
    font-family: inherit;
    font-size: 20px;
    padding: 20px;
    color: #000;
}

#img-map {
    width: 100px;
    height: 100px;
}

section.secao-redes-sociais {
    display: inherit;
}

/* FOOTER E BOTÃO MAIS INFORMAÇÕES */

.botaoMaisInformacoes {
    display: flex;
    padding: 10px 10px 10px 10px;
    margin-left: 42%;
    margin-bottom: 10px;
    font-size: 16px;
    background-color: #ffffff25;
    border-radius: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    color: #000000;
}

#MaisInformacoes:hover {
    color: #000000 !important;
}

.botaoMaisInformacoes.collapsed #arrow {
    transform: rotate(180deg);
    color: #000000;
}

#arrow {
    margin-left: 15px;
}

.cidade-recebimento-promocao div#react-autowhatever-1 {
    width: 14%!important;
}

/* MEDIA QUERIES - AJUSTES RESPONSIVIDADE PARA TELAS */

@media (max-width: 991px) {
    section.secao-redes-sociais {
        display: initial;
    }

    .navbar-nav.nav-mobile {
        display: none !important;
    }
}

@media (min-width: 481px) and (max-width: 767px) {

    .navbar-nav .nav-item a.nav-link,
    .navbar-nav .nav-item.active a.nav-link {
        font-size: 18px;
        border-bottom: 0px;
    }

    .nav-item.i {
        border-bottom: 1px solid #e6e9ed;
        padding: 20px 10px 10px;
        display: flex;
        margin-left: 10px;
        margin-right: 10px;
    }

    .btn-login {
        font-size: 23px;
        border-radius: 5px;
        width: 95%;
        padding: 8px;
        font-family: "nunitoSans";
        height: 50px;
    }

    .navbar.menu {
        border-bottom: 1px solid #e6e9ed;
    }

    .material-icons .close {
        align-items: right;
    }

    .navbar.menu a {
        font-size: 23px;
        font-family: "nunitoSans";
    }

    .navbar-collapse.show .navbar-nav {
        background-color: #FFFFFF;
        width: 350px;
        right: 0;
        top: 0%;
        height: 100%;
        position: fixed !important;
        z-index: 9999;
        text-align: center;
    }

    .navbar-collapse.show .navbar-nav a {
        color: #000 !important;
        padding: 0;
        margin: 5px;
        font-family: "nunitoSans";
    }

    .alice-carousel__prev-btn {
        position: absolute;
        top: 50%;
        text-align: left;
        left: -2rem;
        width: 4rem !important;
        border-radius: 50%;
        height: 4rem;
        line-height: 1px;
    }

    .alice-carousel__next-btn {
        position: absolute;
        top: 50%;
        text-align: left;
        right: -2rem;
        width: 4rem !important;
        border-radius: 50%;
        height: 4rem;
        line-height: 1px;
    }

    .alice-carousel__prev-btn {
        top: 50%;
        left: -2rem;
    }

    .alice-carousel__next-btn {
        top: 50%;
        right: -2rem;
    }

    .cards-dicas .botao {
        text-align: center;
    }

    .carousel-caption.texto-banner {
        top: 14%;
    }

    .pesquisa-tela-inicial {
        margin-left: 5px;
    }

    .alice-carousel__stage-item {
        width: 100% !important;
        margin-bottom: 15px !important;
        margin-right: 0 !important;
    }

    .carousel-indicators {
        margin-right: 7%;
        margin-left: 7%;
        top: 21%;
    }

    .cards-dicas .about-us {
        padding-left: 1%;
        padding-right: 1%;
        margin-top: 0px;
        margin-right: 1%;
        margin-bottom: 0px;
        margin-left: 1%;
    }

    .logo-site {
        width: 20%;
    }

    .input-busca .col-12 {
        margin-top: 10px;
    }

    footer .sobre {
        padding-left: 15px;
        margin-bottom: 20px;
    }

    .carousel-item img.banner-inicio.desktop {
        display: none;
        position: relative !important;
    }

    .carousel-item img.banner-inicio.mobile {
        display: none;
        position: relative !important;
    }

    .carousel-item img.banner-inicio.mobile2 {
        display: block;
        margin-top: 0;
        min-height: 767px;
    }

    .sidebar-minimized .nav-item:hover a.nav-link {
        color: #fff;
    }

    .navbar-light .navbar-nav .nav-link:focus,
    .navbar-light .navbar-nav .nav-link:hover {
        color: #fff;
    }

    .calendario-semana {
        display: none;
    }

    .calendario-semana-mobile {
        display: flex;
    }

    .carousel-item {
        position: relative !important;
    }

    .carousel-inner {
        position: relative !important;
    }

    .carousel-item h1 {
        color: #ffffff;
        text-align: center !important;
        padding: 2px 80px 0px 80px;
        top: 5px;
        left: 0px;
        right: 0px;
        bottom: 30px;
        font-size: max(20px, 1vw);
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 900;
        margin: 0;
        position: absolute;
        z-index: 1 !important;
        height: 160px !important;
    }

    .carousel-item p {
        line-height: max(21px, 2vw);
    }

    .carousel-item #linha-icon {
        line-height: max(30px, 2vw);
    }

    .carousel-item #blue {
        color: #001755;
    }

    .carousel-item #espaco {
        text-decoration: underline;
    }

    #textoInfoPassagem {
        display: flex;
        flex-wrap: wrap;
        border-radius: 30px;
        text-align: justify;
        justify-content: center;
        padding: 10px;
        background-color: #ffffff !important;
    }

    .origem div #react-autowhatever-1 {
        max-height: 250px;
    }

    .destino div #react-autowhatever-1 {
        max-height: 250px;
    }

    .img-reserva {
        margin-left: 100px;
    }

    .card-body.card-reserva.col-md-8.col-sm-11.info {
        margin-left: 15px;
    }

    button.btn-detalhamento {
        width: 140px;
    }

    #btnAlterarPagamento {
        margin: 0px 0px 0px 0px;
    }
}

@media (max-width: 608px) {

    .cookies {
        height: 120px;
    }

    .p .navbar-brand {
        font-size: 1.2vw !important;
        margin-left: 40px;
        white-space: initial;
    }
}

@media only screen and (max-width : 480px) {

    .navbar-nav .nav-item a.nav-link,
    .navbar-nav .nav-item.active a.nav-link {
        font-size: 18px;
        border-bottom: 0px;
    }

    .nav-item.i {
        border-bottom: 1px solid #e6e9ed;
        padding: 20px 10px 10px;
        display: flex;
        margin-left: 10px;
        margin-right: 10px;
    }

    .btn-login {
        font-size: 23px;
        border-radius: 5px;
        width: 95%;
        padding: 8px;
        font-family: "nunitoSans";
        height: 55px;
    }

    .navbar.menu {
        border-bottom: 1px solid #e6e9ed;
    }

    .material-icons .close {
        align-items: right;
    }

    .navbar.menu a {
        font-size: 23px;
        font-family: "nunitoSans";
    }

    .navbar-collapse.show .navbar-nav {
        background-color: #FFFFFF;
        width: 100%;
        right: 0;
        top: 0%;
        height: 100%;
        position: fixed !important;
        z-index: 9999;
        text-align: center;
    }

    .navbar-collapse.show .navbar-nav a {
        color: #000 !important;
        padding: 0;
        margin: 5px;
        font-family: "nunitoSans";
    }

    .pesquisa-tela-inicial {
        margin-left: 0px;
        margin-bottom: 20px;
    }

    .input-busca {
        padding: 12px 15px 15px 15px !important;
    }

    .area-home .sistemas .card h5 {
        font-size: 14px;
    }

    .corpo-modal .detalhes-modal .linha .individual {
        width: 50%;
        margin-bottom: 8px;
    }

    .app-header .navbar-nav .dropdown-menu-right {
        width: 260px;
        background: #001755;
    }

    .menu-desktop .configuracoes,
    .menu-desktop .menu-usuario {
        display: none;
    }

    .add-icon-logo {
        width: 32px;
        height: 32px;
        background-size: 100% 100%;
    }

    #empresaMenuDropdown {
        width: 260px;
    }

    .nome-empresa .dropdown {
        text-align: left;
    }

    .nome-empresa .dropdown a {
        padding-right: 34px !important;
        position: relative;
    }

    .nome-empresa .dropdown a:after {
        position: absolute;
        top: 20px;
        right: 24px;
    }

    .menu-lateral {
        height: calc(100vh - 40px);
        overflow: auto;
    }

    .menu-lateral .linha {
        padding: 8px 16px;
    }

    .menu-lateral .linha span {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #dddddd;
    }

    .menu-mobile {
        display: block;
    }

    .aside-menu-fixed.sidebar-minimized #menu-lateral .menu-lateral-icones-sistemas .nav-item .nav-link .nav-icon {
        margin: 0 1.2rem 0 0 !important;
    }

    .form-login {
        width: 96%;
    }

    .carousel-indicators {
        top: 25%;
        margin-right: 1%;
        margin-left: 1%;
    }

    .cards-destino {
        padding-top: 40px;
        padding-right: 0px;
        padding-bottom: 40px;
        padding-left: 0px;
        margin: 0px;
    }

    #containerTextInfo {
        width: 100%;
        padding: 0px;
    }

    #inforPassagem {
        padding: 0px;
    }

    .rota .rota-tempo .rota-tempo-texto.tempo {
        margin-top: -1px;
        margin-bottom: 4px;
        color: #0064e8;
        font-weight: bold;
        font-size: 16px;
        padding: 8px;
    }

    .card-group>.card {
        margin: 0px;
        padding: 20px;
    }

    .alice-carousel__wrapper {
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
        box-sizing: border-box;
        width: 100%;
        height: auto;
    }

    .card-body {
        padding: 0px;
    }

    .cards-destino {
        padding-top: 40px;
        padding-right: 0px;
        padding-bottom: 40px;
        padding-left: 0px;
        margin: 0px;
    }

    .alice-carousel__stage {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        white-space: nowrap;
        transform-style: flat;
        -webkit-transform-style: flat;
        backface-visibility: hidden;
    }

    .cards-dicas .about-us .text-about-us {
        padding: 0;
        margin: 0;
        height: auto;
        overflow: hidden;
        text-align: center;
    }

    .cards-dicas .about-us {
        padding-left: 1%;
        padding-right: 1%;
        margin-top: 0px;
        margin-right: 1%;
        margin-bottom: 0px;
        margin-left: 1%;
    }

    .logo-site {
        width: 20%;
    }

    .alice-carousel__prev-btn {
        position: absolute;
        top: 50%;
        text-align: left;
        left: -1.8rem;
        width: 4rem !important;
        border-radius: 50%;
        height: 4rem;
        line-height: 1px;
    }

    .alice-carousel__next-btn {
        position: absolute;
        top: 50%;
        text-align: left;
        right: -1.5rem;
        width: 4rem !important;
        border-radius: 50%;
        height: 4rem;
        line-height: 1px;
    }

    .alice-carousel__prev-btn {
        top: 50%;
        left: -1.8rem;
    }

    .alice-carousel__next-btn {
        top: 50%;
        right: -1.5rem;
    }

    .carousel-caption.texto-banner {
        top: 15%;
        font-size: 1.2rem;
    }

    .carousel-caption.next-item {
        bottom: 0px;
    }

    .banner-inicio {
        position: relative;
    }

    .carousel-item img.banner-inicio.desktop {
        display: none;
        position: relative !important;
    }

    .carousel-item img.banner-inicio.mobile2 {
        display: none;
        position: relative !important;
    }

    .carousel-item img.banner-inicio.mobile {
        display: block;
        margin-top: 0;
        min-height: 850px;
        position: relative !important;
    }

    .input-busca .input-group {
        margin-bottom: 8px;
    }

    .iconDown,
    .iconUp {
        font-size: 30px !important;
        padding: 3px;
    }

    .sidebar-minimized .nav-item:hover a.nav-link {
        color: #fff;
    }

    .navbar-light .navbar-nav .nav-link:focus,
    .navbar-light .navbar-nav .nav-link:hover {
        color: #fff;
    }

    .cards-dicas .botao {
        padding-right: 124px;
    }

    .cards-dicas .btn-primary {
        font-size: 14px;
    }

    .input-busca .col-12 {
        margin-top: 10px;
    }

    .line-btn {
        padding: 0 15px;
    }

    #textoInfoPassagem {
        display: flex;
        flex-wrap: wrap;
        border-radius: 30px;
        text-align: justify;
        justify-content: center;
        padding: 10px;
        background-color: #ffffff !important;
    }

    .conteudo .btn-buscaInterna {
        margin: 5% 0px 10px;
        width: 100%;
    }

    footer small,
    footer a,
    footer span {
        color: #e4e4e4;
        font-size: 16px;
        margin: 8px;
    }

    footer .sobre {
        margin-bottom: 20px;
        padding: 30px;
    }

    footer .col-md-4 {
        margin-bottom: 20px;
        padding: 30px;
    }

    footer .linha-Copyright .col-md-5 span {
        display: block;
        line-height: 20px;
        padding-bottom: 20px;
    }

    .calendario-semana {
        display: none;
    }

    .calendario-semana-mobile {
        display: flex;
    }

    .banner-inicio {
        margin-top: -40px;
        width: 100%;
        background-image: url('../../comum/img/back-agencia.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative !important;
    }

    .carousel-item h1 {
        color: #ffffff;
        text-align: center !important;
        padding: 5px 10px 0px 10px;
        top: 10px;
        left: 0px;
        right: 0px;
        bottom: 30px;
        font-size: max(24px, 2vw);
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 900;
        margin: 0;
        position: absolute;
        z-index: 1 !important;
        height: 200px !important;
    }

    .carousel-item p {
        line-height: max(26px, 2vw);
    }

    .carousel-item #linha-icon {
        line-height: max(42px, 2vw);
    }

    .carousel-item #blue {
        color: #001755;
    }

    .carousel-item #espaco {
        text-decoration: underline;
    }

    .banner-inicio desktop {
        position: relative;
    }

    .wrapper {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #0064e8;
        display: flex;
        padding-top: 6px;
        padding-bottom: 6px;
        align-items: center;
        grid-gap: 5px;
    }

    .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        overflow: hidden;
    }

    #divPassagem {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 14px;
    }

    #divPassagemButton {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        background: #ffffff;
        padding: 8px;
    }

    #containerActive {
        display: flex;
        justify-content: space-around;
        align-items: baseline;
        align-content: center;
        width: 388px;
    }

    .card-body.active {
        width: 380px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    #maisInfoContainer {
        width: 380px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    .avisoInformativo {
        display: flex;
        height: 80px;
        width: 90%;
        padding: 20px;
        border: 2px solid #ff9e16fc;
        border-radius: 14px;
        font-size: 16px;
        background: #ffc91677;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: justify;
    }

    .sectionMaps {
        height: 100%;
        width: 380px;
    }

    .sectionMapa1 {
        display: flex;
        height: 450px;
        width: 380px;
        font-size: 16px;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 15px 0px 15px 0px;
    }

    .sectionMapa1 p {
        align-items: flex-start;
        text-align: start;
    }

    .embarque {
        padding: 0px 10px 0px 10px;
        text-align: justify;
        margin-right: 130px;
    }

    .enderecoEmbarque {
        padding: 0px 10px 0px 10px;
        margin: 0px 30px 0px 30px;
    }

    .sectionMapa2 {
        display: flex;
        height: 450px;
        width: 380px;
        font-size: 16px;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .desembarque {
        padding: 0px 10px 0px 10px;
        text-align: justify;
        margin-right: 80px;
    }

    .enderecoDesembarque {
        padding: 0px 10px 0px 10px;
        margin: 0px 30px 0px 30px;
    }

    .renderMapa {
        height: 350px;
        width: 330px;
        display: flex;
        justify-content: center;
    }

    #containerInform {
        width: 388px;
        padding: 0px;
        margin: 0px;
    }

    .sectionInformativos {
        height: 500px;
        width: 388px;
        margin: 0px;
    }

    #tituloInformativos {
        font-size: 15px;
    }

    .row.informativos {
        width: 388px;
        align-items: center;
        text-align: justify;
        font-size: 16px;
        flex-wrap: wrap;
        padding: 10px;
        margin: 3px;
    }

    #informativo1 {
        width: 370px;
        margin: 10px;
    }

    #informativo2 {
        width: 370px;
        margin: 10px;
    }

    #informativo3 {
        width: 370px;
        margin: 10px;
    }

    #informativo4 {
        width: 370px;
        margin: 10px;
    }

    #cardImagem {
        display: flex;
        flex-direction: column;
        justify-items: center;
        justify-content: center;
    }

    .avaliacao {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
    }

    .card {
        margin-bottom: 0px;
    }

    .card-group>.card {
        margin: 0px;
    }

    .wrapper {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 12px;
        font-weight: bold;
        color: #0064e8;
        display: flex;
        padding-top: 6px;
        padding-bottom: 6px;
        align-items: center;
        grid-gap: 5px;
    }

    .wrapper .tooltip {
        position: absolute;
        width: 115%;
        top: 0;
        z-index: 1;
        background: #fff;
        color: #fff;
        padding: 10px 18px;
        font-size: 20px;
        font-weight: 500;
        border-radius: 25px;
        opacity: 0;
        pointer-events: none;
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
        transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .wrapper:hover .tooltip {
        opacity: 1;
        pointer-events: auto;
    }

    .wrapper .tooltip:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        background: #fff;
        left: 50%;
        bottom: -6px;
        transform: translateX(-50%) rotate(45deg);
        transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .wrapper:hover {
        color: #464646;
    }

    .wrapper:hover,
    .wrapper:hover .tooltip {
        color: #464646;
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
    }

    .wrapper:hover,
    .wrapper:hover .tooltip,
    .wrapper:hover .tooltip:before {
        background: #ffffff;
        border-radius: 10px;
        justify-content: center;
    }

    .qtd-poltrona {
        display: block;
        padding: 0 10px;
    }

    .origem div #react-autowhatever-1 {
        max-height: 250px;
    }

    .destino div #react-autowhatever-1 {
        max-height: 250px;
    }

    .card-container img {
        position: absolute;
        width: 322px;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
    }

    #precoDestino {
        display: flex;
        height: 66px;
        flex-direction: column;
        position: relative;
        top: -162px;
        margin-left: 87px;
        text-shadow: 1px 2px black;
        text-align: center;
        padding: 15px;
        z-index: 1;
        font-size: 25px;
        text-align: center;
        background: #ffffff;
        justify-content: center;
        align-items: center;
    }

    .texto-info {
        display: flex;
        height: 110px;
        width: 219px;
        border-radius: 12px;
        flex-direction: column;
        align-content: center;
        flex-wrap: wrap;
        font-size: 23px;
        align-items: flex-start;
        background: rgb(245 146 30);
        margin-left: 35px;
    }

    .alice-carousel__stage {
        position: relative;
        box-sizing: border-box;
        height: 100%;
        margin: 0px;
        padding: 0;
        white-space: nowrap;
        transform-style: flat;
        -webkit-transform-style: flat;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
    }

    .alice-carousel__stage-item {
        width: 100% !important;
        margin-bottom: 15px !important;
        margin-right: 0 !important;
    }

    .botaoMaisInformacoes {
        margin-left: 26%;
    }

    .img-reserva {
        margin-left: 70px;
    }

    .card-body.card-reserva.col-md-8.col-sm-11.info {
        margin-left: 10px;
    }

    #btnAlterarPagamento {
        margin: 10px 12px 0px 0px;
    }

    #CampoDestino {
        margin: 10px 12px 0px 0px;
    }

    #situacaoReserva {
        margin: 10px 12px 0px 0px;
    }

    #btnDetalhamento {
        margin: 10px 12px 0px 0px;
    }
}

@media (max-width: 431px) {
    .img-reserva {
        margin-left: 50px;
    }

    .card-body.card-reserva.col-md-8.col-sm-11.info {
        margin-left: 0px;
    }
}

@media (max-width: 399px) {
    .img-reserva {
        margin-left: 30px;
    }
}

@media (max-width: 360px) {
    .img-reserva {
        margin-left: 10px;
    }
}

@media (max-width: 335px) {
    .img-reserva {
        margin-left: 0px;
    }
}

@media (max-width: 323px) {
    .img-reserva {
        width: 12em;
        margin-left: 0px;
    }
}

@media (max-width: 277px) {
    .img-reserva {
        margin-left: -20px;
    }
}

@media (max-width: 407px) {
    .cookies {
        height: 160px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .navbar-nav .nav-item a.nav-link,
    .navbar-nav .nav-item.active a.nav-link {
        font-size: 18px;
        border-bottom: 0px;
    }

    .nav-item.i {
        border-bottom: 1px solid #e6e9ed;
        padding: 20px 10px 10px;
        display: flex;
        margin-left: 10px;
        margin-right: 10px;
    }

    .btn-login {
        font-size: 23px;
        border-radius: 5px;
        width: 95%;
        padding: 8px;
        font-family: "nunitoSans";
        height: 50px;
    }

    .navbar.menu {
        border-bottom: 1px solid #e6e9ed;
    }

    .material-icons .close {
        align-items: right;
    }

    .navbar.menu a {
        font-size: 23px;
        font-family: "nunitoSans";
    }

    .navbar-collapse.show .navbar-nav {
        background-color: #FFFFFF;
        width: 350px;
        right: 0;
        top: 0%;
        height: 100%;
        position: fixed !important;
        z-index: 9999;
        text-align: center;
    }

    .navbar-collapse.show .navbar-nav a {
        color: #000 !important;
        padding: 0;
        margin: 5px;
        font-family: "nunitoSans";
    }

    #imagemEmpresa {
        width: 100px;
        height: 100px;
        padding: 3px;
    }

    .qtd-poltrona {
        display: block;
        padding: 0px;
    }

    #infoVeiculo {
        align-items: center;
        padding: 0px;
    }

    .img-reserva {
        width: 10em;
    }

    button.btn-detalhamento {
        width: 140px;
    }


    .cidade-recebimento-promocao div#react-autowhatever-1 {
        width: 39%!important;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .cidade-recebimento-promocao div#react-autowhatever-1 {
        width: 34% !important;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .cidade-recebimento-promocao div#react-autowhatever-1 {
        width: 27% !important;
    }
}


.btn-exit {
    font-size: 23px;
    border-radius: 5px;
    width: 90%;
    padding: 8px;
    font-family: "nunitoSans";
    height: 50px;
}

svg.MuiSvgIcon-root.person-perfil {
    margin-bottom: 5px;
}

a#meuPerfil {
    margin-top: 11px;
}

i.material-icons.meus-pedidos {
    position: absolute;
    margin-left: -25px;
    margin-top: -4px;
}

p.nome-usuario {
    margin-top: 20px;
    font-size: 15px;
    font-family: 'nunitoSans';
}

.close-modal-promocao {
    cursor: pointer !important;
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 14px;
    opacity: 1;
    color: #fff;
}

#modal-promocao .modal-header {
    background-color: transparent !important;
    border-color: transparent !important;
}

#modal-promocao .modal-content {
    background-color: transparent !important;
    border-color: transparent !important;
}

#buttonPagErroWhats {
    background: #34af23;
    margin: 5px;
}

#buttonCotacaoFrete {
    background: #fadf0a;
    margin: 5px;
}

#buttonSolicitarGratuidade {
    background: #fadf0a;
    margin: 5px;
}

#SolicitarGratuidade {
    font-weight: bold;
    color: #000000;
}

#SolicitarGratuidade:hover {
    color: #1DB725;
}

#fraseErroWhats {
    font-weight: bold;
    color: #000000;
}

#fraseErroWhats:hover {
    color: #dddddd;
}

#iconWhatsErro {
    margin-left: 10px;
    font-size: 16px;
}

#iconCotacaoFrete {
    margin-left: 10px;
    font-size: 16px;
}

/* PAGINAÇÃO - PRINCIPAIS TRECHOS */

.page-link-brasil {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #02c659;
    background-color: #fff;
    border: 1px solid #c8ced3;
}

.page-link-brasil:hover {
    color: #3F7C10;
}

.page-item.active .page-link-brasil {
    z-index: 1;
    color: #fff;
    background-color: #ffed00;
    border-color: #ffed00;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

/* PÁGINA TERMO DE COMPRA */
#TermosCompra p {
    line-height: 165%;
    font-size: 16px;
    text-align: justify;
    margin: 10px 10px 20px 10px;
}

/*------------------ BlogCarousel ---------------- */

#container-banners {
    margin-left: 50px;
    margin-right: 50px;
}

#titulo-blogs {
    text-align: center;
    font-weight: bold !important;
    font-size: 32px;
    color: #000;
    margin: 40px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.Container-Carousel {
    margin-left: 5%;
    margin-right: 5%;
}

#container-card {
    flex: 0 0 33.333333%;
    max-width: 33%;
}

.card-body {
    flex: 1 1 auto;
    padding: 5px;
}

a:hover {
    color: #12227d;
}

.card-title {
    height: 40px;
    margin-bottom: 0.75rem;
    color: #000000;
    font-weight: bold;
}

.card-img-top {
    width: 100%;
    height: 205px;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-text {
    max-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #000000;
}

.card-text:hover {
    max-height: none;
    overflow: visible;
}

.card-footer {
    color: #000000;
    cursor: pointer;
    font-weight: bold;
}

.carousel-control-next {
    right: -133px;
    /* color: #000000; */
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
    background-color: #000000;
    border-radius: 100%;
    padding: 3px;
    height: 30px;
    width: 30px;
}

.carousel-control-prev {
    left: -98px;
    /* color: #000000; */
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
    background-color: #000000;
    border-radius: 100%;
    padding: 3px;
    height: 30px;
    width: 30px;
}

.carousel-control-next {
    color: #fff0;
    opacity: .5;
}

.carousel-control-prev {
    color: #fff0;
    opacity: .5;
}

/* ================================ BANNER QUERO UMA AGÊNCIA - TELA INICIAL =============================== */
.container-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    height: 400px;
    width: 100%;
}

.banner-inner {
    padding: 10px;
}

.banner-img {
    display: flex;
    height: 300px;
    width: 1110px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.container-banner-quero-uma-agencia {
    text-align: center;
}

.banner-quero-uma-agencia {
    background-repeat: no-repeat;
    background-size: cover;
    width: 70%;
}

/* =========================== Cards Top Trechos ============================ */

.container-toptrechos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    /* border-radius: 15px; */
    padding: 0px 0px 30px 0px;
    margin: 30px 150px 30px 150px;
}

.container-groupedTrechos {
    display: flex;
    margin: 5px;
}

#containertopTrechos0 {
    padding: 20px 10px 0px 10px;
    border: 1px solid #02c659;
    border-radius: 20px;
    margin: 5px;
}

#containertopTrechos1 {
    padding: 20px 10px 0px 10px;
    border: 1px solid #02c659;
    border-radius: 20px;
    margin: 5px;
}

#containertopTrechos2 {
    padding: 20px 10px 0px 10px;
    border: 1px solid #02c659;
    border-radius: 20px;
    margin: 5px;
}

.cardTrecho:hover {
    border: 1px solid #02c659;
    color: #02c659;
}

#containertopTrechos {
    display: flex;
    margin: 15px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0px 190px 0px 190px;
}

.row .containertopTrechos {
    display: flex;
    align-items: center;
}

.titulo-topTrechos {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;    
    width: 100%;
}

#tituloTrechos {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: bold !important;
    font-size: 22px;
    color: #000;
}

#subTituloTopTrechos {
    padding: 15px;
}

#subTituloTrechos {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: bold !important;
    font-size: 16px;
    color: #000;
    margin-top: 15px;
}

.container-origemDestino {
    display: flex;
    font-size: 16px;
    margin: 3px;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.container-cardTopTrecho {
    display: flex;
}

.trechoOrigem {
    font-size: 12px;
    margin: 3px;
}

.trechoDestino {
    font-size: 12px;
    margin: 3px;
}

.cardTrecho {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9c9c9;
    border-radius: 6px;
    height: 50px;
    width: 300px;
    padding: 6px;
}

.icon-topOrigem {
    display: flex;
    margin: 5px;
}

#arrowTrechos {
    font-size: 12px;
    color: #000;
    padding: 3px 0px 3px 0px;
    margin: 0px 11px 0px 7px;
}

.icon-topDestino {
    display: flex;
    margin: 5px;
}

#trechoDestinoIcons {
    padding: 0px 6px 0px 6px;
}

/* =================== Cards Empresas =================== */

.containerCardsEmpresas {
    display: flex;
    margin: 15px;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0px 190px 0px 190px;
    border-bottom: 1px solid rgba(0, 0, 0, .12);
}

.containerGroupEmpresas {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card-Empresa {
    height: 150px;
    width: 150px;
    margin: 7px;
}

.card-body-empresa {
    height: 150px;
    width: 150px;
    /* margin: 7px; */
}

.card-img-empresa {
    height: 140px;
    width: 140px;
}

#button-pesquisarEmp {
    font-size: 14px;
    background-color: #d9d0d074;
    padding: 8px;
    border-radius: 15px;
    margin-top: 20px;
    color: black;
    border-color: darkgrey;
    margin-bottom: 30px;
}


@media (max-width: 430px) {

    .container-topEmpresas {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .containerCardsEmpresas {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .containerGroupEmpresas {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #CardEmpresa {
        margin-bottom: 10px;
    }

    .card-Empresa {
        height: 170px;
        width: 270px;
        margin: 7px;
    }

    .card-body-empresa {
        height: 164px;
        width: 270px;
        margin: 7px;
    }

    .card-img-empresa {
        height: 150px;
        width: 250px;
    }

    #container-topTrechos1 {
        display: flex;
    }

    .titulo-topTrechos {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px;        
        width: auto;
    }

    .container-groupedTrechos {
        display: flex;
        margin: 5px;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    #containerTituloTrechos {
        display: flex;
        width: auto;
    }

    .container-toptrechos {
        display: flex;
        width: auto;
        margin: 2px;        
    }

    .container-pagina-trechos {
        display: flex;
        flex-direction: column;
    }

    .card-paginaTrechos {
        display: flex;
        flex-direction: column;
    }

    .container-pagina-empresa {
        display: flex;
        flex-direction: column;
        margin: 2px;
    }

    #container-card-pag-empresa {
        display: flex;
        flex-direction: column;
    }

    .banner-img {
        display: flex;
        height: 230px !important;
        width: 367px !important;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
    }

    #container-card {
        flex: 0 0 33.333333%;
        max-width: 25%;
    }

    .container-banner-quero-uma-agencia {
        text-align: center;
    }

    .banner-quero-uma-agencia-mobile {
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
    }

    #container-card {
        /* flex: 0 0 auto; */
        min-width: 101%;
        scroll-snap-align: start;
    }

    .carousel-control-next {
        right: -76px;
        /* color: #000000; */
    }

    .carousel-control-next-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
        background-color: #000000;
        border-radius: 100%;
        padding: 3px;
        height: 30px;
        width: 30px;
    }

    .carousel-control-prev {
        left: -37px;
        /* color: #000000; */
    }

    .carousel-control-prev-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
        background-color: #000000;
        border-radius: 100%;
        padding: 3px;
        height: 30px;
        width: 30px;
    }

    .carousel-control-next,
    .carousel-control-prev {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 25% !important;
        color: #fff;
        text-align: center;
        opacity: .5;
        transition: opacity .15s ease;
    }

    .carousel-control-next {
        color: #fff0;
        opacity: .5;
    }

    .carousel-control-prev {
        color: #fff0;
        opacity: .5;
    }

    #container-banners {
        margin-left: 35px;
        margin-right: 35px;
    }

    .cidade-recebimento-promocao div#react-autowhatever-1 {
        width: 80% !important;
    }
}

/*Página Links Passagens*/

.container-groupedPagTrechos {
    display: flex;
    flex-direction: column;
    width: 66%;
}

#PagtopTrechosLink{
    display: flex;
    width: 100%;
}

.PagLinkcardTrecho {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9c9c9;
    border-radius: 6px;
    height: 50px;
    width: 1000px;
    padding: 6px;
}

#container-link-passagem {
    display: flex;
    width: 100%;
}

@media (max-width: 430px) {

    .PagLinkcardTrecho {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #c9c9c9;
        border-radius: 6px;
        height: 80px;
        width: 300px;
        padding: 6px;
    }

    .container-groupedPagTrechos {
        display: flex;
        flex-direction: column;
        width: 90%;
    }
}

@media (max-width: 1280px) {
    .container-groupedPagTrechos {
        display: flex;
        flex-direction: column;
        width: 110%;
    }
}

@media (max-width: 1780px) {
    .container-groupedPagTrechos {
        display: flex;
        flex-direction: column;
        width: 110%;
    }
}

.direitosEdeveres > p {
    margin-bottom: 0;
}