.eva-card-container {
    min-width: 65.1px;
    height: 40.1px;
    border: 1px solid #DDD;
    border-bottom-color: #CCC;
    background: #EEE;
    position: relative;
    display: inline-block;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: content-box;
}

.eva-card-body {
    background: rgba(255, 255, 255, 0.01);
    background-repeat: no-repeat;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 40px;
    width: 65px;
}

.eva-card-header {
    display: block;
    -moz-border-radius: 2px 2px 0px 0px;
    -webkit-border-radius: 2px;
    border-radius: 2px 2px 0px 0px;
    color: white;
    height: 17px;
    line-height: 17px;
    text-align: center;
    font-size: 10px;
    background: #444444;
    padding: 0 3px;
    z-index: 1;
    position: relative;
    width: 65.1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    cursor: default;
}

.eva-composed {
    overflow: hidden;
}

.eva-composed > .eva-card-body {
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    margin-top: 9px;
}

.eva-card-container.-small {
    min-width: 52.1px;;
    height: 32.1px;
}

.eva-card-container.-small .eva-card-body {
    left: -6px;
    transform: scale(0.8);
}

.eva-card-container.-small .eva-card-header {
    height: 12px;
    line-height: 14px;
    font-size: 8px;
    width: 52.1px;
}

.eva-card-container.eva-composed.-small > .eva-card-body {
    margin-top: 3px;
    transform: scale(0.6);
}

.eva-card-body.NO-BANK, .eva-card-body[class*="NO-BANK-"] {
    background-position: center -0px;
}

.eva-card-body.PVC, .eva-card-body[class*="PVC-"] {
    background-position: center -40px;
}

.eva-card-body.FBEN, .eva-card-body[class*="FBEN-"] {
    background-position: center -80px;
}

.eva-card-body.LOT, .eva-card-body[class*="LOT-"] {
    background-position: center -120px;
}

.eva-card-body.BOG, .eva-card-body[class*="BOG-"] {
    background-position: center -160px;
}

.eva-card-body.EXT, .eva-card-body[class*="EXT-"] {
    background-position: center -200px;
}

.eva-card-body.AHO, .eva-card-body[class*="AHO-"] {
    background-position: center -240px;
}

.eva-card-body.CF, .eva-card-body[class*="CF-"] {
    background-position: center -280px;
}

.eva-card-body.SOL, .eva-card-body[class*="SOL-"] {
    background-position: center -320px;
}

.eva-card-body.CORP, .eva-card-body[class*="CORP-"], .eva-card-body.CORPC, .eva-card-body[class*="CORPC-"] {
    background-position: center -360px;
}

.eva-card-body.VIV, .eva-card-body[class*="VIV-"] {
    background-position: center -400px;
}

.eva-card-body.PE, .eva-card-body[class*="PE-"] {
    background-position: center -440px;
}

.eva-card-body.PRB, .eva-card-body[class*="PRB-"] {
    background-position: center -480px;
}

.eva-card-body.BBVAF, .eva-card-body[class*="BBVAF-"] {
    background-position: center -520px;
}

.eva-card-body.AL, .eva-card-body[class*="AL-"] {
    background-position: center -560px;
}

.eva-card-body.BCH, .eva-card-body[class*="BCH-"] {
    background-position: center -600px;
}

.eva-card-body.SAENZ, .eva-card-body[class*="SAENZ-"] {
    background-position: center -640px;
}

.eva-card-body.BTDF, .eva-card-body[class*="BTDF-"] {
    background-position: center -680px;
}

.eva-card-body.PAYU, .eva-card-body[class*="PAYU-"] {
    background-position: center -720px;
}

.eva-card-body.BICE, .eva-card-body[class*="BICE-"] {
    background-position: center -760px;
}

.eva-card-body.EFE, .eva-card-body[class*="EFE-"], .eva-card-body.DEPO, .eva-card-body[class*="DEPO-"], .eva-card-body.DEPO-RIO, .eva-card-body[class*="DEPO-RIO-"], .eva-card-body.CASH, .eva-card-body[class*="CASH-"] {
    background-position: center -800px;
}

.eva-card-body.FOR, .eva-card-body[class*="FOR-"] {
    background-position: center -840px;
}

.eva-card-body.MD, .eva-card-body[class*="MD-"], .eva-card-body.DEBCO, .eva-card-body[class*="DEBCO-"] {
    background-position: center -880px;
}

.eva-card-body.VI, .eva-card-body[class*="VI-"], .eva-card-body.VI_GRL, .eva-card-body[class*="VI_GRL-"] {
    background-position: center -920px;
}

.eva-card-body.VB, .eva-card-body[class*="VB-"], .eva-card-body.E, .eva-card-body[class*="E-"] {
    background-position: center -960px;
}

.eva-card-body.VD, .eva-card-body[class*="VD-"], .eva-card-body.DEBAR, .eva-card-body[class*="DEBAR-"], .eva-card-body.VDCO, .eva-card-body[class*="VDCO-"] {
    background-position: center -1000px;
}

.eva-card-body.GLC, .eva-card-body[class*="GLC-"] {
    background-position: center -1040px;
}

.eva-card-body.DC, .eva-card-body[class*="DC-"], .eva-card-body.DCCOM, .eva-card-body[class*="DCCOM-"], .eva-card-body.DCPE, .eva-card-body[class*="DCPE-"] {
    background-position: center -1080px;
}

.eva-card-body.CREDDESP, .eva-card-body[class*="CREDDESP-"] {
    background-position: center -1120px;
}

.eva-card-body.CM, .eva-card-body[class*="CM-"] {
    background-position: center -1160px;
}

.eva-card-body.CA, .eva-card-body[class*="CA-"] {
    background-position: center -1200px;
}

.eva-card-body.CL, .eva-card-body[class*="CL-"] {
    background-position: center -1240px;
}

.eva-card-body.AX, .eva-card-body[class*="AX-"], .eva-card-body.AXAR, .eva-card-body[class*="AXAR-"], .eva-card-body.AXMX, .eva-card-body[class*="AXMX-"], .eva-card-body.AXC, .eva-card-body[class*="AXC-"] {
    background-position: center -1280px;
}

.eva-card-body.NT, .eva-card-body[class*="NT-"], .eva-card-body.NAT, .eva-card-body[class*="NAT-"] {
    background-position: center -1320px;
}

.eva-card-body.CS, .eva-card-body[class*="CS-"], .eva-card-body.CSCL, .eva-card-body[class*="CSCL-"] {
    background-position: center -1360px;
}

.eva-card-body.TN, .eva-card-body[class*="TN-"], .eva-card-body.NAR, .eva-card-body[class*="NAR-"], .eva-card-body.AX_NAR, .eva-card-body[class*="AX_NAR-"], .eva-card-body.CA_NAR, .eva-card-body[class*="CA_NAR-"], .eva-card-body.VI_NAR, .eva-card-body[class*="VI_NAR-"] {
    background-position: center -1400px;
}

.eva-card-body.BANOR, .eva-card-body[class*="BANOR-"] {
    background-position: center -1440px;
}

.eva-card-body.PR, .eva-card-body[class*="PR-"] {
    background-position: center -1480px;
}

.eva-card-body.HC, .eva-card-body[class*="HC-"] {
    background-position: center -1520px;
}

.eva-card-body.EC, .eva-card-body[class*="EC-"] {
    background-position: center -1560px;
}

.eva-card-body.BX, .eva-card-body[class*="BX-"] {
    background-position: center -1600px;
}

.eva-card-body.BBVAB, .eva-card-body[class*="BBVAB-"], .eva-card-body.BBVABS, .eva-card-body[class*="BBVABS-"] {
    background-position: center -1640px;
}

.eva-card-body.MG, .eva-card-body[class*="MG-"] {
    background-position: center -1680px;
}

.eva-card-body.HSBC, .eva-card-body[class*="HSBC-"] {
    background-position: center -1720px;
}

.eva-card-body.DS, .eva-card-body[class*="DS-"], .eva-card-body.DIS, .eva-card-body[class*="DIS-"] {
    background-position: center -1760px;
}

.eva-card-body.CB, .eva-card-body[class*="CB-"] {
    background-position: center -1800px;
}

.eva-card-body.SAN, .eva-card-body[class*="SAN-"], .eva-card-body.SANBR, .eva-card-body[class*="SANBR-"], .eva-card-body.STR, .eva-card-body[class*="STR-"], .eva-card-body.SANWA, .eva-card-body[class*="SANWA-"], .eva-card-body.RIO, .eva-card-body[class*="RIO-"], .eva-card-body.SANDESPI, .eva-card-body[class*="SANDESPI-"], .eva-card-body.SANDESPP, .eva-card-body[class*="SANDESPP-"], .eva-card-body.SANDESPG, .eva-card-body[class*="SANDESPG-"] {
    background-position: center -1840px;
}

.eva-card-body.ICBC, .eva-card-body[class*="ICBC-"] {
    background-position: center -1880px;
}

.eva-card-body.SB, .eva-card-body[class*="SB-"], .eva-card-body.SCB, .eva-card-body[class*="SCB-"], .eva-card-body.SBP, .eva-card-body[class*="SBP-"], .eva-card-body.SBPA, .eva-card-body[class*="SBPA-"], .eva-card-body.SBPSG, .eva-card-body[class*="SBPSG-"] {
    background-position: center -1920px;
}

.eva-card-body.BTB, .eva-card-body[class*="BTB-"] {
    background-position: center -1960px;
}

.eva-card-body.LIV, .eva-card-body[class*="LIV-"] {
    background-position: center -2000px;
}

.eva-card-body.BANRE, .eva-card-body[class*="BANRE-"] {
    background-position: center -2040px;
}

.eva-card-body.BAJIO, .eva-card-body[class*="BAJIO-"] {
    background-position: center -2080px;
}

.eva-card-body.INBUR, .eva-card-body[class*="INBUR-"] {
    background-position: center -2120px;
}

.eva-card-body.FAMSA, .eva-card-body[class*="FAMSA-"] {
    background-position: center -2160px;
}

.eva-card-body.IXE, .eva-card-body[class*="IXE-"] {
    background-position: center -2200px;
}

.eva-card-body.BANJE, .eva-card-body[class*="BANJE-"] {
    background-position: center -2240px;
}

.eva-card-body.SJU, .eva-card-body[class*="SJU-"] {
    background-position: center -2280px;
}

.eva-card-body.ITAU, .eva-card-body[class*="ITAU-"], .eva-card-body.ITU, .eva-card-body[class*="ITU-"], .eva-card-body.ITA, .eva-card-body[class*="ITA-"], .eva-card-body.ITAUCO, .eva-card-body[class*="ITAUCO-"], .eva-card-body.ITUY, .eva-card-body[class*="ITUY-"], .eva-card-body.ITAUB, .eva-card-body[class*="ITAUB-"] {
    background-position: center -2320px;
}

.eva-card-body.AFIRM, .eva-card-body[class*="AFIRM-"] {
    background-position: center -2360px;
}

.eva-card-body.MIFEL, .eva-card-body[class*="MIFEL-"] {
    background-position: center -2400px;
}

.eva-card-body.INVEX, .eva-card-body[class*="INVEX-"] {
    background-position: center -2440px;
}

.eva-card-body.BSE, .eva-card-body[class*="BSE-"] {
    background-position: center -2480px;
}

.eva-card-body.NBSF, .eva-card-body[class*="NBSF-"] {
    background-position: center -2520px;
}

.eva-card-body.BERSA, .eva-card-body[class*="BERSA-"] {
    background-position: center -2560px;
}

.eva-card-body.SCR, .eva-card-body[class*="SCR-"] {
    background-position: center -2600px;
}

.eva-card-body.CHA, .eva-card-body[class*="CHA-"] {
    background-position: center -2640px;
}

.eva-card-body.NBLR, .eva-card-body[class*="NBLR-"] {
    background-position: center -2680px;
}

.eva-card-body.BLP, .eva-card-body[class*="BLP-"] {
    background-position: center -2720px;
}

.eva-card-body.OXXO, .eva-card-body[class*="OXXO-"] {
    background-position: center -2760px;
}

.eva-card-body.PAGOFACIL, .eva-card-body[class*="PAGOFACIL-"], .eva-card-body.PAYU-PFACIL, .eva-card-body[class*="PAYU-PFACIL-"], .eva-card-body.PAYU-PAGOFACIL, .eva-card-body[class*="PAYU-PAGOFACIL-"] {
    background-position: center -2800px;
}

.eva-card-body.RAPIPAGO, .eva-card-body[class*="RAPIPAGO-"], .eva-card-body.PAYU-RAPIPAGO, .eva-card-body[class*="PAYU-RAPIPAGO-"] {
    background-position: center -2840px;
}

.eva-card-body.SENCI, .eva-card-body[class*="SENCI-"] {
    background-position: center -2880px;
}

.eva-card-body.ELEV, .eva-card-body[class*="ELEV-"] {
    background-position: center -2920px;
}

.eva-card-body.SEPAEX, .eva-card-body[class*="SEPAEX-"] {
    background-position: center -2960px;
}

.eva-card-body.MCH, .eva-card-body[class*="MCH-"] {
    background-position: center -3000px;
}

.eva-card-body.MAN, .eva-card-body[class*="MAN-"] {
    background-position: center -3040px;
}

.eva-card-body.MULTI, .eva-card-body[class*="MULTI-"], .eva-card-body.MULTIVA, .eva-card-body[class*="MULTIVA-"] {
    background-position: center -3080px;
}

.eva-card-body.FACIL, .eva-card-body[class*="FACIL-"] {
    background-position: center -3120px;
}

.eva-card-body.AZTEC, .eva-card-body[class*="AZTEC-"] {
    background-position: center -3160px;
}

.eva-card-body.AUTFI, .eva-card-body[class*="AUTFI-"] {
    background-position: center -3200px;
}

.eva-card-body.LOJ, .eva-card-body[class*="LOJ-"] {
    background-position: center -3240px;
}

.eva-card-body.SANSE, .eva-card-body[class*="SANSE-"] {
    background-position: center -3280px;
}

.eva-card-body.WMART, .eva-card-body[class*="WMART-"] {
    background-position: center -3320px;
}

.eva-card-body.BANS, .eva-card-body[class*="BANS-"] {
    background-position: center -3360px;
}

.eva-card-body.TBANC, .eva-card-body[class*="TBANC-"] {
    background-position: center -3400px;
}

.eva-card-body.COR, .eva-card-body[class*="COR-"] {
    background-position: center -3440px;
}

.eva-card-body.INTAC, .eva-card-body[class*="INTAC-"] {
    background-position: center -3480px;
}

.eva-card-body.AMIGO, .eva-card-body[class*="AMIGO-"] {
    background-position: center -3520px;
}

.eva-card-body.CALIB, .eva-card-body[class*="CALIB-"] {
    background-position: center -3560px;
}

.eva-card-body.MUT, .eva-card-body[class*="MUT-"] {
    background-position: center -3600px;
}

.eva-card-body.BBVAC, .eva-card-body[class*="BBVAC-"] {
    background-position: center -3640px;
}

.eva-card-body.INB, .eva-card-body[class*="INB-"] {
    background-position: center -3680px;
}

.eva-card-body.BCP, .eva-card-body[class*="BCP-"] {
    background-position: center -3720px;
}

.eva-card-body.SPI, .eva-card-body[class*="SPI-"], .eva-card-body.SPE, .eva-card-body[class*="SPE-"] {
    background-position: center -3760px;
}

.eva-card-body.EFECTY, .eva-card-body[class*="EFECTY-"] {
    background-position: center -3800px;
}

.eva-card-body.VIA, .eva-card-body[class*="VIA-"], .eva-card-body.BALOTO, .eva-card-body[class*="BALOTO-"] {
    background-position: center -3840px;
}

.eva-card-body.BB, .eva-card-body[class*="BB-"] {
    background-position: center -3880px;
}

.eva-card-body.CAIXA, .eva-card-body[class*="CAIXA-"] {
    background-position: center -3920px;
}

.eva-card-body.BANXE, .eva-card-body[class*="BANXE-"] {
    background-position: center -3960px;
}

.eva-card-body.BRA, .eva-card-body[class*="BRA-"] {
    background-position: center -4000px;
}

.eva-card-body.BRAMX, .eva-card-body[class*="BRAMX-"] {
    background-position: center -4040px;
}

.eva-card-body.RI, .eva-card-body[class*="RI-"] {
    background-position: center -4080px;
}

.eva-card-body.SRIO, .eva-card-body[class*="SRIO-"], .eva-card-body.OB, .eva-card-body[class*="OB-"], .eva-card-body.OB-RIO, .eva-card-body[class*="OB-RIO-"] {
    background-position: center -4120px;
}

.eva-card-body.TNZ, .eva-card-body[class*="TNZ-"] {
    background-position: center -4160px;
}

.eva-card-body.BANRI, .eva-card-body[class*="BANRI-"] {
    background-position: center -4200px;
}

.eva-card-body.BBVA, .eva-card-body[class*="BBVA-"], .eva-card-body.BBVACO, .eva-card-body[class*="BBVACO-"] {
    background-position: center -4240px;
}

.eva-card-body.BCO, .eva-card-body[class*="BCO-"] {
    background-position: center -4280px;
}

.eva-card-body.CMR, .eva-card-body[class*="CMR-"], .eva-card-body.CMRP, .eva-card-body[class*="CMRP-"], .eva-card-body.CMRCL, .eva-card-body[class*="CMRCL-"], .eva-card-body.CMRAR, .eva-card-body[class*="CMRAR-"] {
    background-position: center -4320px;
}

.eva-card-body.PNET, .eva-card-body[class*="PNET-"] {
    background-position: center -4360px;
}

.eva-card-body.AZU, .eva-card-body[class*="AZU-"] {
    background-position: center -4400px;
}

.eva-card-body.CIU, .eva-card-body[class*="CIU-"] {
    background-position: center -4440px;
}

.eva-card-body.OH, .eva-card-body[class*="OH-"] {
    background-position: center -4480px;
}

.eva-card-body.BCI, .eva-card-body[class*="BCI-"] {
    background-position: center -4520px;
}

.eva-card-body.CIT, .eva-card-body[class*="CIT-"] {
    background-position: center -4560px;
}

.eva-card-body.COM, .eva-card-body[class*="COM-"] {
    background-position: center -4600px;
}

.eva-card-body.HIP, .eva-card-body[class*="HIP-"] {
    background-position: center -4640px;
}

.eva-card-body.BCHI, .eva-card-body[class*="BCHI-"] {
    background-position: center -4680px;
}

.eva-card-body.CHU, .eva-card-body[class*="CHU-"] {
    background-position: center -4720px;
}

.eva-card-body.PRO, .eva-card-body[class*="PRO-"], .eva-card-body.BP, .eva-card-body[class*="BP-"] {
    background-position: center -4760px;
}

.eva-card-body.MAC, .eva-card-body[class*="MAC-"] {
    background-position: center -4800px;
}

.eva-card-body.TUC, .eva-card-body[class*="TUC-"] {
    background-position: center -4840px;
}

.eva-card-body.CAB, .eva-card-body[class*="CAB-"], .eva-card-body.CABCO, .eva-card-body[class*="CABCO-"] {
    background-position: center -4880px;
}

.eva-card-body.NAC, .eva-card-body[class*="NAC-"] {
    background-position: center -4920px;
}

.eva-card-body.VIS, .eva-card-body[class*="VIS-"], .eva-card-body.VISPE, .eva-card-body[class*="VISPE-"] {
    background-position: center -4960px;
}

.eva-card-body.ROS, .eva-card-body[class*="ROS-"] {
    background-position: center -5000px;
}

.eva-card-body.CDC, .eva-card-body[class*="CDC-"] {
    background-position: center -5040px;
}

.eva-card-body.FRN, .eva-card-body[class*="FRN-"] {
    background-position: center -5080px;
}

.eva-card-body.NEU, .eva-card-body[class*="NEU-"] {
    background-position: center -5120px;
}

.eva-card-body.GRL, .eva-card-body[class*="GRL-"] {
    background-position: center -5160px;
}

.eva-card-body.SUP, .eva-card-body[class*="SUP-"] {
    background-position: center -5200px;
}

.eva-card-body.ARP, .eva-card-body[class*="ARP-"] {
    background-position: center -5240px;
}

.eva-card-body.SHO, .eva-card-body[class*="SHO-"] {
    background-position: center -5280px;
}

.eva-card-body.BEST, .eva-card-body[class*="BEST-"] {
    background-position: center -5320px;
}

.eva-card-body.PAT, .eva-card-body[class*="PAT-"] {
    background-position: center -5360px;
}

.eva-card-body.OC, .eva-card-body[class*="OC-"] {
    background-position: center -5400px;
}

.eva-card-body.PAC, .eva-card-body[class*="PAC-"] {
    background-position: center -5440px;
}

.eva-card-body.AUS, .eva-card-body[class*="AUS-"] {
    background-position: center -5480px;
}

.eva-card-body.GUA, .eva-card-body[class*="GUA-"] {
    background-position: center -5520px;
}

.eva-card-body.PIC, .eva-card-body[class*="PIC-"] {
    background-position: center -5560px;
}

.eva-card-body.NV, .eva-card-body[class*="NV-"] {
    background-position: center -5600px;
}

.eva-card-body.BBF, .eva-card-body[class*="BBF-"] {
    background-position: center -5640px;
}

.eva-card-body.BBVAW, .eva-card-body[class*="BBVAW-"] {
    background-position: center -5680px;
}

.eva-card-body.INTER, .eva-card-body[class*="INTER-"] {
    background-position: center -5720px;
}

.eva-card-body.NVP, .eva-card-body[class*="NVP-"] {
    background-position: center -5760px;
}

.eva-card-body.SEC, .eva-card-body[class*="SEC-"] {
    background-position: center -5800px;
}

.eva-card-body.SUPB, .eva-card-body[class*="SUPB-"] {
    background-position: center -5840px;
}

.eva-card-body.PROCR, .eva-card-body[class*="PROCR-"] {
    background-position: center -5880px;
}

.eva-card-body.VILL, .eva-card-body[class*="VILL-"] {
    background-position: center -5920px;
}

.eva-card-body.OCC, .eva-card-body[class*="OCC-"] {
    background-position: center -5960px;
}

.eva-card-body.AGR, .eva-card-body[class*="AGR-"] {
    background-position: center -6000px;
}

.eva-card-body.BCOO, .eva-card-body[class*="BCOO-"] {
    background-position: center -6040px;
}

.eva-card-body.CSOC, .eva-card-body[class*="CSOC-"] {
    background-position: center -6080px;
}

.eva-card-body.COLPA, .eva-card-body[class*="COLPA-"] {
    background-position: center -6120px;
}

.eva-card-body.COPC, .eva-card-body[class*="COPC-"] {
    background-position: center -6160px;
}

.eva-card-body.DAV, .eva-card-body[class*="DAV-"] {
    background-position: center -6200px;
}

.eva-card-body.GNB, .eva-card-body[class*="GNB-"] {
    background-position: center -6240px;
}

.eva-card-body.HELM, .eva-card-body[class*="HELM-"] {
    background-position: center -6280px;
}

.eva-card-body.POP, .eva-card-body[class*="POP-"] {
    background-position: center -6320px;
}

.eva-card-body.FALCO, .eva-card-body[class*="FALCO-"], .eva-card-body.FALPE, .eva-card-body[class*="FALPE-"], .eva-card-body.FALCL, .eva-card-body[class*="FALCL-"] {
    background-position: center -6360px;
}

.eva-card-body.PICCO, .eva-card-body[class*="PICCO-"] {
    background-position: center -6400px;
}

.eva-card-body.AMX, .eva-card-body[class*="AMX-"] {
    background-position: center -6440px;
}

.eva-card-body.BCOL, .eva-card-body[class*="BCOL-"] {
    background-position: center -6480px;
}

.eva-card-body.PSE, .eva-card-body[class*="PSE-"] {
    background-position: center -6520px;
}

.eva-card-body.SERV, .eva-card-body[class*="SERV-"] {
    background-position: center -6560px;
}

.eva-card-body.VEC, .eva-card-body[class*="VEC-"] {
    background-position: center -6600px;
}

.eva-card-body.WMTAC, .eva-card-body[class*="WMTAC-"] {
    background-position: center -6640px;
}

.eva-card-body.WMTL, .eva-card-body[class*="WMTL-"] {
    background-position: center -6680px;
}

.eva-card-body.WMTLE, .eva-card-body[class*="WMTLE-"] {
    background-position: center -6720px;
}

.eva-card-body.VIP, .eva-card-body[class*="VIP-"] {
    background-position: center -6760px;
}

.eva-card-body.GNBPE, .eva-card-body[class*="GNBPE-"] {
    background-position: center -6800px;
}

.eva-card-body.ORIG, .eva-card-body[class*="ORIG-"] {
    background-position: center -6840px;
}

.eva-card-body.RUM, .eva-card-body[class*="RUM-"] {
    background-position: center -6880px;
}

.eva-card-body.CD, .eva-card-body[class*="CD-"] {
    background-position: center -6920px;
}

.eva-card-body.ABCVI, .eva-card-body[class*="ABCVI-"] {
    background-position: center -6960px;
}

.eva-card-body.XCIT, .eva-card-body[class*="XCIT-"] {
    background-position: center -7000px;
}

.eva-card-body.PP, .eva-card-body[class*="PP-"] {
    background-position: center -7040px;
}

.eva-card-body.SFJO, .eva-card-body[class*="SFJO-"] {
    background-position: center -7080px;
}

.eva-card-body.CRES, .eva-card-body[class*="CRES-"] {
    background-position: center -7120px;
}

.eva-card-body.INF, .eva-card-body[class*="INF-"] {
    background-position: center -7160px;
}

.eva-card-body.POL, .eva-card-body[class*="POL-"] {
    background-position: center -7200px;
}

.eva-card-body.CSPE, .eva-card-body[class*="CSPE-"] {
    background-position: center -7240px;
}

.eva-card-body.TIT, .eva-card-body[class*="TIT-"] {
    background-position: center -7280px;
}

.eva-card-body.CHIC, .eva-card-body[class*="CHIC-"] {
    background-position: center -7320px;
}

.eva-card-body.COL, .eva-card-body[class*="COL-"] {
    background-position: center -7360px;
}

.eva-card-body.MACS, .eva-card-body[class*="MACS-"] {
    background-position: center -7400px;
}

.eva-card-body.BBVABP, .eva-card-body[class*="BBVABP-"] {
    background-position: center -7440px;
}

.eva-card-body.BOL, .eva-card-body[class*="BOL-"] {
    background-position: center -7480px;
}

.eva-card-body.PATM, .eva-card-body[class*="PATM-"] {
    background-position: center -7520px;
}

.eva-card-body.HSBCS, .eva-card-body[class*="HSBCS-"] {
    background-position: center -7560px;
}

.eva-card-body.CJP, .eva-card-body[class*="CJP-"] {
    background-position: center -7600px;
}

.eva-card-body.BON, .eva-card-body[class*="BON-"] {
    background-position: center -7640px;
}

.eva-card-body.MACH, .eva-card-body[class*="MACH-"] {
    background-position: center -7680px;
}

.eva-card-body.EXI, .eva-card-body[class*="EXI-"] {
    background-position: center -7720px;
}

.eva-card-body.CAU, .eva-card-body[class*="CAU-"] {
    background-position: center -7760px;
}

.eva-card-body.TO, .eva-card-body[class*="TO-"] {
    background-position: center -7800px;
}

.eva-card-body.PAN, .eva-card-body[class*="PAN-"] {
    background-position: center -7840px;
}

.eva-card-body.WIL, .eva-card-body[class*="WIL-"] {
    background-position: center -7880px;
}

.eva-card-body.COLPAB, .eva-card-body[class*="COLPAB-"] {
    background-position: center -7920px;
}

.eva-card-body.COLPAI, .eva-card-body[class*="COLPAI-"] {
    background-position: center -7960px;
}

.eva-card-body.COLPAS, .eva-card-body[class*="COLPAS-"] {
    background-position: center -8000px;
}

.eva-card-body.COLPAP, .eva-card-body[class*="COLPAP-"] {
    background-position: center -8040px;
}

.eva-card-body.PROM, .eva-card-body[class*="PROM-"], .eva-card-body.PROMP, .eva-card-body[class*="PROMP-"], .eva-card-body.PROMGT, .eva-card-body[class*="PROMGT-"], .eva-card-body.PROMHN, .eva-card-body[class*="PROMHN-"], .eva-card-body.PROMSV, .eva-card-body[class*="PROMSV-"] {
    background-position: center -8080px;
}

.eva-card-body.BTBP, .eva-card-body[class*="BTBP-"] {
    background-position: center -8120px;
}

.eva-card-body.BNCR, .eva-card-body[class*="BNCR-"] {
    background-position: center -8160px;
}

.eva-card-body.CRED, .eva-card-body[class*="CRED-"] {
    background-position: center -8200px;
}

.eva-card-body.SCOL, .eva-card-body[class*="SCOL-"], .eva-card-body.CITCO, .eva-card-body[class*="CITCO-"] {
    background-position: center -8240px;
}

.eva-card-body.STGEORGES, .eva-card-body[class*="STGEORGES-"] {
    background-position: center -8280px;
}

.eva-card-body.RICL, .eva-card-body[class*="RICL-"] {
    background-position: center -8320px;
}

.eva-card-body.BOGI, .eva-card-body[class*="BOGI-"] {
    background-position: center -8360px;
}

.eva-card-body.BOGG, .eva-card-body[class*="BOGG-"] {
    background-position: center -8400px;
}

.eva-card-body.BOGP, .eva-card-body[class*="BOGP-"] {
    background-position: center -8440px;
}

.eva-card-body.BOGS, .eva-card-body[class*="BOGS-"], .eva-card-body.BOGB, .eva-card-body[class*="BOGB-"] {
    background-position: center -8480px;
}

.eva-card-body.BANI, .eva-card-body[class*="BANI-"] {
    background-position: center -8520px;
}

.eva-card-body.HIPS, .eva-card-body[class*="HIPS-"] {
    background-position: center -8560px;
}

.eva-card-body.SURED, .eva-card-body[class*="SURED-"] {
    background-position: center -8600px;
}

.eva-card-body.ACERT, .eva-card-body[class*="ACERT-"] {
    background-position: center -8640px;
}

.eva-card-body.APCUCUTA, .eva-card-body[class*="APCUCUTA-"] {
    background-position: center -8680px;
}

.eva-card-body.APUNIDAS, .eva-card-body[class*="APUNIDAS-"] {
    background-position: center -8720px;
}

.eva-card-body.GANAG, .eva-card-body[class*="GANAG-"] {
    background-position: center -8760px;
}

.eva-card-body.GANAC, .eva-card-body[class*="GANAC-"] {
    background-position: center -8800px;
}

.eva-card-body.JER, .eva-card-body[class*="JER-"] {
    background-position: center -8840px;
}

.eva-card-body.PERLA, .eva-card-body[class*="PERLA-"] {
    background-position: center -8880px;
}

.eva-card-body.PAGAT, .eva-card-body[class*="PAGAT-"] {
    background-position: center -8920px;
}

.eva-card-body.SUCHANCE, .eva-card-body[class*="SUCHANCE-"] {
    background-position: center -8960px;
}

.eva-card-body.AMA, .eva-card-body[class*="AMA-"] {
    background-position: center -9000px;
}

.eva-card-body.LAF, .eva-card-body[class*="LAF-"] {
    background-position: center -9040px;
}

.eva-card-body.BGRL, .eva-card-body[class*="BGRL-"] {
    background-position: center -9080px;
}

.eva-card-body.CAP, .eva-card-body[class*="CAP-"] {
    background-position: center -9120px;
}

.eva-card-body.AXPLA, .eva-card-body[class*="AXPLA-"] {
    background-position: center -9160px;
}

.eva-card-body.WAL, .eva-card-body[class*="WAL-"] {
    background-position: center -9200px;
}

.eva-card-body.SBZ, .eva-card-body[class*="SBZ-"] {
    background-position: center -9240px;
}

.eva-card-body.SCOTCR, .eva-card-body[class*="SCOTCR-"] {
    background-position: center -9280px;
}

.eva-card-body.BBVAINF, .eva-card-body[class*="BBVAINF-"] {
    background-position: center -9320px;
}

.eva-card-body.CITPRE, .eva-card-body[class*="CITPRE-"] {
    background-position: center -9360px;
}

.eva-card-body.BBVAPLA, .eva-card-body[class*="BBVAPLA-"] {
    background-position: center -9400px;
}

.eva-card-body.BBVAIN, .eva-card-body[class*="BBVAIN-"] {
    background-position: center -9440px;
}

.eva-card-body.BANORINF, .eva-card-body[class*="BANORINF-"] {
    background-position: center -9480px;
}

.eva-card-body.SANBLA, .eva-card-body[class*="SANBLA-"] {
    background-position: center -9520px;
}

.eva-card-body.SANPLA, .eva-card-body[class*="SANPLA-"] {
    background-position: center -9560px;
}

.eva-card-body.SBPLA, .eva-card-body[class*="SBPLA-"] {
    background-position: center -9600px;
}

.eva-card-body.INVEXPLA, .eva-card-body[class*="INVEXPLA-"] {
    background-position: center -9640px;
}

.eva-card-body.COOPE, .eva-card-body[class*="COOPE-"] {
    background-position: center -9680px;
}

.eva-card-body.LIDER, .eva-card-body[class*="LIDER-"] {
    background-position: center -9720px;
}

.eva-card-body.BACPA, .eva-card-body[class*="BACPA-"], .eva-card-body.BACCR, .eva-card-body[class*="BACCR-"] {
    background-position: center -9760px;
}

.eva-card-body.NACPE, .eva-card-body[class*="NACPE-"] {
    background-position: center -9800px;
}

.eva-card-body.WUN, .eva-card-body[class*="WUN-"], .eva-card-body.WU, .eva-card-body[class*="WU-"] {
    background-position: center -9840px;
}

.eva-card-body.TBO, .eva-card-body[class*="TBO-"] {
    background-position: center -9880px;
}

.eva-card-body.FAL, .eva-card-body[class*="FAL-"], .eva-card-body.SAGA, .eva-card-body[class*="SAGA-"] {
    background-position: center -9920px;
}

.eva-card-body.SANDESP_WHITE, .eva-card-body[class*="SANDESP_WHITE-"] {
    background-position: center -9960px;
}

.eva-card-body.MIVE, .eva-card-body[class*="MIVE-"] {
    background-position: center -10000px;
}

.eva-card-body.PAG, .eva-card-body[class*="PAG-"] {
    background-position: center -10040px;
}

.eva-card-body.SIP, .eva-card-body[class*="SIP-"] {
    background-position: center -10080px;
}

.eva-card-body.CMRMX, .eva-card-body[class*="CMRMX-"] {
    background-position: center -10120px;
}

.eva-card-body.HSBCMX, .eva-card-body[class*="HSBCMX-"] {
    background-position: center -10160px;
}

.eva-card-body.BANC, .eva-card-body[class*="BANC-"] {
    background-position: center -10200px;
}

.eva-card-body.POPE, .eva-card-body[class*="POPE-"] {
    background-position: center -10240px;
}

.eva-card-body.FINANCED, .eva-card-body[class*="FINANCED-"] {
    background-position: center -10280px;
}

.eva-card-body.MDP_TRANSFER, .eva-card-body[class*="MDP_TRANSFER-"] {
    background-position: center -10320px;
}

.eva-card-body.MDP_DEPOSIT, .eva-card-body[class*="MDP_DEPOSIT-"] {
    background-position: center -10360px;
}

.eva-card-body.CMR_PUNTOS, .eva-card-body[class*="CMR_PUNTOS-"] {
    background-position: center -10400px;
}

.eva-card-body.CMRPTSPE, .eva-card-body[class*="CMRPTSPE-"], .eva-card-body.CMRPTSCO, .eva-card-body[class*="CMRPTSCO-"], .eva-card-body.CMRPTSCL, .eva-card-body[class*="CMRPTSCL-"], .eva-card-body.CMRPPM, .eva-card-body[class*="CMRPPM-"], .eva-card-body.VIAJAYA, .eva-card-body[class*="VIAJAYA-"] {
    background-position: center -10440px;
}

.eva-card-body.KON, .eva-card-body[class*="KON-"] {
    background-position: center -10480px;
}

.eva-card-body.ICBCDESP, .eva-card-body[class*="ICBCDESP-"] {
    background-position: center -10520px;
}

.eva-card-body.RBNK, .eva-card-body[class*="RBNK-"] {
    background-position: center -10560px;
}

.eva-card-body.BTBF, .eva-card-body[class*="BTBF-"] {
    background-position: center -10600px;
}

.eva-card-body.BXCP, .eva-card-body[class*="BXCP-"] {
    background-position: center -10640px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and ( min--moz-device-pixel-ratio: 1.5), only screen and ( -o-min-device-pixel-ratio: 1.5/1), only screen and ( min-device-pixel-ratio: 1.5), only screen and ( min-resolution: 192dpi), only screen and ( min-resolution: 2dppx) {
    .eva-card-body {
        background: rgba(255, 255, 255, 0.01);
        background-size: 65px auto;
    }
}

@media print {
    .eva-card-body {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }
}