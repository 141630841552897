.home-ui-carousel-offers .home-ui-carousel-offers-inner-container {
    overflow: hidden
}

.home-ui-carousel-offers .home-ui-carousel-offers-gradients {
    position: relative;
    display: flex
}

.home-ui-carousel-offers .home-ui-carousel-offers-gradients:before, .home-ui-carousel-offers .home-ui-carousel-offers-gradients:after {
    content: '';
    display: flex;
    position: absolute;
    z-index: 1
}

.home-ui-carousel-offers .home-ui-carousel-offers-gradients:before {
    background-image: linear-gradient(to right, #EEE, rgba(255, 255, 255, 0))
}

.home-ui-carousel-offers .home-ui-carousel-offers-gradients:after {
    background-image: linear-gradient(to left, #EEE, rgba(255, 255, 255, 0));
    right: 0
}

.home-ui-carousel-offers .home-ui-carousel-offers-wrapper {
    display: flex
}

.home-ui-carousel-offers .home-ui-carousel-offer {
    padding: 0;
    order: unset
}

.home-ui-carousel-offers .home-ui-card {
    height: 100%;
    max-width: unset
}

.home-ui-carousel-offers .home-ui-card .home-ui-card-content {
    flex-grow: 1
}

.home-ui-carousel-offers .home-ui-carousel-offers-controls-left-arrow, .home-ui-carousel-offers .home-ui-carousel-offers-controls-right-arrow {
    position: absolute;
    top: 49%;
    display: none
}

.home-ui-carousel-offers .home-ui-carousel-offers-controls-left-arrow.-show, .home-ui-carousel-offers .home-ui-carousel-offers-controls-right-arrow.-show {
    display: flex
}

.home-ui-carousel-offers .home-ui-carousel-offers-controls-left-arrow:before, .home-ui-carousel-offers .home-ui-carousel-offers-controls-right-arrow:before {
    content: '';
    position: absolute;
    height: 70px;
    width: 60px
}

.home-ui-carousel-offers .home-ui-carousel-offers-controls-left-arrow {
    left: -25px
}

.home-ui-carousel-offers .home-ui-carousel-offers-controls-left-arrow:before {
    left: 0
}

.home-ui-carousel-offers .home-ui-carousel-offers-controls-right-arrow {
    right: -25px
}

.home-ui-carousel-offers .home-ui-carousel-offers-controls-right-arrow:before {
    right: 0
}

.home-ui-carousel-offers .home-ui-carousel-offers-lead-text {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.2px;
    color: #666666;
    font-size: 12px;
    padding-bottom: 8px
}

.home-ui-carousel-offers .home-ui-carousel-offers-main-text {
    font-weight: 500;
    line-height: 1.13;
    letter-spacing: -0.7px;
    color: #444444
}

.home-ui-carousel-offers .home-ui-carousel-offers-main-text {
    color: #444444
}

.home-ui-carousel-offers .home-ui-carousel-offers-expand-text.-with-space:before {
    content: ' '
}

.home-ui-carousel-offers {
    padding-bottom: 50px
}

.home-ui-carousel-offers .home-ui-carousel-offers-title-container {
    max-width: 1130px;
    margin: 0 auto
}

.home-ui-carousel-offers .home-ui-carousel-offers-title {
    max-width: 75%
}

.home-ui-carousel-offers .home-ui-carousel-offers-container {
    max-width: 1130px;
    margin: 0 auto;
    position: relative
}

.home-ui-carousel-offers .home-ui-carousel-offers-wrapper {
    padding: 30px 0;
    transition: all .5s
}

.home-ui-carousel-offers .home-ui-carousel-offers-wrapper .home-ui-carousel-offer {
    flex: 0 0 269px;
    margin-left: 18px
}

.home-ui-carousel-offers .home-ui-carousel-offers-wrapper .home-ui-carousel-offer:first-of-type {
    margin-left: 0
}

.home-ui-carousel-offers .home-ui-carousel-offers-wrapper .home-ui-card {
    width: 269px
}

.home-ui-carousel-offers .home-ui-carousel-offers-main-text {
    font-size: 32px
}

.home-ui-carousel-offers .home-ui-carousel-offers-expand-text {
    font-size: 32px
}

.home-ui-carousel-offers .home-ui-carousel-offers-lead-text {
    padding-bottom: 12px
}

@media (min-width: 1366px) {
    .home-ui-carousel-offers .home-ui-carousel-offers-title-container {
        max-width: 1248px
    }

    .home-ui-carousel-offers .home-ui-carousel-offers-container {
        max-width: 1248px
    }

    .home-ui-carousel-offers .home-ui-carousel-offers-wrapper .home-ui-carousel-offer {
        flex: 0 0 297px;
        margin-left: 20px
    }

    .home-ui-carousel-offers .home-ui-carousel-offers-wrapper .home-ui-card {
        width: 297px
    }

    .home-ui-carousel-offers .home-ui-carousel-offers-controls-left-arrow {
        left: -30px
    }

    .home-ui-carousel-offers .home-ui-carousel-offers-controls-right-arrow {
        right: -30px
    }
}
