.page-bus-layout {
    margin: 0;
    font-family: Roboto, sans-serif, Arial, Helvetica;
    background: #fafafa
}

.page-bus-layout .gv-header {
    height: 70px
}

.page-bus-layout .date-controller-button {
    display: none
}

@media (min-width: 768px) {
    .page-bus-layout .date-controller-button {
        display: block
    }
}

@media (min-width: 768px) {
    .page-bus-layout .content {
        max-width: 1000px;
        margin: auto
    }
}

.page-bus-layout .ticket-routes {
    padding: 24px 8px 8px
}

.page-bus-layout .ticket-routes .ticket-routes-description {
    font-size: 18px;
    font-weight: 500;
    color: #757575
}

.page-bus-layout .ticket-routes .ticket-routes-paragraph {
    font-size: 12px;
    font-family: Roboto, sans-serif, Arial, Helvetica;
    margin-top: 4px;
    color: #9e9e9e
}

.page-bus-layout .about-text {
    line-height: 1.5;
    margin: 8px 0;
    color: #424242
}

.page-bus-layout .gv-promotional-taskbar {
    background-color: #2196f3;
    min-height: 67px;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, .2);
    box-shadow: 0 2px 3px rgba(0, 0, 0, .2)
}

.page-bus-layout .gv-promotional-taskbar-red {
    background-color: #b71c1c
}

.page-bus-layout .gv-promotional-taskbar-orange, .page-bus-layout .gv-promotional-taskbar-yellowx {
    background-color: #e65100
}

.page-bus-layout .gv-promotional-taskbar-gray {
    background-color: #212121
}

.page-bus-layout .gv-promotional-taskbar-light-green {
    background-color: #33691e
}

.page-bus-layout .gv-promotional-taskbar-dark-green {
    background-color: #1b5e20
}

.page-bus-layout .gv-promotional-taskbar-blue {
    background-color: #0d47a1
}

.page-bus-layout .gv-promotional-taskbar-pink {
    background-color: #880e4f
}

.page-bus-layout .gv-promotional-taskbar-purple {
    background-color: #4a148c
}

.page-bus-layout .gv-promotional-taskbar .gpt-logo-link {
    display: none
}

@media (min-width: 768px) {
    .page-bus-layout .gv-promotional-taskbar .gpt-logo-link {
        display: block
    }
}

.page-bus-layout .gv-promotional-taskbar .gpt-logo-link.logo_wl {
    padding: 0 8px 0 0
}

.page-bus-layout .gv-promotional-taskbar .header-taskbar-name {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 400;
    margin-left: 0;
    display: none
}

@media (min-width: 768px) {
    .page-bus-layout .gv-promotional-taskbar .header-taskbar-name {
        display: block;
        margin-left: 16px
    }
}

.page-bus-layout .gv-promotional-taskbar .lp-cht-left-container {
    width: 95%;
    width: -webkit-calc(100% - 12px);
    width: calc(100% - 12px)
}

.page-bus-layout .gv-promotional-taskbar .lp-cht-left-container .lp-cht-left-container-trigger {
    margin-right: 8px
}

.page-bus-layout .gv-promotional-taskbar .lp-cht-left-container .lp-cht-left-container-trigger .icon-test {
    font-size: 24px;
    text-align: center;
    text-indent: 6px;
    width: 24px;
    line-height: 1.3
}

.page-bus-layout .bus-layout__section {
    padding: 0
}

@media (min-width: 768px) {
    .page-bus-layout .bus-layout__section {
        padding: 24px 8px
    }
}

.page-bus-layout .header-taskbar-name__seat {
    position: fixed;
    top: 24px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    left: 50px;
    z-index: 35
}

.page-bus-layout .header-taskbar-name__seat .header-taskbar-name__desktop {
    display: none
}

@media (min-width: 768px) {
    .page-bus-layout .header-taskbar-name__seat .header-taskbar-name__desktop {
        display: inline-block
    }
}

@media (min-width: 768px) {
    .page-bus-layout .header-taskbar-name__seat {
        top: -25px;
        color: #757575;
        font-size: 16px;
        font-weight: 400;
        z-index: 25;
        position: relative;
        left: 0;
        max-width: 1000px;
        width: 100%
    }
}

.page-bus-layout .header-taskbar-name__seat.connection-seat {
    padding-right: 15px;
    top: 20px;
    width: 220px
}

@media (min-width: 768px) {
    .page-bus-layout .header-taskbar-name__seat.connection-seat {
        top: -25px;
        width: 100%
    }
}

.page-bus-layout .tickets-title {
    margin-top: 16px
}

.page-bus-layout .mdcard {
    width: 100%;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
    box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    padding: 16px 16px 32px;
    margin: 16px 0
}

@media (min-width: 768px) {
    .page-bus-layout .mdcard {
        max-width: 890px;
        margin: auto;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        height: 300px;
        padding: 24px 0 0
    }
}

.page-bus-layout .mdcard h2.mdcard__title {
    color: #757575;
    padding: 16px 0 8px;
    font-size: 18px
}

.page-bus-layout .mdcard p.mdct__text {
    color: #424242;
    font-size: 14px;
    line-height: 1.3;
    padding: 8px 0 16px
}

.page-bus-layout .mdcard .mdc-chip-set__container {
    padding: 16px 0
}

.page-bus-layout .mdcard .mdc-chip-set {
    display: inline-block;
    box-sizing: border-box;
    margin: 4px;
    padding: 1px
}

.page-bus-layout .mdcard .mdc-chip-set .mdc-chip.mdc-ripple-upgraded.mdc-chipset-sale {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    background: transparent;
    border: 1px solid #9e9e9e
}

.page-bus-layout .mdcard .mdc-chip-set .seats-input:checked + .mdc-chip.mdc-ripple-upgraded.mdc-chipset-sale {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    background: #ff9800;
    color: #fff;
    border: 1px solid #ff9800
}

.page-bus-layout .mdcard .mdc-chip-set .mdc-chip__text.mdc-sale {
    font-size: 20px;
    color: #757575;
    padding: 8px 13px
}

.page-bus-layout .mdcard .mdc-text-helper {
    color: #757575;
    margin-top: 0;
    font-size: 12px;
    padding: 0 0 0 12px;
    line-height: 1.5
}

.page-bus-layout .mdcard .mdc-text-field.mdc-text-field--outlined.mdcci__confirm {
    margin: 16px 0 8px
}

.page-bus-layout .chipset-container {
    margin-top: 72px
}

@media (min-width: 768px) {
    .page-bus-layout .chipset-container {
        margin-top: 40px;
        max-width: 1000px
    }
}

.page-bus-layout .chipset-container .chipset {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none
}

.page-bus-layout .chipset-container .chipset::-webkit-scrollbar {
    display: none
}

.page-bus-layout .chipset-container .chipset .chip:first-child {
    margin-left: 8px
}

.page-bus-layout .chipset-container .chipset .chip:last-child {
    margin-right: 8px
}

.page-bus-layout .chipset-container .chipset .chip {
    text-align: center;
    background: #eee;
    display: inline-block;
    padding: 4px 16px;
    margin: 0 2px;
    font-size: 14px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    border-radius: 16px;
    color: hsla(0, 0%, 45.9%, .9);
    font-weight: 500
}

.page-bus-layout .chipset-container .chipset .chip .chip__weekday {
    display: block;
    font-size: 12px
}

.page-bus-layout .chipset-container .chipset .chip-active {
    color: #2196f3;
    background: rgba(33, 150, 243, .22)
}

.page-bus-layout .floating-search-button {
    font-size: 14px;
    color: #fff;
    background-color: #ff9800;
    border: 1px solid #fafafa;
    height: 40px;
    padding: 0 24px;
    position: fixed;
    bottom: 16px;
    right: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    -ms-border-radius: 32px;
    -o-border-radius: 32px;
    border-radius: 32px;
    -webkit-justify-content: center;
    justify-content: center;
    z-index: 40
}

.page-bus-layout .floating-search-button span.icon-search {
    color: #fff;
    width: 8px;
    height: 8px;
    border: 2px solid #fff;
    border-radius: 100%;
    position: absolute;
    left: 16px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 16px
}

@media (min-width: 768px) {
    .page-bus-layout .search-ticket__card {
        display: -webkit-flex;
        display: flex
    }
}

.page-bus-layout .search-ticket__card .stc__route {
    display: -webkit-flex;
    display: flex;
    width: 100%
}

@media (min-width: 768px) {
    .page-bus-layout .search-ticket__card .stc__route {
        display: -webkit-flex;
        display: flex;
        width: 100%;
        flex-direction: row-reverse;
        padding: 16px 40px 0
    }
}

.page-bus-layout .search-ticket__card .stc__route .stcr__info {
    width: 100%
}

@media (min-width: 768px) {
    .page-bus-layout .search-ticket__card .stc__route .stcr__info {
        width: 100%;
        margin: 0 16px
    }
}

.page-bus-layout .search-ticket__card .stc__route .stcr__info .stcri__row {
    line-height: 1.5
}

.page-bus-layout .search-ticket__card .stc__route .stcr__trip-info {
    text-align: center
}

@media (min-width: 768px) {
    .page-bus-layout .search-ticket__card .stc__route .stcr__trip-info {
        width: 200px;
        margin: 0 16px;
        text-align: left
    }
}

.page-bus-layout .search-ticket__card .stc__route img.stcr--trip-info__logo {
    width: 88px;
    border: 1px solid #e0e0e0;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px
}

.page-bus-layout .search-ticket__card .stc__route span.stcr-ticket__text--small {
    display: block;
    margin-bottom: 4px;
    text-align: center;
    font-size: 12px;
    color: #757575
}

@media (min-width: 768px) {
    .page-bus-layout .search-ticket__card .stc__route span.stcr-ticket__text--small {
        margin-bottom: 8px;
        text-align: left
    }
}

.page-bus-layout .search-ticket__card .stc__route .stcr-ticket__text-freeSeats {
    font-size: 12px;
    margin-top: 4px;
    color: #757575;
    display: block;
    text-align: center
}

.page-bus-layout .search-ticket__card .stcr__price {
    position: fixed;
    top: 27px;
    z-index: 30;
    right: 20px;
    color: #fff
}

@media (min-width: 768px) {
    .page-bus-layout .search-ticket__card .stcr__price {
        position: relative;
        color: #424242;
        font-weight: 700;
        top: 0;
        right: 0;
        width: 300px;
        text-align: center;
        padding: 16px;
        z-index: 15
    }
}

.page-bus-layout .search-ticket__card .stcr__price .js-price {
    font-size: 16px
}

@media (min-width: 768px) {
    .page-bus-layout .search-ticket__card .stcr__price .js-price {
        font-size: 22px
    }
}

.page-bus-layout .search-ticket__card .stcr__price .js-price:after {
    content: "+ taxas";
    font-size: 10px;
    color: #fff;
    font-weight: 300;
    display: block;
    padding: 2px 0 0;
    margin-left: 36px
}

@media (min-width: 768px) {
    .page-bus-layout .search-ticket__card .stcr__price .js-price:after {
        font-size: 12px;
        color: #9e9e9e;
        font-weight: 400;
        display: block;
        padding: 8px 0 0;
        margin-left: 58px
    }
}

.page-bus-layout .search-ticket__card .stcr__price .js-price:empty {
    display: none
}

.page-bus-layout .search-ticket__card .stcr__price .js-price-full {
    font-size: 16px
}

@media (min-width: 768px) {
    .page-bus-layout .search-ticket__card .stcr__price .js-price-full {
        font-size: 22px
    }
}

.page-bus-layout .search-ticket__card .stcr__price .js-price-full:after {
    content: "com taxas inclusas";
    font-size: 10px;
    color: #fff;
    font-weight: 300;
    display: block;
    padding: 2px 0 0
}

@media (min-width: 768px) {
    .page-bus-layout .search-ticket__card .stcr__price .js-price-full:after {
        font-size: 12px;
        color: #9e9e9e;
        font-weight: 400;
        display: block;
        padding: 8px 0 0
    }
}

.page-bus-layout .search-ticket__card .stcr__price .js-price-full:empty {
    display: none
}

.page-bus-layout .search-ticket__card .stcr__date {
    text-align: center;
    font-size: 11px;
    color: #757575;
    padding: 4px 0 16px
}

@media (min-width: 768px) {
    .page-bus-layout .search-ticket__card .stcr__date {
        display: none
    }
}

.page-bus-layout .gv-box {
    padding: 16px
}

@media (min-width: 768px) {
    .page-bus-layout .gv-box {
        padding: 0 0 16px;
        max-width: 1000px;
        margin: auto;
        height: auto
    }
}

@media (min-width: 768px) {
    .page-bus-layout .gv-box .gvb-content {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        margin-top: -16px;
        padding: 0
    }
}

.page-bus-layout .gv-box__trip-info {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center
}

@media (min-width: 768px) {
    .page-bus-layout .gv-box__trip-info {
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: flex-start;
        align-items: flex-start;
        -webkit-flex: 0.8;
        flex: 0.8
    }
}

.page-bus-layout .gv-box__trip-info__free-seats {
    font-size: 12px;
    color: #ff5252;
    display: none;
    -webkit-align-items: center;
    align-items: center;
    margin: 0 0 0 auto
}

@media (min-width: 768px) {
    .page-bus-layout .gv-box__trip-info__free-seats {
        order: 3;
        margin: 8px auto 0 0;
        display: block
    }

    .page-bus-layout .gv-box__trip-info__free-seats:before {
        margin: 0
    }
}

.page-bus-layout .gv-box__trip-info__free-seats_free {
    display: none
}

@media (min-width: 768px) {
    .page-bus-layout .gv-box__trip-info__free-seats_free {
        display: block;
        font-size: 12px;
        color: #757575;
        margin-top: 6px
    }
}

.page-bus-layout .gv-box.box_custom {
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, .24);
    -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, .24);
    box-shadow: 0 3px 3px rgba(0, 0, 0, .24)
}

.page-bus-layout .gv-search-result-route {
    width: 100%;
    position: relative;
    margin: 0 16px 16px 8px
}

.page-bus-layout .gv-search-result-route-direct li.srr-simples-rota-item.srr-sri-match {
    padding-bottom: 8px
}

@media (min-width: 768px) {
    .page-bus-layout .gv-search-result-route-direct li.srr-simples-rota-item.srr-sri-match span.srr-sri__desktop {
        font-size: 18px;
        font-weight: 700
    }
}

.page-bus-layout .gv-search-result-route-direct.srr-sri-scale {
    padding-bottom: 16px
}

.page-bus-layout .gv-search-result-route-direct.srr-sri-scale:before {
    content: "trip_origin";
    font-size: 12px;
    left: -7px;
    top: 4px
}

.page-bus-layout .gv-search-result-route-direct.srr-sri-scale .srr-sri-scale-text {
    font-size: 12px;
    position: relative;
    top: -8px;
    color: #757575
}

.page-bus-layout .srtc-footer {
    background-color: #fff;
    position: fixed;
    z-index: 25;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px;
    height: 80px;
    -webkit-box-shadow: 0 1px 4px #424242;
    -moz-box-shadow: 0 1px 4px #424242;
    box-shadow: 0 1px 4px #424242
}

.page-bus-layout .srtc-footer .srtc-container {
    width: 100%
}

@media (min-width: 768px) {
    .page-bus-layout .srtc-footer .srtc-container {
        display: -webkit-flex;
        display: flex;
        max-width: 1000px;
        margin: auto
    }
}

.page-bus-layout .srtc-footer .srtc-container .srtcf-item {
    width: 100%
}

@media (min-width: 768px) {
    .page-bus-layout .srtc-footer .srtc-container .srtcf-item {
        width: 70%;
        margin: auto;
        display: -webkit-flex;
        display: flex
    }
}

.page-bus-layout .srtc-footer .srtc-container .srtcf-item .srtcf-item_info {
    display: none
}

@media (min-width: 768px) {
    .page-bus-layout .srtc-footer .srtc-container .srtcf-item .srtcf-item_info {
        display: inline-block;
        width: 210px
    }
}

.page-bus-layout .srtc-footer .srtc-container .srtcf-item .srtcf-item_info .srtcf-item__text {
    color: #757575
}

.page-bus-layout .srtc-footer .srtc-container .srtcf-item .srtcf-item_info .srtcf-item__text .main {
    font-size: 20px;
    margin-top: 4px
}

.page-bus-layout .srtc-footer .srtc-container .srtcf-item .srtcf-item_info .js-seats-list {
    font-size: 16px
}

@media (min-width: 768px) {
    .page-bus-layout .srtc-footer .srtc-container .srtcf-item .srtcf-item_info .js-seats-list {
        font-size: 18px
    }
}

@media (min-width: 768px) {
    .page-bus-layout .srtc-footer .srtc-container .srtcf-item .srtcf-item_info .js-seats-list:before {
        content: "Poltronas selecionadas";
        font-size: 16px;
        font-weight: 500;
        color: #757575;
        display: block;
        margin-bottom: 4px
    }
}

.page-bus-layout .srtc-footer .srtc-container .srtcf-item .srtcf-item_info .js-seats-list:empty {
    display: none
}

.page-bus-layout .srtc-footer .gv-button-secondary {
    height: 48px
}

@media (min-width: 768px) {
    .page-bus-layout .srtc-footer .gv-button-full {
        width: 252px
    }
}

.page-bus-layout .mdc-tab-bar {
    width: 100%;
    margin: 16px auto
}

@media (min-width: 768px) {
    .page-bus-layout .mdc-tab-bar {
        width: 100%;
        margin: -14px auto 40px
    }
}

.page-bus-layout .mdc-tab-bar .mdc-tab-scroller {
    overflow-y: hidden
}

@media (min-width: 768px) {
    .page-bus-layout .mdc-tab-bar .mdc-tab-scroller__scroll-area mdc-tab-scroller__scroll-area--scroll {
        margin-bottom: 0
    }
}

.page-bus-layout .mdc-dialog {
    z-index: 55
}

.page-bus-layout .js-floor {
    display: none
}

.page-bus-layout .js-floor-active {
    display: block
}

@media (min-width: 768px) {
    .page-bus-layout .js-floor-active {
        display: block;
        margin-top: -20px;
        margin-bottom: 20px
    }
}

.page-bus-layout .mdc-dialog__emailask {
    width: 95%;
    width: -webkit-calc(100% - 16px);
    width: calc(100% - 16px)
}

@media (min-width: 768px) {
    .page-bus-layout .mdc-dialog__emailask {
        max-width: 360px
    }
}

.page-bus-layout .gvb-subtitle {
    margin: 32px 32px 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
    justify-content: space-around
}

@media (min-width: 768px) {
    .page-bus-layout .gvb-subtitle.mobile {
        display: none
    }
}

.page-bus-layout .gvb-subtitle.desk {
    display: none
}

@media (min-width: 768px) {
    .page-bus-layout .gvb-subtitle.desk {
        display: -webkit-flex;
        display: flex
    }
}

@media (min-width: 768px) {
    .page-bus-layout .gvb-subtitle {
        margin: auto;
        width: 50%
    }
}

.page-bus-layout .gvb-subtitle .gvbs_item_direction {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center
}

.page-bus-layout .gvb-subtitle .gvbs_item_direction .gvbsi_seat {
    width: 24px;
    height: 24px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    display: block;
    margin: 8px
}

.page-bus-layout .gvb-subtitle .gvbs_item_direction .gvbsi_seat.free {
    color: #757575;
    border: 1px solid
}

.page-bus-layout .gvb-subtitle .gvbs_item_direction .gvbsi_seat.selected {
    background-color: #ff9800
}

.page-bus-layout .gvb-subtitle .gvbs_item_direction .gvbsi_seat.busy {
    background-color: #eee
}

.page-bus-layout .gvb-subtitle .gvbs_item_direction .gvbsi_seat.busy .busy_close {
    font-size: 16px;
    padding: 4px;
    color: #757575
}

.page-bus-layout .gvb-subtitle .gvbs_item_direction .gvbsif__text {
    font-size: 12px;
    color: #757575
}

.page-bus-layout .breadcrumb-section {
    margin: 0 0 16px
}

.mdc-text-field {
    width: 100%;
    margin-top: 32px
}

.mdc-text-field.mdc-text-field--outlined.mdc-text-field-custom {
    margin-top: 32px;
    margin-bottom: 0
}

.gv-button:disabled {
    background-color: rgba(0, 0, 0, .12);
    color: #757575;
    cursor: default;
    pointer-events: none
}

@media (min-width: 768px) {
    .mdc-button__details {
        display: none
    }
}

.srdp-container-header {
    font-size: 12px;
    color: #757575;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 16px 8px;
    display: none
}

@media (min-width: 768px) {
    .srdp-container-header {
        border: none;
        padding: 0;
        height: 16px;
        width: 100%;
        position: relative;
        display: block;
        margin-top: 0
    }

    .srdp-container-header:after, .srdp-container-header:before {
        background-color: #fafafa;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
        border-radius: 50px;
        width: 16px;
        height: 16px;
        top: 0;
        position: absolute;
        content: ""
    }

    .srdp-container-header:before {
        -webkit-box-shadow: -3px 0 4px -4px inset;
        -moz-box-shadow: -3px 0 4px -4px inset;
        box-shadow: inset -3px 0 4px -4px;
        left: -8px
    }

    .srdp-container-header:after {
        box-shadow: inset 3px 0 4px -4px;
        right: -8px
    }
}

@media (min-width: 768px) {
    .srdp-container-header.connection {
        margin-top: 20px
    }
}

@-webkit-keyframes autofill {
    to {
        background: transparent
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both
}

.transport-result-wrapper {
    margin: 10px ;
    padding: 0 0 0px;
    background: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    height: auto
}

@media (min-width: 768px) {
    .transport-result-wrapper {
        padding: 0
    }
}

@media (min-width: 768px) {
    .transport-result-wrapper-connection {
        padding: 0 0 0px
    }
}

.transport-result-wrapper.transport-result-wrapper__auto {
    height: auto
}

@media (max-width: 359px) {
    .transport.bus-design.bus-large {
        zoom: .7;
        -ms-zoom: .7;
        -webkit-zoom: .7;
        -moz-transform: scale(.7)
    }
}

@media (max-width: 359px) {
    .transport.bus-design.bus-extra-large {
        zoom: .54;
        -ms-zoom: .54;
        -webkit-zoom: .54;
        -moz-transform: scale(.54)
    }
}

.transport.bus-design.bus-design-side {
    width: 243px;
    padding: 0 0 64px
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-side {
        width: 206px;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        height: 270px;
        margin-right: 360px;
        padding: 0
    }
}

.transport.bus-design.bus-design-side .transport-frente {
    width: 100%;
    height: 80px;
    position: relative;
    bottom: -3px;
    background-color: #fff;
    display: -moz-inline-stack;
    display: inline-block;
    background: #fff url('./../img/veiculo/bus-frente.svg') no-repeat;
}

/*.transport.bus-design.bus-design-side .transport-frente:before {
    background: #fff url('./../img/veiculo/frente-esquerda.png') no-repeat;
    left: 12px
}

.transport.bus-design.bus-design-side .transport-frente:after {
    background: #fff url('./../img/veiculo/frente_direita.png') no-repeat;
    right: 12px
}*/



@media (min-width: 768px) {
    .transport.bus-design.bus-design-side .transport-frente:after, .transport.bus-design.bus-design-side .transport-frente:before {
        background-color: #fff
    }
}

.transport.bus-design.bus-design-side .transport-meio {
    min-height: 400px;
    background-color: #fff;
    border: solid #f5f5f5;
    border-width: 0 1.5px;
    box-sizing: border-box;
    padding: 16px 0;
    display: flex;
    flex-direction: row-reverse
}

.transport.bus-design.bus-design-side .transport-meio-fileira {
    width: 40px;
    height: auto;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    margin-right: 8px
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-side .transport-meio-fileira {
        width: 32px
    }
}

.transport.bus-design.bus-design-side .transport-meio-fileira input {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    cursor: pointer
}

.transport.bus-design.bus-design-side .transport-meio-fileira label {
    font-family: Roboto, sans-serif, Arial, Helvetica;
    font-size: 20px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    color: #000;
    padding: 8px 0;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    margin-bottom: 8px;
    position: relative;
    line-height: 1.1
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-side .transport-meio-fileira label {
        font-size: 16px;
        width: 32px;
        height: 32px;
        padding: 7px 0;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg)
    }
}

.transport.bus-design.bus-design-side .transport-meio-fileira label.assento-disponivel {
    border: 1px solid #757575;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    font-family: Roboto, sans-serif, Arial, Helvetica;
    color: #757575;
    transition: background-color .5s;
    -webkit-transition: background-color .5s
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-side .transport-meio-fileira label.assento-disponivel {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px
    }
}

.transport.bus-design.bus-design-side .transport-meio-fileira label.assento-ocupado {
    color: #9e9e9e;
    background-color: #eee;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    cursor: default !important;
    width: 40px
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-side .transport-meio-fileira label.assento-ocupado {
        width: 32px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px
    }
}

.transport.bus-design.bus-design-side .transport-meio-fileira label.assento-ocupado .assento-icon-close {
    color: #757575
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-side .transport-meio-fileira label.assento-ocupado .assento-icon-close {
        font-size: 20px
    }
}

.transport.bus-design.bus-design-side .transport-meio-fileira label.assento-selecionado {
    color: #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    background-color: #ff9800;
    border: 1px solid #ff9800;
    transition: background-color .02s;
    -webkit-transition: background-color .02s
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-side .transport-meio-fileira label.assento-selecionado {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px
    }
}

.transport.bus-design.bus-design-side .transport-meio-fileira label.espaco-vago {
    background: none;
    cursor: default;
    pointer-events: none;
    color: #fff;
    user-select: none
}

.transport.bus-design.bus-design-side .transport-meio-fileira:first-child, .transport.bus-design.bus-design-side .transport-meio-fileira:last-child {
    margin-right: 8px
}

.transport.bus-design.bus-design-side .transport-meio-corredor {
    width: 100%;
    height: 20px;
    width: 21px;
    height: 100%;
    display: -moz-inline-stack;
    display: inline-block
}

.transport.bus-design.bus-design-side .transport-meio-corredor:last-child {
    display: none
}

.transport.bus-design.bus-design-side .transport-meio-thin .transport-meio-corredor {
    height: auto;
    width: 40px
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-side .transport-meio-thin .transport-meio-corredor {
        width: 37px
    }
}

.transport.bus-design.bus-design-side .transport-traseira {
    width: 100%;
    height: 15px;
    position: relative;
    background-color: #f5f5f5;
    display: -moz-inline-stack;
    display: inline-block;
    bottom: 6px;
    -webkit-border-radius: 0 0 16px 16px;
    -moz-border-radius: 0 0 16px 16px;
    -ms-border-radius: 0 0 16px 16px;
    -o-border-radius: 0 0 16px 16px;
    border-radius: 0 0 16px 16px;
}



@media (min-width: 768px) {
    .transport.bus-design.bus-design-side .transport-traseira:after, .transport.bus-design.bus-design-side .transport-traseira:before {
        background-color: transparent
    }
}

.transport.bus-design.bus-design-up {
    width: 260px;
    margin: auto;
    padding: 32px 0 64px
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-up {
        display: inline-block;
        left: 0;
        position: relative;
        width: 230px;
        top: -10px
    }
}

.transport.bus-design.bus-design-up .transport-frente {
    width: 100%;
    height: 80px;
    position: relative;
    bottom: -3px;
    background-color: #fff;
    display: -moz-inline-stack;
    display: inline-block;
    -webkit-box-shadow: 0 11px 0 #9e9e9e inset, 0 27px 0 #becbcf inset;
    -moz-box-shadow: 0 11px 0 #9e9e9e inset, 0 27px 0 #becbcf inset;
    box-shadow: inset 0 11px 0 #9e9e9e, inset 0 27px 0 #becbcf
}

.transport.bus-design.bus-design-up .transport-frente:before {
    background: #fff url('./../img/veiculo/frente_direita.png') no-repeat;
    left: 12px
}

.transport.bus-design.bus-design-up .transport-frente:after {
    background: #fff url('./../img/veiculo/frente-esquerda.png') no-repeat;
    right: 12px
}

.transport.bus-design.bus-design-up .transport-frente:after, .transport.bus-design.bus-design-up .transport-frente:before {
    width: 80px;
    height: 104px;
    position: absolute;
    content: "";
    display: block;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    top: -12px
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-up .transport-frente:after, .transport.bus-design.bus-design-up .transport-frente:before {
        background-color: #fff
    }
}

.transport.bus-design.bus-design-up .transport-meio {
    min-height: 400px;
    background-color: #fff;
    border: solid #9e9e9e;
    border-width: 0 1.5px;
    box-sizing: border-box;
    padding: 16px 0
}

.transport.bus-design.bus-design-up .transport-meio-fileira {
    width: 40px;
    height: auto;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    margin-left: 8px
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-up .transport-meio-fileira {
        width: 32px
    }
}

.transport.bus-design.bus-design-up .transport-meio-fileira input {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    cursor: pointer
}

.transport.bus-design.bus-design-up .transport-meio-fileira label {
    font-family: Roboto, sans-serif, Arial, Helvetica;
    font-size: 20px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    color: #000;
    padding: 8px 0;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    margin-bottom: 8px;
    position: relative;
    line-height: 1.1
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-up .transport-meio-fileira label {
        font-size: 16px;
        width: 32px;
        height: 32px;
        padding: 7px 0
    }
}

.transport.bus-design.bus-design-up .transport-meio-fileira label.assento-disponivel {
    border: 1px solid #757575;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    font-family: Roboto, sans-serif, Arial, Helvetica;
    color: #757575;
    transition: background-color .5s;
    -webkit-transition: background-color .5s
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-up .transport-meio-fileira label.assento-disponivel {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px
    }
}

.transport.bus-design.bus-design-up .transport-meio-fileira label.assento-ocupado {
    color: #9e9e9e;
    background-color: #eee;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    cursor: default !important;
    width: 40px
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-up .transport-meio-fileira label.assento-ocupado {
        width: 32px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px
    }
}

.transport.bus-design.bus-design-up .transport-meio-fileira label.assento-ocupado .assento-icon-close {
    color: #757575
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-up .transport-meio-fileira label.assento-ocupado .assento-icon-close {
        font-size: 20px
    }
}

.transport.bus-design.bus-design-up .transport-meio-fileira label.assento-selecionado {
    color: #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    background-color: #ff9800;
    border: 1px solid #ff9800;
    transition: background-color .02s;
    -webkit-transition: background-color .02s
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-up .transport-meio-fileira label.assento-selecionado {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px
    }
}

.transport.bus-design.bus-design-up .transport-meio-fileira label.espaco-vago {
    background: none;
    cursor: default;
    pointer-events: none;
    color: #fff;
    user-select: none
}

.transport.bus-design.bus-design-up .transport-meio-fileira:first-child, .transport.bus-design.bus-design-up .transport-meio-fileira:last-child {
    margin-left: 8px
}

.transport.bus-design.bus-design-up .transport-meio-corredor {
    width: 100%;
    height: 20px;
    width: 21px;
    height: 100%;
    display: -moz-inline-stack;
    display: inline-block
}

.transport.bus-design.bus-design-up .transport-meio-corredor:last-child {
    display: none
}

.transport.bus-design.bus-design-up .transport-meio-thin .transport-meio-corredor {
    height: auto;
    width: 40px
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-up .transport-meio-thin .transport-meio-corredor {
        width: 32px
    }
}

.transport.bus-design.bus-design-up .transport-traseira {
    width: 100%;
    height: 15px;
    position: relative;
    background-color: #9e9e9e;
    display: -moz-inline-stack;
    display: inline-block;
    bottom: 6px;
    -webkit-border-radius: 0 0 16px 16px;
    -moz-border-radius: 0 0 16px 16px;
    -ms-border-radius: 0 0 16px 16px;
    -o-border-radius: 0 0 16px 16px;
    border-radius: 0 0 16px 16px
}

.transport.bus-design.bus-design-up .transport-traseira:before {
    left: 3px;
    background: transparent url('./../img/veiculo/atras_direita.png') no-repeat
}

.transport.bus-design.bus-design-up .transport-traseira:after {
    background: transparent url('./../img/veiculo/atras_esquerda.png') no-repeat;
    right: 40px
}

.transport.bus-design.bus-design-up .transport-traseira:after, .transport.bus-design.bus-design-up .transport-traseira:before {
    width: 70px;
    height: 150px;
    display: block;
    content: "";
    position: absolute;
    top: -39px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg)
}

@media (min-width: 768px) {
    .transport.bus-design.bus-design-up .transport-traseira:after, .transport.bus-design.bus-design-up .transport-traseira:before {
        background-color: transparent
    }
}

@media (max-width: 359px) {
    .transport.train-design.train-large {
        zoom: .7;
        -ms-zoom: .7;
        -webkit-zoom: .7;
        -moz-transform: scale(.7)
    }
}

@media (max-width: 359px) {
    .transport.train-design.train-extra-large {
        zoom: .54;
        -ms-zoom: .54;
        -webkit-zoom: .54;
        -moz-transform: scale(.54)
    }
}

.transport.train-design.train-design-up {
    width: 240px;
    margin: auto;
    padding: 16px 0
}

@media (min-width: 768px) {
    .transport.train-design.train-design-up {
        position: relative;
        left: 0;
        top: -20px;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        width: 204px;
        height: 270px;
        margin-right: 228px
    }
}

.transport.train-design.train-design-up .transport-frente {
    width: 100%;
    height: 8px;
    position: relative;
    top: 4px;
    display: -moz-inline-stack;
    display: inline-block;
    background-color: #9e9e9e;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    -o-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0
}

.transport.train-design.train-design-up .transport-frente:after {
    background: #9e9e9e;
    width: 40px;
    height: 8px;
    position: absolute;
    bottom: 8px;
    left: 40%;
    left: -webkit-calc(50% - 20px);
    left: calc(50% - 20px);
    content: ""
}

.transport.train-design.train-design-up .transport-meio {
    min-height: 400px;
    background-color: #fff;
    border: solid #9e9e9e;
    border-width: 0 1px;
    box-sizing: border-box;
    padding: 16px 0;
    display: flex;
    flex-direction: row-reverse
}

.transport.train-design.train-design-up .transport-meio-fileira {
    width: 40px;
    height: auto;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    margin-right: 8px
}

@media (min-width: 768px) {
    .transport.train-design.train-design-up .transport-meio-fileira {
        width: 34px
    }
}

.transport.train-design.train-design-up .transport-meio-fileira input {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    cursor: pointer
}

.transport.train-design.train-design-up .transport-meio-fileira label {
    font-family: Roboto, sans-serif, Arial, Helvetica;
    font-size: 16px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    color: #000;
    padding: 12px 4px;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    display: block;
    text-align: center;
    margin-bottom: 8px;
    position: relative
}

@media (min-width: 768px) {
    .transport.train-design.train-design-up .transport-meio-fileira label {
        height: 34px;
        padding: 10px 4px;
        font-size: 14px
    }
}

.transport.train-design.train-design-up .transport-meio-fileira label.assento-disponivel {
    border: 1px solid #757575;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    font-family: Roboto, sans-serif, Arial, Helvetica;
    color: #757575;
    transition: background-color .5s;
    -webkit-transition: background-color .5s
}

@media (min-width: 768px) {
    .transport.train-design.train-design-up .transport-meio-fileira label.assento-disponivel {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px;
        margin-bottom: 8px
    }
}

.transport.train-design.train-design-up .transport-meio-fileira label.assento-ocupado {
    color: #9e9e9e;
    background-color: #eee;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    cursor: default !important;
    width: 40px;
    margin-bottom: 8px
}

@media (min-width: 768px) {
    .transport.train-design.train-design-up .transport-meio-fileira label.assento-ocupado {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px;
        width: 34px;
        margin-bottom: 8px
    }
}

.transport.train-design.train-design-up .transport-meio-fileira label i.material-icons.assento-icon-close {
    position: relative;
    top: -2px;
    font-size: 19px
}

.transport.train-design.train-design-up .transport-meio-fileira label.assento-selecionado {
    color: #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    background-color: #ff9800;
    border: 1px solid #ff9800;
    transition: background-color .02s;
    -webkit-transition: background-color .02s
}

@media (min-width: 768px) {
    .transport.train-design.train-design-up .transport-meio-fileira label.assento-selecionado {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px
    }
}

.transport.train-design.train-design-up .transport-meio-fileira label.espaco-vago {
    background: none;
    cursor: default;
    pointer-events: none;
    color: #fff;
    user-select: none
}

.transport.train-design.train-design-up .transport-meio-corredor {
    width: 100%;
    height: 40px;
    width: 40px;
    height: 100%;
    display: -moz-inline-stack;
    display: inline-block
}

@media (min-width: 768px) {
    .transport.train-design.train-design-up .transport-meio-corredor {
        width: 26px;
        height: 20px
    }
}

.transport.train-design.train-design-up .transport-meio-corredor:last-child {
    display: none
}

.transport.train-design.train-design-up .transport-meio-thin .transport-meio-corredor {
    height: 32px
}

.transport.train-design.train-design-up .transport-traseira {
    width: 100%;
    height: 9px;
    position: relative;
    background-color: #9e9e9e;
    display: -moz-inline-stack;
    display: inline-block;
    bottom: 4px
}

.transport.train-design.train-design-up .transport-traseira:after {
    background: #9e9e9e;
    width: 40px;
    height: 8px;
    position: absolute;
    top: 8px;
    left: 40%;
    left: -webkit-calc(50% - 20px);
    left: calc(50% - 20px);
    content: ""
}

.transport.boat-design.boat-large {
    width: 460px !important
}

@media (max-width: 768px) {
    .transport.boat-design.boat-large {
        zoom: .7;
        -ms-zoom: .7;
        -webkit-zoom: .7;
        -moz-transform: scale(.7)
    }
}

@media (min-width: 768px) {
    .transport.boat-design.boat-large {
        width: 420px !important;
        left: 38px !important
    }
}

.transport.boat-design.boat-extra-large {
    width: 480px !important;
    left: -40px !important;
    zoom: .7;
    -ms-zoom: .7;
    -webkit-zoom: .7;
    -moz-transform: scale(.7)
}

@media (min-width: 768px) {
    .transport.boat-design.boat-extra-large {
        zoom: 0;
        -ms-zoom: 0;
        -webkit-zoom: 0;
        -moz-transform: scale(0)
    }
}

.transport.boat-design.boat-design-up {
    width: 330px;
    position: relative
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-up {
        width: 306px;
        position: relative;
        top: -3px;
        padding: 0 0 32px
    }
}

.transport.boat-design.boat-design-up .transport-frente {
    display: inline-block;
    width: 100%;
    height: 50px;
    position: relative;
    bottom: -3px;
    border-top: 3px solid #cfd8dc;
    background-color: #eceff1;
    box-shadow: inset 0 4px 0 #eceff1, inset 0 5px 0 #cfd8dc
}


@media (min-width: 768px) {
    .transport.boat-design.boat-design-up .transport-frente:before {
        background-color: #fff
    }
}



@media (min-width: 768px) {
    .transport.boat-design.boat-design-up .transport-frente:after {
        background-color: #fff
    }
}

.transport.boat-design.boat-design-up .transport-meio {
    background-color: #fff;
    border: solid #cfd8dc;
    border-width: 0 5px;
    box-sizing: border-box;
    padding: 6px 5px;
    min-height: 400px;
    max-width: 100%;
    min-width: 200px
}

.transport.boat-design.boat-design-up .transport-meio-fileira {
    width: 34px;
    height: auto;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    margin-left: 8px
}

.transport.boat-design.boat-design-up .transport-meio-fileira input {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    cursor: pointer
}

.transport.boat-design.boat-design-up .transport-meio-fileira label {
    font-family: Roboto, sans-serif, Arial, Helvetica;
    font-size: 14px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    color: #000;
    padding: 10px 5px;
    box-sizing: border-box;
    width: 100%;
    height: 34px;
    display: block;
    text-align: center;
    margin-bottom: 8px;
    position: relative
}

.transport.boat-design.boat-design-up .transport-meio-fileira label.assento-disponivel {
    border: 1px solid #757575;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    font-family: Roboto, sans-serif, Arial, Helvetica;
    color: #757575;
    transition: background-color .5s;
    -webkit-transition: background-color .5s;
    margin-bottom: 8px
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-up .transport-meio-fileira label.assento-disponivel {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px;
        padding: 10px 5px
    }
}

.transport.boat-design.boat-design-up .transport-meio-fileira label.assento-ocupado {
    color: #9e9e9e;
    background-color: #eee;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    cursor: default !important;
    width: 34px
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-up .transport-meio-fileira label.assento-ocupado {
        width: 34px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px;
        margin-bottom: 8px
    }
}

.transport.boat-design.boat-design-up .transport-meio-fileira label.assento-ocupado i.material-icons.assento-icon-close {
    font-size: 18px;
    position: relative;
    top: -2px
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-up .transport-meio-fileira label.assento-ocupado i.material-icons.assento-icon-close {
        font-size: 20px;
        position: relative;
        top: -3px
    }
}

.transport.boat-design.boat-design-up .transport-meio-fileira label.assento-selecionado {
    color: #fff;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    border-radius: 8px;
    background-color: #ff9800;
    border: 1px solid #ff9800;
    transition: background-color .02s;
    -webkit-transition: background-color .02s
}

.transport.boat-design.boat-design-up .transport-meio-fileira label.espaco-vago {
    background: none;
    cursor: default;
    pointer-events: none;
    color: #fff;
    user-select: none;
    margin-bottom: 8px
}

.transport.boat-design.boat-design-up .transport-meio-corredor {
    width: 100%;
    height: 20px;
    width: 15px;
    height: 100%;
    display: inline-block
}

.transport.boat-design.boat-design-up .transport-meio-corredor:last-child {
    display: none
}

.transport.boat-design.boat-design-up .transport-meio-thin .transport-meio-corredor {
    height: 34px
}

.transport.boat-design.boat-design-up .transport-traseira {
    display: inline-block;
    width: 100%;
    height: 33px;
    position: relative;
    background: #cfd8dc
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-up .transport-traseira:before {
        background-color: #fff
    }
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-up .transport-traseira:after {
        background-color: #fff
    }
}

.transport.boat-design.boat-design-side {
    width: 304px;
    margin: auto;
    padding: 32px 0 64px;
    position: relative;
    left: -10px
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-side {
        width: 310px;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        height: 500px;
        margin-right: 200px;
        padding: 8px
    }
}

.transport.boat-design.boat-design-side .transport-frente {
    display: inline-block;
    width: 100%;
    height: 50px;
    position: relative;
    bottom: -3px;
    border-top: 3px solid #cfd8dc;
    background-color: #eceff1;
    box-shadow: inset 0 4px 0 #eceff1, inset 0 5px 0 #cfd8dc
}


@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-frente:before {
        background-color: #fff
    }
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-frente:after {
        background-color: #fff
    }
}

.transport.boat-design.boat-design-side .transport-meio {
    background-color: #fff;
    border: solid #cfd8dc;
    border-width: 0 5px;
    box-sizing: border-box;
    min-height: 400px;
    max-width: 100%;
    min-width: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-meio {
        padding: 0 8px
    }
}

.transport.boat-design.boat-design-side .transport-meio-fileira {
    width: 32px;
    height: auto;
    display: inline-block;
    vertical-align: top;
    white-space: nowrap;
    margin-right: 8px
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-meio-fileira {
        width: 34px;
        margin-right: 8px
    }
}

.transport.boat-design.boat-design-side .transport-meio-fileira input {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    cursor: pointer
}

.transport.boat-design.boat-design-side .transport-meio-fileira label {
    font-family: Roboto, sans-serif, Arial, Helvetica;
    font-size: 14px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    color: #000;
    padding: 9px 5px;
    box-sizing: border-box;
    width: 100%;
    height: 32px;
    display: block;
    text-align: center;
    margin-bottom: 8px;
    position: relative
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-meio-fileira label {
        padding: 10px 5px;
        height: 34px;
        margin-bottom: 8px
    }
}

.transport.boat-design.boat-design-side .transport-meio-fileira label.assento-disponivel {
    border: 1px solid #757575;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    font-family: Roboto, sans-serif, Arial, Helvetica;
    color: #757575;
    transition: background-color .5s;
    -webkit-transition: background-color .5s
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-meio-fileira label.assento-disponivel {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px;
        margin-bottom: 8px
    }
}

.transport.boat-design.boat-design-side .transport-meio-fileira label.assento-ocupado {
    color: #9e9e9e;
    background-color: #eee;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    cursor: default !important;
    width: 32px
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-meio-fileira label.assento-ocupado {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px;
        width: 34px;
        margin-bottom: 8px
    }
}

.transport.boat-design.boat-design-side .transport-meio-fileira label.assento-ocupado i.material-icons.assento-icon-close {
    font-size: 18px;
    position: relative;
    top: -2px
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-meio-fileira label.assento-ocupado i.material-icons.assento-icon-close {
        font-size: 20px;
        position: relative;
        top: -3px
    }
}

.transport.boat-design.boat-design-side .transport-meio-fileira label.assento-selecionado {
    color: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    background-color: #ff9800;
    border: 1px solid #ff9800;
    transition: background-color .02s;
    -webkit-transition: background-color .02s
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-meio-fileira label.assento-selecionado {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
        border-radius: 6px
    }
}

.transport.boat-design.boat-design-side .transport-meio-fileira label.espaco-vago {
    background: none;
    cursor: default;
    pointer-events: none;
    color: #fff;
    user-select: none
}

.transport.boat-design.boat-design-side .transport-meio-corredor {
    width: 100%;
    height: 20px;
    width: 20px;
    height: 100%;
    display: inline-block
}

.transport.boat-design.boat-design-side .transport-meio-corredor:last-child {
    display: none
}

.transport.boat-design.boat-design-side .transport-meio-thin .transport-meio-corredor {
    height: 28px
}

@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-meio-thin .transport-meio-corredor {
        height: 34px
    }
}

.transport.boat-design.boat-design-side .transport-traseira {
    display: inline-block;
    width: 100%;
    height: 33px;
    position: relative;
    background: #cfd8dc
}



@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-traseira:before {
        background-color: #fff
    }
}



@media (min-width: 768px) {
    .transport.boat-design.boat-design-side .transport-traseira:after {
        background-color: #fff
    }
}