@charset "UTF-8";
@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;1,400;1,500&display=swap);

[class*=logo-] {
    background-size: cover;
    color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    text-decoration: none;
    overflow: hidden;
    display: inline-block
}

.logo-payment-mercadopago {
    width: 130px;
    height: 34px;
    background-image: url(/src/estilo/comum/img/logo-mercado-pago.svg)
}

.logo-payment-mastercard {
    width: 48px;
    height: 37px;
    background-image: url(/src/estilo/comum/img/cartoes/master-card.svg)
}

.logo-payment-visa {
    width: 64px;
    height: 20px;
    background-image: url(/src/estilo/comum/img/cartoes/visa.svg)
}

.logo-payment-amex {
    width: 36px;
    height: 32px;
    background-image: url(/src/estilo/comum/img/cartoes/american-express.svg)
}

.logo-payment-hipercard {
    width: 64px;
    height: 27px;
    background-image: url(/src/estilo/comum/img/cartoes/hipercard.svg)
}

.logo-payment-paypal {
    width: 90px;
    height: 24px;
    background-image: url(/src/estilo/comum/img/cartoes/paypal.svg)
}

