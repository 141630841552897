.container-trechos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.card-principaisTrechos {
    height: 340px;
    width: 277px;
    padding: 10px;
}

.card-trecho {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 225px;
    align-items: center;
    justify-content: space-between;
}

.card-trecho img {
    width: 240px;
    height: 170px;
    object-fit: cover;
}

.icon-origem {
    height: .75rem;
    width: .75rem;
    border-radius: 50%;
    background-color: var(--color-white);
    border: 1px solid var(--vxa-loyalty-tertiary-400);
    position: absolute;
    left: 27px;
}

.icon-origem-2 {
    position: absolute;
    left: 21px;
    margin-top: 25px;
    color: var(--vxa-loyalty-tertiary-400);
}

.box-icon {
    margin-bottom: 1rem;
}

.box-icon h3 {
    /*font-size: 1.3rem;*/
}

#nomeTrechoDestino {
    margin-top: 1rem !important;
}

.box-icon.mt-3 {
    width: 200px;
}

.btn-trecho {
    background-color: #444;
    padding: 10px 28px;
    border-radius: 30px;
    color: white;
}

.btn-trecho {
    background-color: #444;
    padding: 10px 28px;
    border-radius: 30px;
    color: white;
}

.btn-trecho:hover {
    color: var(--vxa-loyalty-tertiary-400)
}

.mybox {
    display: flex;
}

.display-cidades {
    display: flex;
    justify-items: center;
}